import React, { useState, } from 'react';
import { View, Text, TouchableOpacity, TextInput, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { Feather } from '@expo/vector-icons';


const CreateNotice = ({ isVisible, onCancel, box_id, onCreate}) => {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');



    const today = new Date().toISOString().split('T')[0];

    const handleCreateNotice = () => {
        // Validate notice data here if needed
        // Then pass notice data to onCreate function
        console.log(startDate)
        console.log(endDate)
        onCreate(title, body, startDate, endDate, 0);
    };

    const handleDateChange = (date, type) => {
        const d = date
        if (d < today) {
            d = today;
        }
        type === "start" ? setStartDate(d) : setEndDate(d);
    };


    return (
        <Modal
            animationType="slide"
            transparent={true}
            isVisible={isVisible}
            onRequestClose={onCancel}
            backdropOpacity={0.375}
            style={{ margin: 0 }}
        >
            <View style={styles.modalContainer}>
                <View style={styles.alertBox}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
                        <Text style={styles.title}>Create Notice</Text>
                        <TouchableOpacity onPress={onCancel}>
                            <Feather name="x-circle" size={24} color="black" />
                        </TouchableOpacity>
                    </View>
                    <Text>Title</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Enter title for notice..."
                        value={title}
                        onChangeText={setTitle}
                    />
                    <Text>Body</Text>
                    <TextInput
                        style={[styles.input, styles.textArea]}
                        placeholder="Create body for your notice..."
                        value={body}
                        onChangeText={setBody}
                        multiline={true}
                        numberOfLines={4}
                    />
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
                        <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignContent: 'center', width: '50%', }}>
                            <Text>Start Date</Text>
                            <input
                                type="date"
                                id="start"
                                name="start"
                                value={startDate}
                                min={today}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </View>

                        <View style={{ marginLeft: '1%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignContent: 'center', width: '50%' }}>
                            <Text>End Date</Text>
                            <input
                                type="date"
                                id="end"
                                name="end"
                                value={endDate}
                                min={today}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </View>
                    </View>
                    <View style={styles.buttonContainer}>

                        <TouchableOpacity onPress={onCancel} style={styles.cancelButton}>
                            <Text>Cancel</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={handleCreateNotice} style={[styles.confirmButton, {opacity: endDate != '' && startDate != '' && title != '' && body != '' ? 1 : 0.5}]} disabled={!(endDate != '' && startDate != '' && title != '' && body != '')}>
                            <Text style={{ color: 'white' }}>Create Notice</Text>
                        </TouchableOpacity>

                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        // backgroundColor: 'red'
    },
    alertBox: {
        backgroundColor: 'white',
        padding: 30,
        borderRadius: '0.25rem',
        elevation: 5,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 4,
        marginTop: '50px',
        width: '600px',
        overflowX: 'auto'
        // maxHeight: '100%',
    },
    title: {
        fontSize: 22,
        marginBottom: 20,
        fontWeight: 500,
        textAlign: 'center',
    },
    input: {
        height: 40,
        width: '100%',
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: 5,
        paddingHorizontal: 10,
        marginBottom: '20px',
        marginTop: 10,
    },
    inputDate: {
        height: 40,
        width: '99%',
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: 5,
        paddingHorizontal: 10,
        marginBottom: '20px',
        marginTop: 10,
    },
    textArea: {
        height: '275px',
        textAlignVertical: 'top',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    confirmButton: {
        flex: 1,
        height: 40,
        marginLeft: 5,
        backgroundColor: 'blue',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cancelButton: {
        flex: 1,
        height: 40,
        marginRight: 5,
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: 10,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default CreateNotice;
