import React, { useEffect, useState } from "react";
import { View } from "react-native";
import OverbookedAlert from "../OverbookedAlert";
import { useNavigate } from 'react-router-dom';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { API_URL } from "../constants";
import setupFetchInterceptors from "../../hooks/setupFetchInterceptors";
import { getData } from '../../utils/PaymentUtils.js'


export default function SaleCheckoutForm({ showBanner, orderContents }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);



  const navigate = useNavigate();

  setupFetchInterceptors()

  useEffect(() => {

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log(paymentIntent)
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);


  const handleSuccess = async () => {

    let customer_id = 1;
    let location_id = 1;
    let employee_id = await getData('user_id');
    let payment_type = "card"
    const saleItems = orderContents.map(item => ({ id: item.id, quantity: item.quantity }));

    //add submit
    const payload = {
      employee_id,
      customer_id,
      location_id,
      payment_type,
      saleItems,
    };

    try {
      const res = await fetch(`${API_URL}/sales`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:19006',
          'Access-Control-Request-Method': 'POST',
          'Access-Control-Request-Headers': 'Content-Type, Authorization',
        },
        body: JSON.stringify(payload),
      });

      if (res.status === 201) {
        const jsonRes = await res.json();
        console.log(jsonRes.data);

        return jsonRes.data.booking_id;
      } else {
        // Handle the case where the API call was not successful
        return false;
      }
    } catch (err) {
      // Handle any errors that occur during the API call
      console.log(err);
      return false;
    }

  }

  const handleError = async () => {

  }


  const handleSubmit = async (e, overbooked_flag) => {
    e.preventDefault();

    console.log(overbooked_flag)

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);


    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "http://localhost:19006/#/new_sale/",
          payment_method_data: {
            billing_details: {
              name: '', // Provide a valid name
              email: '', // Provide a valid email
              phone: '', // Provide a valid phone number
              address: {
                country: 'US', // Provide a valid country code
                postal_code: '', // Provide a valid postal code
                state: '', // Provide a valid state abbreviation
                city: '', // Provide a valid city
                line1: '', // Provide a valid address
                line2: '',
              }
            },
          },
          receipt_email: null,
        },
        redirect: 'if_required'
      });

      if (error) {
        // If credit card fails, remove the temporary booking from inventory and return appropriate error.
        console.error(error);
        setMessage(error.message)
        handleError()
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        // If the credit card succeeds, change the temporary booking to a legit one
        console.log("Payment succeeded");
        if (handleSuccess()) {
          showBanner('success', 'Successfully created sale!');
          navigate('/new_sale')
        } else {
          setMessage("Error adding payment to database")
        }

      } else {
        console.log("Payment failed");
        handleError()
        setMessage("Error unexpected failure")
      }
    } catch (error) {
      setMessage(error);
      console.error(error);
    }

    setIsLoading(false);
  };


  const paymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
    fields: {
      billingDetails: {
        name: 'never',
        email: 'never',
        address: 'never',
        phone: 'never',
      }
    },
  };


  return (
    // <View style={{ width: '100%', height: '100%', overflow: 'auto' }}>


    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />

      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
    // </View>
  );

}