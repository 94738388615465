import React, { Component, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Touchable, Animated, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';


const RosterDropdown = ({
    header = {},
    type = {},
    value = {},
    userId = {},
    textStyle = {},
    onSelect = () => { },
    onFilter = () => { },
    onValueChange = () => { },
}) => {

    let filterOptions = [{ id: 1, name: 'All' }, { id: 2, name: 'Admin' }, { id: 3, name: 'Manager' }, { id: 4, name: 'Boxes' }, { id: 5, name: 'Ice' }]
    let roles = [{ id: 2, name: 'Admin' }, { id: 3, name: 'Manager' }, { id: 4, name: 'Boxes' }, { id: 5, name: 'Ice' }]
    let filterSaleOptions = [{ id: 1, name: 'Today' }, { id: 2, name: "Week" }, { id: 3, name: "Month" }, { id: 4, name: "Year" }]


    const [headerValue, setHeaderValue] = useState("All");
    const [dropValue, setDropValue] = useState(value);
    const [showOption, setShowOption] = useState(false)
    const [saleFilter, setSaleFilter] = useState('Today')


    const onSelectedItem = (val) => {
        setShowOption(false)
        if(type === "sale filter"){
            setSaleFilter(val.name)
        }
        if (header) {
            setHeaderValue(val.name);
            //filter results accordingly...
            onFilter(val.name);

        } else {
            //change database value....
            onSelect(userId, val.name);

            //change the visual value for the user
            onValueChange(val.name);
            // setDropValue(val.name);
        }
    }


    return (
        <View style={{ height: '100%', width: '100%', border: '1pt solid rgb(233 236 239)', borderRadius: '0.25rem', }}>
            <TouchableOpacity
                style={{ height: '100%', width: '100%', backgroundColor: 'white' }}
                activeOpacity={0.8}
                onPress={() => setShowOption(!showOption)}
            >

                {header && type != "sale filter" &&
                    <Text style={{ paddingLeft: '10px', marginVertical: 'auto', color: 'black', fontSize: '14px', fontWeight: header ? 'bold' : 'normal', lineHeight: '1', }}>{headerValue}</Text>
                }
                {!header && type != "sale filter" &&
                    <Text style={{ paddingLeft: '10px', marginVertical: 'auto', color: 'black', fontSize: '14px', fontWeight: header ? 'bold' : 'normal', lineHeight: '1', }}>{!!value ? value : 'Undefined'}</Text>
                }
                {(type === "sale filter") &&
                    <Text style={{ paddingLeft: '10px', marginVertical: 'auto', color: 'black', fontSize: '14px', fontWeight: header ? 'bold' : 'normal', lineHeight: '1', }}>{saleFilter}</Text>
                }
                <Animated.Image
                    style={{
                        position: 'absolute',
                        right: '2.5%',
                        width: '4.6%',
                        height: '34%',
                        resizeMode: 'contain',
                        objectFit: 'contain',
                        top: '50%',
                        transform: [{ translateY: '-50%' }, { rotate: showOption ? '180deg' : '0deg' }],

                    }}
                    source={require('../public/images/dropdown_icon.png')}
                />
            </TouchableOpacity>
            {showOption && type != "sale filter" && header && (<View style={styles.dropdownMenu}>
                {filterOptions.map((val, i) => {
                    return (
                        <TouchableOpacity
                            style={{}}
                            key={String(i)}
                            onPress={() => onSelectedItem(val)}
                        >
                            <Text>{val.name}</Text>
                        </TouchableOpacity>
                    )
                })}
            </View>)}
            {showOption && type != "sale filter" && !header && (<View style={styles.dropdownMenu}>
                {roles.map((val, i) => {
                    return (
                        <TouchableOpacity
                            style={{}}
                            key={String(i)}
                            onPress={() => onSelectedItem(val)}
                        >
                            <Text>{val.name}</Text>
                        </TouchableOpacity>
                    )
                })}
            </View>)}
            {showOption && type === "sale filter" && (<View style={styles.dropdownMenu}>
                {filterSaleOptions.map((val, i) => {
                    return (
                        <TouchableOpacity
                            style={{}}
                            key={String(i)}
                            onPress={() => onSelectedItem(val)}
                        >
                            <Text>{val.name}</Text>
                        </TouchableOpacity>
                    )
                })}
            </View>)}
        </View>
    );

};

const styles = StyleSheet.create({
    image: {
        textAlign: 'right',
        width: '15px',
        height: '15px',
        flexDirection: 'row',
        resizeMode: 'contain',
    },
    dropdownMenu: {
        position: 'absolute',
        marginTop: '30px',
        left: 0,
        right: 0,
        backgroundColor: 'white',
        borderRadius: 5,
        padding: 10,
        shadowColor: 'black',
        shadowOpacity: 0.5,
        shadowRadius: 5,
        elevation: 5,
        overflow: 'auto',
        maxHeight: '300pt',
        zIndex: 2
    },
})

export default RosterDropdown;