import { useEffect, useRef } from 'react';
import { API_URL } from '../components/constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useLogout from './useLogout';
import { useNavigate } from 'react-router-dom';

const useLocationSSE = (boxId) => {
    const eventSourceRef = useRef(null);
    const logout = useLogout()
    const navigate = useNavigate();


    useEffect(() => {



        console.log(boxId)
        if (boxId === null || boxId === undefined) {
            if(logout && eventSourceRef.current){
                console.log("Closing")
                eventSourceRef.close();
                return;
            }
            return;
        }

        var attempts = 0;

        // Function to establish SSE connection and handle reconnect logic
        const establishSSEConnection = (att) => {

            const sseEndpoint = `${API_URL}/sse?boxId=${boxId}`;
            eventSourceRef.current = new EventSource(sseEndpoint, { withCredentials: true });
            

            // Event listener for successful connection
            eventSourceRef.current.onopen = () => {
                console.log('SSE connection established');
                attempts = 0;
            };

            // Event listener for incoming messages
            eventSourceRef.current.onmessage = (event) => {
                // Handle SSE messages
                const data = JSON.parse(event.data)
                console.log('Received SSE message:', data);
                console.log(data.type)
                if (data.type === "new_booking") {
                  console.log("YOOOO WE GOT A BIG ONE")  
                } 
                attempts = 0;
            };

            // Event listener for connection errors
            eventSourceRef.current.onerror = (error) => {
                console.error('SSE connection error:', error);
                // Attempt to reconnect
                reconnectSSEConnection(att);
            };
        };

        // Function to reconnect SSE connection
        const reconnectSSEConnection = (att) => {

            console.log(att)
            if (att > 3) {
                //display error message:
                //Server error: refresh page to attempt reconnect
                eventSourceRef.current.close();
                return;
            }

            // Close existing connection (if any)
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
            }
            // Attempt to establish a new connection after a short delay
            setTimeout(() => {
                establishSSEConnection(att + 1);
            }, 2000); // Reconnect after 2 seconds (adjust as needed)
        };

        // Establish SSE connection if not already open
        if (!eventSourceRef.current) {
            establishSSEConnection(attempts);
        }

        // Clean-up function to close SSE connection on unmount
        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
            }
        };
    }, [boxId]);

    // Return any additional data or functions as needed
};

export default useLocationSSE;
