import React, { useEffect, useState } from 'react';
import { Platform, TouchableOpacity, Text, View } from 'react-native';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import './mapstyles.css';
import { API_URL } from './constants';
import Calendar from './Calendars/CalendarSingle';

const CustomMap = ({ mode, sale_id, location, booking_contents }) => {

  const [mapState, setMapState] = useState({
    center: [initialLongitude, initialLatitude],
    zoom: defaultZoom,
    // Add other map state properties...
  });

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [marker, setMarker] = useState(null);

  const [currentBoxID, setCurrentBoxID] = useState(null);
  const [currentMap, setCurrentMap] = useState(null);

  let d = new Date()
  d.setHours(0, 0, 0, 0)

  const [daySelected, setDaySelected] = useState(d);
  const [isSelectingDate, setIsSelectingDate] = useState(false)

  const addBookingMarker = async (map, position) => {
    try {
      const payload = {
        latitude: position.y,
        longitude: position.x,
      };

      // Check if the booking already exists
      const existingMarker = document.querySelectorAll(`.marker2_${sale_id}`);
      if (existingMarker) {
        // Update the location in the database
        await fetch(`${API_URL}/setLocation/${sale_id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Origin': 'http://localhost:19006',
            'Access-Control-Request-Method': 'POST',
            'Access-Control-Request-Headers': 'Content-Type, Authorization',
          },
          body: JSON.stringify(payload),
        });

        // Remove the existing marker from the map
        existingMarker.forEach((marker) => {
          marker.remove();
        });

        // Add the updated marker to the map
        const el = document.createElement('div');
        el.className = 'marker2_' + sale_id + " current_id";

        if (booking_contents) {
          let umbrella_flag = 0
          let chair_flag = 0
          let combo_flag = 0
          booking_contents.booking_items.forEach(item => {
            if (item.product.product_id === 1) {
              umbrella_flag = 1
            } else if (item.product.product_id === 2) {
              chair_flag = 1
            } else if (item.product.product_id === 3) {
              combo_flag = 1
            }
          })

          if (umbrella_flag && !chair_flag && !combo_flag) {
            //add umbrella thing
            el.className += " umbrella"
          } else if (chair_flag && !umbrella_flag && !combo_flag) {
            //add chair thing
            el.className += " chair"
          } else {
            //add combo thing
            el.className += " combo"
          }
        }

        const marker = new mapboxgl.Marker(el)
          .setLngLat([position.x, position.y])
          .addTo(map);

        if (booking_contents) {
          // Create a popup for each marker
          const popup = new mapboxgl.Popup()
            .setHTML(`
            <div class="popup-content">
            <h3>Booking Information</h3>
            <p><strong>ID:</strong> ${booking_contents.booking_id}</p>
            <p><strong>Name:</strong> ${booking_contents.customer_name}</p>
            <p><strong>Contents:</strong></p>
            <ul>
            ${booking_contents.booking_items.map(item => `<li>${item.product.name.charAt(0).toUpperCase() + item.product.name.slice(1)} - Quantity: ${item.quantity}</li>`).join('')}
            </ul>
            </div>
            `)
            .setMaxWidth('300px');

          // Attach the popup to the marker
          marker.setPopup(popup);
        }

        return;
      }

      // If the booking doesn't exist, add it to the list
      const res = await fetch(`${API_URL}/setLocation/` + sale_id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:19006',
          'Access-Control-Request-Method': 'POST',
          'Access-Control-Request-Headers': 'Content-Type, Authorization',
        },
        body: JSON.stringify(payload),
      });

      if (res.status !== 200) {
        console.log("fail");
        return;
      }

      console.log("Success");

      // Add the new booking to the bookings list
      const el = document.createElement('div');
      el.className = 'marker2_' + sale_id + " current_id";
      const marker = new mapboxgl.Marker(el)
        .setLngLat([position.x, position.y])
        .addTo(map);
    } catch (error) {
      console.error('Error adding booking marker:', error);
    }
  };


  const load_bookings = async (map, box_id, day) => {
    try {
      console.log(day)
      let d = new Date()
      d.setHours(0, 0, 0, 0)
      console.log(d)

      let pad = function (num) { return ('00' + num).slice(-2) };
      let testDate = day.getUTCFullYear() + '-' +
        pad(day.getUTCMonth() + 1) + '-' +
        pad(day.getUTCDate()) + ' ' +
        pad(day.getUTCHours()) + ':' +
        pad(day.getUTCMinutes()) + ':' +
        pad(day.getUTCSeconds());

      console.log(`${API_URL}/bookings/` + box_id + "?date=" + testDate)

      const res = await fetch(`${API_URL}/bookings/` + box_id + "?date=" + testDate, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:19006',
          'Access-Control-Request-Method': 'GET',
          'Access-Control-Request-Headers': 'Content-Type, Authorization',
        },
      });

      console.log(res.status)

      const existingMarkers = document.querySelectorAll('div[class^="marker2_"]');
      console.log(existingMarkers)
      existingMarkers.forEach((marker) => {
        marker.remove()
      })

      if (res.status !== 200) {
        const jsonRes = await res.json();
        console.log(jsonRes);
        return null;
      } else {
        const jsonRes = await res.json();
        console.log(jsonRes);

        let temp_bookings = [];

        jsonRes.bookings.forEach(booking => {

          if (booking.location === null) {
            console.log("yo")
            // Skip bookings with no location
          } else {
            console.log(booking.location)
            // Add the new layer with updated coordinates and custom icon
            const el = document.createElement('div');
            if (sale_id && booking.booking_id === sale_id) {
              el.className = 'marker2_' + booking.booking_id + " current_id";
            } else {
              el.className = 'marker2_' + booking.booking_id;
            }

            let umbrella_flag = 0
            let chair_flag = 0
            let combo_flag = 0
            booking.booking_items.forEach(item => {
              if (item.product.product_id === 1) {
                umbrella_flag = 1
              } else if (item.product.product_id === 2) {
                chair_flag = 1
              } else if (item.product.product_id === 3) {
                combo_flag = 1
              }
            })

            if (umbrella_flag && !chair_flag && !combo_flag) {
              //add umbrella thing
              el.className += " umbrella"
            } else if (chair_flag && !umbrella_flag && !combo_flag) {
              //add chair thing
              el.className += " chair"
            } else {
              //add combo thing
              el.className += " combo"
            }

            const marker = new mapboxgl.Marker(el)
              .setLngLat([booking.location.longitude, booking.location.latitude])
              .addTo(map);

            // Create a popup for each marker
            const popup = new mapboxgl.Popup()
              .setHTML(`
    <div class="popup-content">
        <h3>Booking Information</h3>
        <p><strong>ID:</strong> ${booking.booking_id}</p>
        <p><strong>Name:</strong> ${booking.customer.name}</p>
        <p><strong>Contents:</strong></p>
        <ul>
            ${booking.booking_items.map(item => `<li>${item.product.name.charAt(0).toUpperCase() + item.product.name.slice(1)} - Quantity: ${item.quantity}</li>`).join('')}
        </ul>
    </div>
`)
              .setMaxWidth('300px');

            // Attach the popup to the marker
            marker.setPopup(popup);

            temp_bookings.push(marker)
          }
        });
      }
      return;
    } catch (err) {
      console.log(err);
    }

    return null;

  }

  const add_box_markers = async (map, locations) => {

    // Loop through locations and create markers with popups
    locations.forEach(box => {
      if (box.location === null) {

      } else {
        const marker = new mapboxgl.Marker()
          .setLngLat([box.location.longitude, box.location.latitude])
          .addTo(map);


        // Create a popup for each marker
        const popup = new mapboxgl.Popup()
          .setHTML(`
        <p>${box.name}</p>
        `)
          .setMaxWidth('300px');

        // Attach the popup to the marker
        marker.setPopup(popup);

        marker.getElement().addEventListener('click', () => {
          // Use map.flyTo to smoothly transition to the desired location and zoom level
          map.flyTo({
            center: [box.location.longitude, box.location.latitude],
            zoom: 17,
            essential: true, // This option ensures that the transition is not interrupted by other user interactions
          });


          load_bookings(map, box.box_id, daySelected);
          setCurrentBoxID(box.box_id)

        });
      }
    });
  }


  useEffect(() => {
    const loadMapState = async () => {
      try {
        // Load map state from AsyncStorage
        const storedMapState = await AsyncStorage.getItem('mapState');
        console.log(storedMapState);
        if (storedMapState) {
          const parsedMapState = JSON.parse(storedMapState);
          setMapState(parsedMapState);

          // Pass the loaded state directly to initializeMap
          await initializeMap(parsedMapState);
        } else {
          await initializeMap(null)
        }
      } catch (error) {
        console.error('Error loading map state:', error);
      }
    };

    const saveMapState = async (updatedMapState) => {
      try {
        // Save map state to AsyncStorage
        await AsyncStorage.setItem('mapState', JSON.stringify(updatedMapState));
      } catch (error) {
        console.error('Error saving map state:', error);
      }
    };

    const initializeMap = async (loadedMapState) => {
      mapboxgl.accessToken = 'pk.eyJ1IjoiamFjb2JhdzgiLCJhIjoiY2xvdTgxbzlwMDkycTJqbHNmbGlrdWFsZiJ9.xFM8PPhw_0gjnB9TJF_cXw';

      console.log("YO?")
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: loadedMapState != null ? loadedMapState.center : [initialLongitude, initialLatitude],
        zoom: loadedMapState != null ? loadedMapState.zoom : defaultZoom,
        bearing: bearing,
      });

      setCurrentMap(map);

      if (location) {
        console.log(location)
        setCurrentBoxID(location)
        load_bookings(map, location, daySelected)
      }


      try {
        // Fetch data...
        const locationsResponse = await fetch(`${API_URL}/inventory?location=true`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Origin': 'http://localhost:19006',
            'Access-Control-Request-Method': 'GET',
            'Access-Control-Request-Headers': 'Content-Type, Authorization',
          },
        });

        if (locationsResponse.status !== 200) {
          throw new Error('Failed to fetch data');
        }

        const locationsData = await locationsResponse.json();

        // Update the locations state with the fetched data
        console.log(locationsData.inventory);
        await add_box_markers(map, locationsData.inventory);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle errors as needed
      }

      map.on('moveend', () => {
        const updatedMapState = {
          center: map.getCenter().toArray(),
          zoom: map.getZoom(),
          // Add other map state properties...
        };
        console.log(updatedMapState);
        setMapState(updatedMapState);
        saveMapState(updatedMapState); // Save the updated state
      });

      map.setMaxBounds(bounds, {
        padding: 50, // Adjust padding as needed
      });

      // Add event listeners...
      // Add a click event listener on the map container
      map.getCanvas().addEventListener('click', (e) => {
        const target = e.target;

        // Check if the clicked target is a marker or popup content
        if (target.classList.contains('mapboxgl-marker') || target.classList.contains('mapboxgl-popup-content')) {
          // Clicked on a marker or popup content
          console.log('Clicked on a marker or popup');
        } else {
          // Convert pixel coordinates to geographical coordinates
          const rect = map.getCanvas().getBoundingClientRect();
          const x = e.clientX - rect.left; // Relative x-coordinate within the map container
          const y = e.clientY - rect.top; // Relative y-coordinate within the map container

          // Convert relative coordinates to geographical coordinates
          const lngLat = map.unproject([x, y]);
          // Clicked outside of markers
          const { lng, lat } = lngLat;
          if (mode === 'single-place') {
            setMarker({ x: lng, y: lat });
            addBookingMarker(map, { x: lng, y: lat });
          }
          console.log(`Longitude: ${lng}, Latitude: ${lat} (bad one)`);
        }
      });

      // Add a click event listener on the map container
      map.getCanvas().addEventListener('mousemove', (e) => {
        const target = e.target;

        // Check if the clicked target is a marker or popup content
        if (target.classList.contains('mapboxgl-marker') || target.classList.contains('mapboxgl-popup-content')) {
          // Clicked on a marker or popup content
          console.log('Clicked on a marker or popup');
        } else {

          // Convert pixel coordinates to geographical coordinates
          const rect = map.getCanvas().getBoundingClientRect();
          const x = e.clientX - rect.left; // Relative x-coordinate within the map container
          const y = e.clientY - rect.top; // Relative y-coordinate within the map container

          // Convert relative coordinates to geographical coordinates
          const lngLat = map.unproject([x, y]);

          // Extract longitude and latitude
          const { lng, lat } = lngLat;

          if (mode === 'single-place') {
            setMousePosition({ x: lng, y: lat });
            map.getCanvas().style.cursor = 'crosshair';
          }
        }
      });

      // Add a click event listener on the map container
      map.getCanvas().addEventListener('mouseleave', (e) => {
        const target = e.target;

        // Check if the clicked target is a marker or popup content
        if (target.classList.contains('mapboxgl-marker') || target.classList.contains('mapboxgl-popup-content')) {
          // Clicked on a marker or popup content
          console.log('Clicked on a marker or popup');
        } else {
          setMousePosition({ x: 0, y: 0 });
          map.getCanvas().style.cursor = '';
        }
      });
    };

    loadMapState();

    // Cleanup function
    return () => {
      // Clean up resources...
    };
  }, [location]); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
      {/* Map container */}
      <div id="map" style={{ height: '100%', width: '100%' }} />

      {/* Date selection button */}
      {location && <TouchableOpacity
        onPress={() => { setIsSelectingDate((prev) => { return !prev }); console.log("YOOO") }/* Pass selected date value here */}
        style={{ position: 'absolute', top: 20, right: 20, width: '300px', padding: 10, backgroundColor: 'navy', borderWidth: '0.5px', borderRadius: '0.2rem' }}
      >
        <Text style={{ textAlign: 'center', color: 'white' }}>Selected Date: {daySelected.toLocaleString('default', { month: 'long' }) + " " + daySelected.getDate() + " " + daySelected.getFullYear()}</Text>
      </TouchableOpacity>}
      {/* Calendar */}
      {isSelectingDate && location && (
        <View
          style={{
            position: 'absolute',
            top: 60, // Adjust the value to create the desired gap between the button and the calendar
            right: 20,
            zIndex: 999, // Ensure the calendar is displayed above other elements
            backgroundColor: 'white',
            borderRadius: 10,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            // padding: 10,
          }}
        >
          {/* Render the Calendar component */}
          <Calendar
            width={300}
            height={300}
            openToDate={daySelected}
            onSelect={(day) => { setIsSelectingDate(false); setDaySelected(day); load_bookings(currentMap, currentBoxID, day) }}
          />
        </View>
      )}
    </div>
  );
};

const initialLatitude = 33.81721; // Adjusted initial latitude
const initialLongitude = -78.67257; // Adjusted initial longitude
const defaultZoom = 12.6; //12.75
const bearing = -24;
const bounds = [
  [-78.78581, 33.77102],
  [-78.54502, 33.85470],
]
export default CustomMap;
