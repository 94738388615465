import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useEmployeeSSE from "../hooks/useEmployeeSSE";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();


    if (auth?.id) {
        useEmployeeSSE(auth?.id);
    }

    return (
        allowedRoles?.includes(auth?.role)
            ? <Outlet />
            : auth?.token
                ? <Navigate to="/home" state={{ from: location }} replace />
                : <Navigate to="/" state={{ from: location }} replace />
    )
};

export default RequireAuth;
