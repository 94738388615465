import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, TextInput, StyleSheet, Picker } from 'react-native';
import Modal from 'react-native-modal';
import { Feather } from '@expo/vector-icons';
import { API_URL } from './constants';
import AsyncStorage from '@react-native-async-storage/async-storage';



const SettingsModal = ({ isVisible, onCancel, user_id, onCreate }) => {
    const [reportOption, setReportOption] = useState('');
    const [supervisorNote, setSupervisorNote] = useState('');
    const [autoPlaceOption, setAutoPlaceOption] = useState(null);
    const [payment, setPayment] = useState(null)
    const [appearance, setAppearance] = useState(null)
    const [employeeEmail, setEmployeeEmail] = useState(null)

    const [originalAutoPlaceOption, setOriginalAutoPlaceOption] = useState(null)
    const [originalPayment, setOriginalPayment] = useState(null)
    const [originalAppearance, setOriginalAppearance] = useState(null)
    const [originalEmployeeEmail, setOriginalEmployeeEmail] = useState(null)

    useEffect(() => {
        if (isVisible) {
            console.log("SETTINGS")
            const getEmail = async () => {
                let temp_email = await AsyncStorage.getItem('email');
                setOriginalEmployeeEmail(temp_email);
                setEmployeeEmail(temp_email);
            }
            getEmail()

            //load in settings
            fetch(`${API_URL}/settings/` + user_id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'http://localhost:19006',
                    'Access-Control-Request-Method': 'GET',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                },
            })
                .then(async res => {
                    if (res.status !== 200) {
                        console.log(res.status);
                    } else {
                        const jsonRes = await res.json()
                        console.log("success", jsonRes)
                        setAutoPlaceOption(jsonRes?.settings?.map_placement_method);
                        setPayment(jsonRes?.settings?.default_payment_method);
                        setAppearance(jsonRes?.settings?.appearance);

                        setOriginalAutoPlaceOption(jsonRes?.settings?.map_placement_method);
                        setOriginalPayment(jsonRes?.settings?.default_payment_method);
                        setOriginalAppearance(jsonRes?.settings?.appearance);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [isVisible]);

    const handleReportOptionChange = (value) => {
        setReportOption(value);
    };

    const handleSupervisorNoteChange = (value) => {
        setSupervisorNote(value);
    };

    const handleAutoPlaceOptionChange = (value) => {
        setAutoPlaceOption(value);
    };

    const handleSubmit = () => {

        if (supervisorNote != '' && reportOption != '') {
            //submit supervisor report
            //create notification
            const payload = {
                employee_id: user_id,
                message: supervisorNote,
                severity: 'info',
                relationType: reportOption,
                roles: ['Manager', 'Admin']
            }
            // fetch(`${API_URL}/change_inventory`, {
            fetch(`${API_URL}/notifications`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'http://localhost:19006',
                    'Access-Control-Request-Method': 'POST',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                },
                body: JSON.stringify(payload),
            }).then(async res => {

                try {

                    if (res.status !== 201) {
                        console.log(res.status)
                    } else {
                        const jsonRes = await res.json();
                        console.log(jsonRes)

                    }
                } catch (err) {
                    //setMessage(err);
                    console.log(err);
                };
            })
                .catch(err => {
                    console.log(err);
                })
        }

        if (originalEmployeeEmail != employeeEmail) {

            //VERIFY EMAIL IS IN CORRECT FORMAT

            //update employee email
            fetch(`${API_URL}/employee/` + user_id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'http://localhost:19006',
                    'Access-Control-Request-Method': 'PUT',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                },
                body: JSON.stringify({
                    email: employeeEmail
                }),
            })
                .then(async res => {
                    if (res.status !== 200) {
                        console.log(res.status);
                    } else {
                        const jsonRes = await res.json()
                        console.log("success", jsonRes)
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }

        if (originalAppearance != appearance ||
            originalAutoPlaceOption != autoPlaceOption ||
            originalPayment != payment) {

            //load in settings
            fetch(`${API_URL}/settings/` + user_id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'http://localhost:19006',
                    'Access-Control-Request-Method': 'PUT',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                },
                body: JSON.stringify({
                    appearance,
                    default_payment_method: payment,
                    map_placement_method: autoPlaceOption,
                }),
            })
                .then(async res => {
                    if (res.status !== 200 && res.status !== 201) {
                        console.log(res.status);
                    } else {
                        const jsonRes = await res.json()
                        console.log("success", jsonRes)
                    }
                })
                .catch(err => {
                    console.log(err);
                });

        }


        onCancel();
    };

    if (!isVisible) {
        return null;
    }


    return (
        <Modal
            animationType="slide"
            transparent={true}
            isVisible={isVisible}
            onRequestClose={onCancel}
            backdropOpacity={0.375}
            style={{ margin: 0 }}
        >
            <View style={styles.modalContainer}>
                <View style={styles.alertBox}>
                    <View style={styles.modalHeader}>
                        <Text style={styles.modalTitle}>Settings</Text>
                        <TouchableOpacity onPress={onCancel}>
                            <Text style={styles.modalCloseBtn}>×</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.modalBody}>
                        <View style={styles.settingSection}>
                            <Text style={styles.settingSectionTitle}>Appearance</Text>
                            <View style={styles.settingOptionRadio}>
                                <TouchableOpacity onPress={() => setAppearance('light')} style={[styles.radioButton, { width: '50%' }]}>
                                    <View style={{ height: 16, width: 16, borderRadius: 12, borderWidth: 2, borderColor: '#000', alignItems: 'center', justifyContent: 'center', }}>
                                        {appearance === 'light' ?
                                            <View style={{ height: 9, width: 9, borderRadius: 6, backgroundColor: '#000', }} />
                                            : null
                                        }
                                    </View>
                                    <Text style={{ marginLeft: 5 }}>Light Mode</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => setAppearance('dark')} style={styles.radioButton}>
                                    <View style={{ height: 16, width: 16, borderRadius: 12, borderWidth: 2, borderColor: '#000', alignItems: 'center', justifyContent: 'center', }}>
                                        {appearance === 'dark' ?
                                            <View style={{ height: 9, width: 9, borderRadius: 6, backgroundColor: '#000', }} />
                                            : null
                                        }
                                    </View>
                                    <Text style={{ marginLeft: 5 }}>Dark Mode</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.settingSection}>
                            <Text style={styles.settingSectionTitle}>Default Payment</Text>
                            <View style={styles.settingOptionRadio}>
                                <TouchableOpacity onPress={() => setPayment('cash')} style={[styles.checkbox, { width: '50%' }]}>
                                    <View style={{ height: 16, width: 16, borderRadius: 12, borderWidth: 2, borderColor: '#000', alignItems: 'center', justifyContent: 'center', }}>
                                        {payment === 'cash' ?
                                            <View style={{ height: 9, width: 9, borderRadius: 6, backgroundColor: '#000', }} />
                                            : null
                                        }
                                    </View>
                                    <Text style={{ marginLeft: 5 }}>Cash</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => setPayment('card')} style={styles.checkbox}>
                                    <View style={{ height: 16, width: 16, borderRadius: 12, borderWidth: 2, borderColor: '#000', alignItems: 'center', justifyContent: 'center', }}>
                                        {payment === 'card' ?
                                            <View style={{ height: 9, width: 9, borderRadius: 6, backgroundColor: '#000', }} />
                                            : null
                                        }
                                    </View>
                                    <Text style={{ marginLeft: 5 }}>Card</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.settingSection}>
                            <Text style={styles.settingSectionTitle}>Auto Place Umbrellas/Chairs on Map upon Purchase</Text>
                            <View style={styles.settingOption}>
                                <Picker
                                    selectedValue={autoPlaceOption}
                                    style={styles.picker}
                                    onValueChange={handleAutoPlaceOptionChange}>
                                    <Picker.Item label="Select Auto Place Option" value="" />
                                    <Picker.Item label="Automatic" value="automatic" />
                                    <Picker.Item label="Manual" value="manual" />
                                    <Picker.Item label="Rows" value="rows" />
                                    <Picker.Item label="Single" value="single" />
                                </Picker>
                            </View>
                        </View>
                        <View style={styles.settingSection}>
                            <Text style={styles.settingSectionTitle}>Account</Text>
                            <View style={styles.settingOption}>
                                <TextInput
                                    style={styles.input}
                                    placeholder="Change Email"
                                    value={employeeEmail}
                                    onChangeText={(val) => setEmployeeEmail(val)}
                                />
                                <TouchableOpacity style={styles.changePasswordBtn} onPress={handleSubmit}>
                                    <Text>Change Password</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.settingSection}>
                            <Text style={styles.settingSectionTitle}>Report Something to Supervisor</Text>
                            <View style={styles.settingOption}>
                                <Picker
                                    selectedValue={reportOption}
                                    style={styles.picker}
                                    onValueChange={handleReportOptionChange}>
                                    <Picker.Item label="Select Report Option" value="" />
                                    <Picker.Item label="Invalid Inventory" value="invalid_inventory" />
                                    <Picker.Item label="Water" value="water" />
                                    <Picker.Item label="Other" value="other" />
                                </Picker>
                                <TextInput
                                    style={styles.textArea}
                                    placeholder="Enter your note here..."
                                    value={supervisorNote}
                                    onChangeText={handleSupervisorNoteChange}
                                    multiline
                                />
                            </View>
                        </View>

                    </View>
                    <TouchableOpacity style={styles.saveBtn} onPress={() => { handleSubmit() }}>
                        <Text style={styles.saveBtnText}>Save</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        // backgroundColor: 'red'
    },
    alertBox: {
        backgroundColor: 'white',
        padding: 30,
        borderRadius: '0.25rem',
        elevation: 5,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 4,
        marginTop: '50px',
        width: '600px',
        minHeight: '300px',
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
        // maxHeight: '100%',
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    modalTitle: {
        fontSize: 29,
        color: 'navy',
    },
    modalCloseBtn: {
        fontSize: 24,
        color: 'navy',
    },
    modalBody: {
        marginBottom: 20,
    },
    settingSection: {
        marginBottom: 20,
    },
    settingSectionTitle: {
        fontSize: 24,
        color: 'navy',
    },
    settingOptionRadio: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 10,
    },
    settingOption: {
        marginTop: 10,
    },
    input: {
        width: '100%',
        padding: 8,
        marginTop: 8,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
    },
    changePasswordBtn: {
        backgroundColor: 'orange',
        padding: 5,
        borderRadius: 4,
        marginTop: 8,
        alignItems: 'center',
    },
    picker: {
        width: '100%',
        padding: 8,
        marginTop: 8,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
    },
    textArea: {
        width: '100%',
        padding: 8,
        marginTop: 8,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        height: 100,
    },
    saveBtn: {
        backgroundColor: 'orange',
        padding: 10,
        borderRadius: 4,
        alignItems: 'center',
    },
    saveBtnText: {
        color: '#000',
    },
    radioButton: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkbox: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export default SettingsModal;
