import React, { useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { View, Text, StyleSheet, TouchableOpacity, Image, Dimensions } from 'react-native';
import Header from '../../components/Header';
import { Feather } from '@expo/vector-icons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import setupFetchInterceptors from '../../hooks/setupFetchInterceptors';
import Payments from '../../components/Rentals/Payments';


const startingScreenWidth = Dimensions.get('window').width;

const IcePurchaseScreen = ({ showBanner }) => {

  const ice_items = [
    { id: 4, name: 'Large', price: 6, image: require('../../public/images/ice_images/large.jpg') },
    { id: 5, name: 'Small', price: 3, image: require('../../public/images/ice_images/small.jpg') },
  ]

  const [cartItems, setCartItems] = useState([]);
  const [currPrice, setCurrPrice] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [currRole, setCurrRole] = useState(null)

  const [enteringCard, setEnteringCard] = useState(false);

  //hook used to change between the payment screens
  const [currScreen, setCurrScreen] = useState(0);
  const [isCartExpanded, setIsCartExpanded] = useState(true);

  const [fullScreen, setFullScreen] = useState(false)
  const [displayWidth, setDisplayWidth] = useState('calc(100vw - 60px)')
  const [marginSize, setMarginSize] = useState('60px')

  const [screenWidth, setScreenWidth] = useState(startingScreenWidth)

  setupFetchInterceptors()

  // get data from storage
  async function getData(key, defaultValue = null) {
    try {
      const value = await AsyncStorage.getItem(key);

      if (value !== null) {
        return value;
      } else {
        return defaultValue;
      }
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  }

  React.useEffect(() => {

    const getRole = async () => {
      setCurrRole(await getData('role'));
    };

    setScreenWidth(Dimensions.get('window').width)

    if (fullScreen) {
      setDisplayWidth('100vw')
    } else if (!fullScreen && isDrawerOpen) {
      setDisplayWidth('calc(100vw - 250px)')
    } else if (!fullScreen && !isDrawerOpen) {
      setDisplayWidth('calc(100vw - 60px)')
    }

    if (fullScreen) {
      setMarginSize('0px')
    } else if (!fullScreen && isDrawerOpen) {
      setMarginSize('250px')
    } else if (!fullScreen && !isDrawerOpen) {
      setMarginSize('60px')
    }

    getRole();

    window.addEventListener('resize', () => {setScreenWidth(Dimensions.get('window').width)});

    return () => {
      window.removeEventListener('resize', () => {setScreenWidth(Dimensions.get('window').width)});
    }
  }, [fullScreen, isDrawerOpen]);

  const handleAddToCart = (item) => {
    const itemIndex = cartItems.findIndex((cartItem) => cartItem.id === item.id);

    setCurrPrice(currPrice + item.price);

    if (itemIndex !== -1) {
      const newCartItems = [...cartItems];
      newCartItems[itemIndex].quantity++;
      setCartItems(newCartItems);
    } else {
      const newCartItem = { ...item, quantity: 1 };
      setCartItems([...cartItems, newCartItem]);
    }
  };

  const handleRemoveFromCart = (item) => {

    // if you remove cart contents completely, reset the payment process...
    if (cartItems.length === 1 && cartItems[0].quantity === 1) {
      console.log("len: " + cartItems.length);
      setCurrScreen(0);
    }

    const itemIndex = cartItems.findIndex((cartItem) => cartItem.id === item.id);

    setCurrPrice(currPrice - item.price);

    if (itemIndex !== -1) {
      const newCartItems = [...cartItems];
      if (newCartItems[itemIndex].quantity > 1) {
        newCartItems[itemIndex].quantity--;
        setCartItems(newCartItems);
      } else {
        newCartItems.splice(itemIndex, 1);
        setCartItems(newCartItems);
      }
    }

  };

  const handleDrawerStateChange = (newState) => {
    setIsDrawerOpen(newState);
  };

  return (
    <View style={styles.back}>

      {!fullScreen && <Header
        value={null}
        onSelect={null}
        onDrawerStateChange={handleDrawerStateChange}
        role={currRole}
        hideDropdown={true}
        currentPage={'New Sale'}
      />}

      {screenWidth > 1000 &&
        <View style={{ display: 'flex', flexDirection: 'row', height: fullScreen ? '100%' : 'calc(100vh - 65px)', width: displayWidth, marginLeft: marginSize, }}>


          <View style={{ flex: isCartExpanded ? '0.7' : '1', overflow: 'auto', overflowX: 'hidden', height: '100%', }}>

            {/* ADDING AND REMOVING FROM CART */}
            {currScreen == 0 &&
              <View style={{ width: '100%', height: '100%', overflowX: 'auto' }} >
                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto', paddingTop: '40px', paddingBottom: '40px', marginTop: 'auto', marginBottom: 'auto' }}>
                  {ice_items.map((item) => (
                    <TouchableOpacity key={item.id} onPress={() => handleAddToCart(item)} style={[styles.itemsContainer, /*{marginLeft: 'auto', marginRight: 'auto'}*/]}>
                      <Image source={item.image} style={styles.itemImage} />
                      <Text style={styles.itemName}>{item.name}</Text>
                      <Text style={styles.itemPrice}>${item.price}</Text>
                    </TouchableOpacity>
                  ))}
                </View>
                <TouchableOpacity style={{ position: 'absolute', bottom: 20, right: 20 }} onPress={() => { setFullScreen(prev => !prev) }}>
                  <Feather name={fullScreen ? "minimize-2" : "maximize-2"} size={24} color="black" />
                </TouchableOpacity>
                <TouchableOpacity style={{ position: 'absolute', top: 20, right: 20 }} onPress={() => { setIsCartExpanded(prev => !prev) }}>
                  <Feather name={isCartExpanded ? "chevron-right" : "chevron-left"} size={24} color="black" />
                </TouchableOpacity>
              </View>
            }

            {/* DATE SELECTION */}
            {currScreen == 1 &&
              <View style={{ width: '100%', height: '100%', overflowX: 'auto' }} >
                {!enteringCard &&
                  <View style={{ boxSizing: 'border-box', margin: '0px', height: '100%', minWidth: '0px', borderRadius: '0.2rem', padding: '1rem', justifyContent: 'center', alignItems: 'center', }}>
                    {/* <View style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', overflow: 'hidden', position: 'relative', display: 'block', outline: '0px !important' }}> */}
                    <View style={{ width: '100%', boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                      <Ionicons name="card-outline" size={35} color="black" />
                      <Text style={{ fontSize: 24, fontWeight: 470, marginBottom: '5px', marginTop: '5px' }}>Waiting for Card Payment</Text>
                      <Text style={{ fontSize: 14, marginBottom: '20px' }}>Please insert, swipe, or tap credit card.</Text>
                    </View>
                    <TouchableOpacity style={{ backgroundColor: 'navy', margin: '0px', appearance: 'none', borderImage: 'initial', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', cursor: 'pointer', textTransform: 'capitalize', fontSize: '0.775rem', boxSizing: 'border-box', padding: '6px', borderWidth: '1px', borderStyle: 'solid', borderColor: 'black', borderRadius: '0.2rem', lineHeight: '1.25', verticalAlign: 'middle', textAlign: 'center', textDecoration: 'none', minWidth: '40px', }}
                      onPress={() => { setEnteringCard(true) }}
                    >
                      <Text style={{ fontWeight: 475, color: 'white' }}>Enter Card Details</Text>
                    </TouchableOpacity>

                  </View>
                }
                {enteringCard &&
                  <View style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto', paddingTop: '40px', paddingBottom: '40px', marginTop: 'auto', marginBottom: 'auto' }}>
                    <Payments paymentType={"sale"} orderContents={cartItems} showBanner={showBanner} />
                    <TouchableOpacity style={{ backgroundColor: 'navy', margin: '0px', marginTop: 20, appearance: 'none', borderImage: 'initial', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', cursor: 'pointer', textTransform: 'capitalize', fontSize: '0.775rem', boxSizing: 'border-box', padding: '6px', borderWidth: '1px', borderStyle: 'solid', borderColor: 'black', borderRadius: '0.2rem', lineHeight: '1.25', verticalAlign: 'middle', textAlign: 'center', textDecoration: 'none', minWidth: '40px', }}
                      onPress={() => { setEnteringCard(false) }}
                    >
                      <Text style={{ fontWeight: 475, color: 'white' }}>Use Card Reader</Text>
                    </TouchableOpacity>

                  </View>

                }
                <TouchableOpacity style={{ position: 'absolute', bottom: 20, right: 20 }} onPress={() => { setFullScreen(prev => !prev) }}>
                  <Feather name={fullScreen ? "minimize-2" : "maximize-2"} size={24} color="black" />
                </TouchableOpacity>
                <TouchableOpacity style={{ position: 'absolute', top: 20, right: 20 }} onPress={() => { setIsCartExpanded(prev => !prev) }}>
                  <Feather name={isCartExpanded ? "chevron-right" : "chevron-left"} size={24} color="black" />
                </TouchableOpacity>
              </View>
            }

            {/* CARD OR CASH */}
            {currScreen == 2 &&
              <View style={{ width: '100%', height: '100%', overflowX: 'auto' }} >
                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto', paddingTop: '40px', paddingBottom: '40px', marginTop: 'auto', marginBottom: 'auto' }}>

                </View>
                <TouchableOpacity style={{ position: 'absolute', bottom: 20, right: 20 }} onPress={() => { setFullScreen(prev => !prev) }}>
                  <Feather name={fullScreen ? "minimize-2" : "maximize-2"} size={24} color="black" />
                </TouchableOpacity>
                <TouchableOpacity style={{ position: 'absolute', top: 20, right: 20 }} onPress={() => { setIsCartExpanded(prev => !prev) }}>
                  <Feather name={isCartExpanded ? "chevron-right" : "chevron-left"} size={24} color="black" />
                </TouchableOpacity>
              </View>
            }
          </View>

          <View style={{ backgroundColor: 'white', boxShadow: '-2px 5px 10px 1px #aaaaaa', flex: isCartExpanded ? '0.3' : '0', height: '100%', overflow: 'auto', }} >

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', height: 60 }}>
              <View style={{ width: '80%', paddingTop: 20, borderBottomColor: 'lightgray', borderBottomWidth: '1px', alignItems: 'center', marginBottom: 10 }}>
                <Text style={{ fontSize: 22, fontWeight: 500 }}>Order Details</Text>
              </View>
            </View>

            {cartItems.map((cartItem) => {

              return (
                <View key={cartItem.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>
                  <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center' }}>
                    <View style={{ padding: 5, marginHorizontal: 20 }}>
                      <Image source={cartItem.image} style={styles.cartItemImage} />
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'center', flex: 1, marginRight: 20, marginVertical: 10 }}>
                      <Text style={{ fontSize: 19, fontWeight: 500 }}>{cartItem.name}</Text>
                      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', marginBottom: 20 }}>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                          <TouchableOpacity onPress={() => handleRemoveFromCart(cartItem)} >
                            <Feather name={'minus-circle'} size={18} color={'black'} style={{ marginRight: 5 }} />
                          </TouchableOpacity>
                          <Text style={{ fontSize: 16, fontWeight: 450, textAlign: 'center' }}>{cartItem.quantity}</Text>
                          <TouchableOpacity onPress={() => handleAddToCart(cartItem)} >
                            <Feather name={'plus-circle'} size={18} color={'black'} style={{ marginLeft: 5 }} />
                          </TouchableOpacity>
                        </View>
                        <Text style={{ fontSize: 16, fontWeight: 450 }}>${cartItem.price}/each</Text>
                      </View>
                    </View>
                  </View>
                </View>
              );
            })}

            {cartItems.length > 0 && (
              <View>
                <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignContent: 'flex-end' }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text style={{ marginTop: 10, marginRight: 10, float: 'right', }}>Price: ${(currPrice).toFixed(2)}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text style={{ marginTop: 5, marginRight: 10, float: 'right', }}>Tax (6%): ${(0.06 * currPrice).toFixed(2)}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text style={{ marginTop: 7.5, marginRight: 10, float: 'right', fontWeight: 'bold' }}>Final Price: ${(currPrice + (0.06 * currPrice)).toFixed(2)}</Text>
                  </View>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <TouchableOpacity onPress={() => {
                    if (currScreen === 1) {

                    }

                    setCurrScreen(currScreen - 1);
                  }} style={[styles.backButton, { opacity: currScreen <= 0 ? '0.5' : '1' }]} disabled={currScreen <= 0}>
                    <Text style={styles.backButtonLabel}>Back</Text>
                  </TouchableOpacity>
                  {currScreen <= 1 &&
                    <TouchableOpacity
                      onPress={() => { setCurrScreen(currScreen + 1) }} style={[styles.nextButton, { opacity: currScreen != 0 ? '0.5' : '1' }]}
                      disabled={(currScreen != 0)}
                    >

                      <Text style={styles.nextButtonLabel}>Next</Text>

                    </TouchableOpacity>}
                </View>
              </View>
            )}

          </View>

        </View>
      }
      {screenWidth <= 1024 &&
        <View style={{ display: 'flex', flexDirection: 'row', height: fullScreen ? '100%' : 'calc(100vh - 65px)', width: displayWidth, marginLeft: marginSize, }}>


          <View style={{ flex: isCartExpanded ? '0.7' : '1', overflow: 'auto', overflowX: 'hidden', height: '100%', }}>

            {/* ADDING AND REMOVING FROM CART */}
            {currScreen == 0 &&
              <View style={{ width: '100%', height: '100%', overflowX: 'auto' }} >
                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto', paddingTop: '40px', paddingBottom: '40px', marginTop: 'auto', marginBottom: 'auto' }}>
                  {ice_items.map((item) => (
                    <TouchableOpacity key={item.id} onPress={() => handleAddToCart(item)} style={[styles.itemsContainer, /*{marginLeft: 'auto', marginRight: 'auto'}*/]}>
                      <Image source={item.image} style={styles.itemImage} />
                      <Text style={styles.itemName}>{item.name}</Text>
                      <Text style={styles.itemPrice}>${item.price}</Text>
                    </TouchableOpacity>
                  ))}
                </View>
                <TouchableOpacity style={{ position: 'absolute', bottom: 20, right: 20 }} onPress={() => { setFullScreen(prev => !prev) }}>
                  <Feather name={fullScreen ? "minimize-2" : "maximize-2"} size={24} color="black" />
                </TouchableOpacity>
                <TouchableOpacity style={{ position: 'absolute', top: 20, right: 20 }} onPress={() => { setIsCartExpanded(prev => !prev) }}>
                  <Feather name={isCartExpanded ? "chevron-right" : "chevron-left"} size={24} color="black" />
                </TouchableOpacity>
              </View>
            }

            {/* DATE SELECTION */}
            {currScreen == 1 &&
              <View style={{ width: '100%', height: '100%', overflowX: 'auto' }} >
                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto', paddingTop: '40px', paddingBottom: '40px', marginTop: 'auto', marginBottom: 'auto' }}>

                </View>
                <TouchableOpacity style={{ position: 'absolute', bottom: 20, right: 20 }} onPress={() => { setFullScreen(prev => !prev) }}>
                  <Feather name={fullScreen ? "minimize-2" : "maximize-2"} size={24} color="black" />
                </TouchableOpacity>
                <TouchableOpacity style={{ position: 'absolute', top: 20, right: 20 }} onPress={() => { setIsCartExpanded(prev => !prev) }}>
                  <Feather name={isCartExpanded ? "chevron-right" : "chevron-left"} size={24} color="black" />
                </TouchableOpacity>
              </View>
            }

            {/* CARD OR CASH */}
            {currScreen == 2 &&
              <Text>test</Text>
            }
          </View>

          <View style={{ backgroundColor: 'red', boxShadow: '-2px 5px 10px 1px #aaaaaa', flex: isCartExpanded ? '0.3' : '0', height: '100%', overflow: 'auto', }} >

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', height: 60 }}>
              <View style={{ width: '80%', paddingTop: 20, borderBottomColor: 'lightgray', borderBottomWidth: '1px', alignItems: 'center', marginBottom: 10 }}>
                <Text style={{ fontSize: 22, fontWeight: 500 }}>Order Details</Text>
              </View>
            </View>

            {cartItems.map((cartItem) => {

              return (
                <View key={cartItem.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>
                  <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center' }}>
                    <View style={{ padding: 5, marginHorizontal: 20 }}>
                      <Image source={cartItem.image} style={styles.cartItemImage} />
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'center', flex: 1, marginRight: 20, marginVertical: 10 }}>
                      <Text style={{ fontSize: 19, fontWeight: 500 }}>{cartItem.name}</Text>
                      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', marginBottom: 20 }}>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                          <TouchableOpacity onPress={() => handleRemoveFromCart(cartItem)} >
                            <Feather name={'minus-circle'} size={18} color={'black'} style={{ marginRight: 5 }} />
                          </TouchableOpacity>
                          <Text style={{ fontSize: 16, fontWeight: 450, textAlign: 'center' }}>{cartItem.quantity}</Text>
                          <TouchableOpacity onPress={() => handleAddToCart(cartItem)} >
                            <Feather name={'plus-circle'} size={18} color={'black'} style={{ marginLeft: 5 }} />
                          </TouchableOpacity>
                        </View>
                        <Text style={{ fontSize: 16, fontWeight: 450 }}>${cartItem.price}/each</Text>
                      </View>
                    </View>
                  </View>
                </View>
              );
            })}

            {cartItems.length > 0 && (
              <View>
                <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignContent: 'flex-end' }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text style={{ marginTop: 10, marginRight: 10, float: 'right', }}>Price: ${(currPrice).toFixed(2)}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text style={{ marginTop: 5, marginRight: 10, float: 'right', }}>Tax (6%): ${(0.06 * currPrice).toFixed(2)}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text style={{ marginTop: 7.5, marginRight: 10, float: 'right', fontWeight: 'bold' }}>Final Price: ${(currPrice + (0.06 * currPrice)).toFixed(2)}</Text>
                  </View>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <TouchableOpacity onPress={() => {
                    if (currScreen === 1) {

                    }

                    setCurrScreen(currScreen - 1);
                  }} style={[styles.backButton, { opacity: currScreen < 1 ? '0.5' : '1' }]} disabled={currScreen < 1}>
                    <Text style={styles.backButtonLabel}>Back</Text>
                  </TouchableOpacity>
                  {currScreen <= 1 &&
                    <TouchableOpacity
                      onPress={() => { setCurrScreen(currScreen + 1) }} style={[styles.nextButton, { opacity: currScreen > 1 ? '0.5' : '1' }]}
                      disabled={(currScreen > 1)}
                    >

                      <Text style={styles.nextButtonLabel}>Next</Text>

                    </TouchableOpacity>}
                </View>
              </View>
            )}

          </View>

        </View>
      }
    </View >
  );
};




const styles = StyleSheet.create({
  itemsContainer: {
    padding: 10,
    borderRadius: 20,
    margin: 30,
    height: '210px',
    width: '210px',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: 'white',
    shadowColor: 'black',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 15,
  },
  cartItemImage: {
    width: '100px',
    height: '100px',
    borderRadius: 10,
    objectFit: 'cover',
  },
  itemImage: {
    width: '100%',
    height: '75%',
    borderRadius: 10,
  },
  itemName: {
    marginTop: 10,
    fontWeight: 'bold',
    fontSize: 16,
  },
  itemPrice: {
    marginTop: 5,
    color: '#888',
  },
  back: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FAFAFC',
  },
  heading: {
    height: '45px',
    fontSize: 40,
    fontWeight: 'bold',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center',
    color: 'black',
  },
  cartItemButton: {
    backgroundColor: '#eee',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginRight: 10,
  },
  cartItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  cartButton: {
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    padding: 5,
    marginHorizontal: 10,
  },
  cartButtonLabel: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  cartItemName: {
    flex: 1,
    marginLeft: 10,
  },
  cartItemPrice: {
    marginRight: 10,
  },
  cartItemQuantity: {
    paddingHorizontal: 10,
  },
  purchaseButton: {
    backgroundColor: 'green',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignSelf: 'center',
    marginVertical: 20,
    marginLeft: '15px',
  },
  purchaseButtonLabel: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
  backButton: {
    backgroundColor: '#DA012D',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignSelf: 'center',
    marginVertical: 20,
    marginRight: '15px',
  },
  backButtonLabel: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
  nextButton: {
    backgroundColor: '#FDD128',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginVertical: 20,
    alignSelf: 'center',
    marginLeft: '15px',
  },
  nextButtonLabel: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },

});

export default IcePurchaseScreen;