// import React, { useState } from 'react';
// import { View, Platform, Alert } from "react-native";
// import moment, { isDate, } from 'moment';
// import { API_URL } from '../../components/constants';
// import Banner from '../../components/Banner';
// import useFetchInterceptors from '../../hooks/useFetchInterceptors';

// // const API_URL = Platform.OS !== 'ios' ? 'http://localhost:5000' : 'http://10.0.2.2:5000';

// const TestPage = () => {

//     const [typeSubmitted, setTypeSubmitted] = useState('nothing yet')
//     const [numChair, setNumChair] = useState(0)
//     const [numUmbrella, setNumUmbrella] = useState(0)
//     const [bannerVisible, setBannerVisible] = useState(false);
//     const [bannerType, setBannerType] = useState('');
//     const [bannerMessage, setBannerMessage] = useState('');

//     useFetchInterceptors()

//     const showBanner = (type, message) => {
//         setBannerType(type);
//         setBannerMessage(message);
//         setBannerVisible(true);

//         // Hide the banner after 5 seconds
//         setTimeout(() => {
//             setBannerVisible(false);
//         }, 5500);
//     };

//     const handleEffect = () => {
//         // Trigger the effect
//         // For example, a successful action occurs
//         // Then, show the banner
//         console.log("test")
//         showBanner('success', 'Action successful!');
//     };

//     const [totals, setTotals] = useState({
//         totalPendingUmbrellas: 0,
//         totalPendingChairs: 0,
//         totalActiveUmbrellas: 0,
//         totalActiveChairs: 0,
//     });

//     function getRandomInt(min, max) {
//         min = Math.ceil(min);
//         max = Math.floor(max);
//         return Math.floor(Math.random() * (max - min + 1)) + min;
//     }

//     const fetchData1 = async () => {
//         //create notification
//         const payload = {
//             employee_id: 1,
//             message: 'New notification',
//             severity: 'Info',
//             relationType: 'Booking',
//             relatedId: 123,
//             roles: ['Ice', 'Boxes', 'Admin']
//         }
//         // fetch(`${API_URL}/change_inventory`, {
//         fetch(`${API_URL}/notifications`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Origin': 'http://localhost:19006',
//                 'Access-Control-Request-Method': 'POST',
//                 'Access-Control-Request-Headers': 'Content-Type, Authorization',
//             },
//             body: JSON.stringify(payload),
//         }).then(async res => {

//             try {
                
//                 if (res.status !== 201) {
//                     console.log(res.status)
//                 } else {
//                     const jsonRes = await res.json();
//                     console.log(jsonRes)

//                 }
//             } catch (err) {
//                 //setMessage(err);
//                 console.log(err);
//             };
//         })
//         .catch(err => {
//             console.log(err);
//         })

//     };

//     const fetchData2 = async () => {
        
//             fetch(`${API_URL}/notifications/active/1`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Origin': 'http://localhost:19006',
//                     'Access-Control-Request-Method': 'GET',
//                     'Access-Control-Request-Headers': 'Content-Type, Authorization',
//                 },
//             }).then(async res => {
//                 try {
//                     if (res.status !== 200) {
//                         console.log(res.status)
//                     } else {
//                         const jsonRes = await res.json();
//                         console.log(jsonRes)
//                     }
//                 } catch (err) {
//                     //setMessage(err);
//                     console.log(err);
//                 };
//             })
//             .catch(err => {
//                 console.log(err);
//             })
//     };

//     const fetchData3 = async () => {

//         // const payload = {
//         //     box_id: boxRef.current,
//         // total_umbrellas: umbrellasOverbooked.current,
//         // total_chairs: chairsOverbooked.current
//         // }
//         fetch(`${API_URL}/overbook/1`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Origin': 'http://localhost:19006',
//                 'Access-Control-Request-Method': 'POST',
//                 'Access-Control-Request-Headers': 'Content-Type, Authorization',
//             },
//             // body: JSON.stringify(payload),
//         })
//             .then(async res => {
//                 try {
//                     const jsonRes = await res.json();
//                     if (res.status !== 200) {
//                         console.log(jsonRes)
//                     } else {
//                         console.log(jsonRes)
//                         // location.reload();
//                     }
//                 } catch (err) {
//                     //setMessage(err);
//                     console.log(err);
//                 };
//             })

//         // const response2 = await fetch(`${API_URL}/bookings/` + 1 + '?includeBookingItem=true', {
//         //     method: 'GET',
//         //     headers: {
//         //       'Content-Type': 'application/json',
//         //       'Origin': 'http://localhost:19006',
//         //       'Access-Control-Request-Method': 'GET',
//         //       'Access-Control-Request-Headers': 'Content-Type, Authorization',
//         //     },
//         //   });

//         //   console.log(await response2.json())


//     };

//     const fetchData4 = async () => {
//         var notes = '';
//         var employee_id = 1
//         var assembled = false;
//         var temp = true;
//         var failed = false;
//         var delivery_address = 'test';
//         var payment_type = "card";
//         var customer_id = '1';

//         var box_id = 1

//         var start_date = moment()
//         var end_date = moment()

//         var repeat_sale_id = null;

//         var overbooked = 0;


//         const sampleBookingItems = [
//             { id: 2, quantity: getRandomInt(0, 6) }, // Chair
//             { id: 3, quantity: getRandomInt(0, 6) }, // Combo
//             { id: 1, quantity: getRandomInt(0, 6) }, // Umbrella
//         ];

//         const bookingItems = sampleBookingItems.map(item => ({
//             id: item.product_id,
//             quantity: item.quantity,
//         }));


//         const payload = {
//             employee_id,
//             box_id,
//             customer_id,
//             start_date,
//             end_date,
//             notes,
//             payment_type,
//             delivery_address,
//             temp,
//             failed,
//             assembled,
//             overbooked,
//             sampleBookingItems,
//         };

//         try {
//             const res = await fetch(`${API_URL}/bookings`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Origin': 'http://localhost:19006',
//                     'Access-Control-Request-Method': 'POST',
//                     'Access-Control-Request-Headers': 'Content-Type, Authorization',
//                 },
//                 body: JSON.stringify(payload),
//             });

//             if (res.status === 200) {
//                 const jsonRes = await res.json();
//                 console.log(jsonRes.booking_id);
//             } else {
//                 // Handle the case where the API call was not successful
//                 return false;
//             }
//         } catch (err) {
//             // Handle any errors that occur during the API call
//             setMessage(err);
//             console.log(err);
//             return false;
//         }
//     }

//     return (
//         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
//             {bannerVisible && <Banner type={bannerType} message={bannerMessage} see={bannerVisible} />}

//             <div>
//                 <h1>Test Page</h1>
//                 <button onClick={fetchData1}>Create test alert</button>
//                 <button onClick={fetchData2}>Get alerts</button>
//                 <button onClick={fetchData3}>Check inventory</button>
//                 <button onClick={fetchData4}>Add booking</button>
//                 <h1>Values just submitted:</h1>
//                 <p>Type: {typeSubmitted}</p>
//                 <p>Umbrella Count: {numUmbrella}</p>
//                 <p>Chair Count: {numChair}</p>
//                 <h1>Values just grabbed:</h1>
//                 <p>Total Pending Umbrellas: {totals.totalPendingUmbrellas}</p>
//                 <p>Total Pending Chairs: {totals.totalPendingChairs}</p>
//                 <p>Total Active Umbrellas: {totals.totalActiveUmbrellas}</p>
//                 <p>Total Active Chairs: {totals.totalActiveChairs}</p>
//             </div>
//         </div>
//     );
// };

// export default TestPage;


import React, { useState } from 'react';
import { View, Text, StyleSheet, Button, Modal, TouchableOpacity } from 'react-native';
import { API_URL } from '../../components/constants';

const TestPage = () => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <View style={styles.container}>
      <Text>Test Page</Text>
      <Button title="Open Modal" onPress={() => setModalVisible(true)} />
      <Modal
        visible={modalVisible}
        transparent={false}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <TouchableOpacity style={styles.closeButton} onPress={() => setModalVisible(false)}>
            <Text style={styles.closeButtonText}>X</Text>
          </TouchableOpacity>
          <iframe
            title="Iframe Content"
            src={API_URL + "/iframe"}
            style={styles.iframe}
          />
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    padding: 10,
    zIndex: 999,
  },
  closeButtonText: {
    color: 'white',
    fontSize: 24,
  },
  iframe: {
    // marginTop: 20,
    width: '90%',
    height: '80%',
    boxSizing: 'border-box',
    backgroundColor: 'white',
  },
});

export default TestPage;
