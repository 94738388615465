import React, { useEffect, useState, useRef } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ImageBackground, View, Text, StyleSheet, TouchableOpacity, TextInput, Platform, Image } from 'react-native';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_URL } from '../../components/constants';
import useAuth from '../../hooks/useAuth';
import { Feather } from '@expo/vector-icons';


const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PWD_CHARS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%])/;

const SignupScreen = ({ navigation }) => {

    const navigate = useNavigate();

    const { setAuth } = useAuth();

    const [email, setEmail] = useState('');
    const [nameFocused, setEmailFocused] = useState(false)
    const [validEmail, setValidEmail] = useState(false);

    const passRef = useRef()
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [validConfirmPassword, setValidConfirmPassword] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);




    const [activationKey, setActivationKey] = useState(null);

    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');

    const [queryParameters] = useSearchParams()


    useEffect(() => {

        passRef.current.focus()

        let email = queryParameters.get("email")
        if (email) {
            setEmail(email)
        }

    }, []);

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
        setValidConfirmPassword(password === confirmPassword && password != '');
    }, [password, confirmPassword])


    // set data to storage
    async function setData(key, value) {
        try {
            await AsyncStorage.setItem(key, value);
        } catch (error) {
            console.log(error);
        }
    }

    // get data from storage
    async function getData(key, defaultValue = null) {
        try {
            const value = await AsyncStorage.getItem(key);

            if (value !== null) {
                return value;
            } else {
                return defaultValue;
            }
        } catch (error) {
            console.log(error);
            return defaultValue;
        }
    }

    const onLoggedIn = async token => {
        fetch(`${API_URL}/private`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'no-referrer-when-downgrade',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(async res => {
                try {
                    const jsonRes = await res.json();
                    if (res.status === 200) {
                        setMessage(jsonRes.message);
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });


        const currRole = await getData("role")
        navigate('/home');
    }

    const onSubmitHandler = () => {

        const email_success = EMAIL_REGEX.test(email);
        const pass_success = PWD_REGEX.test(password);
        if (!email_success || !pass_success) {
            setMessage("Invalid Entry");
            return;
        }

        if (password != confirmPassword) {
            setIsError(true);
            setMessage("Passwords do not match");
            return;
        }

        let key = queryParameters.get("key")

        if (!key) {
            setIsError(true);
            setMessage("Invalid activation key");
            return;
        }

        const payload = {
            email,
            password,
            activationKey: key,
        };
        fetch(`${API_URL}/signup/invite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'no-referrer-when-downgrade',
                'Origin': 'https://www.jacobawebb.com',
                'Access-Control-Request-Method': 'POST',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            credentials: 'include',
            body: JSON.stringify(payload),
        })
            .then(async res => {
                try {
                    const jsonRes = await res.json();
                    if (res.status !== 200) {
                        setIsError(true);
                        setMessage(jsonRes.message);
                    } else {

                        var valueArr = [jsonRes.name, jsonRes.role, jsonRes.email, jsonRes.id]

                        await setData('name', valueArr[0])
                        await setData('role', valueArr[1])
                        await setData('email', valueArr[2])
                        await setData('user_id', valueArr[3])
                        await setData('date_selected', new Date())
                        console.log(valueArr[3])

                        onLoggedIn(jsonRes.token);
                        setAuth({ id: jsonRes.id, name: jsonRes.name, email: jsonRes.email, role: jsonRes.role, token: jsonRes.token, })
                        setIsError(false);
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <View style={styles.background}>
            <Image
                style={styles.image}
                source={require('../../public/images/nmblogo.png')}
            />
            <View style={styles.card}>
                <Text style={styles.heading}>Create Account</Text>
                <View style={styles.form}>
                    <View style={styles.inputs}>
                        <View style={[styles.input, { position: 'relative', zIndex: 15 }]} >
                            <TextInput style={{ width: '100%', height: '100%', color: 'white', fontSize: 16 }} placeholder="Email" autoCapitalize="none" value={email} onChangeText={setEmail} onFocus={() => setEmailFocused(true)} onBlur={() => setEmailFocused(false)} ></TextInput>
                        </View>
                        <View style={[styles.input, { position: 'relative', zIndex: 10 }]}>
                            <TextInput ref={passRef} secureTextEntry={!passwordVisible} style={styles.passwordInput} onFocus={() => setPasswordFocused(true)} onBlur={() => setPasswordFocused(false)} placeholder="Password" onChangeText={setPassword} />
                            <TouchableOpacity style={{ position: 'absolute', right: 5, top: 9.5 }} onPress={() => { setPasswordVisible((prev) => { return !prev }) }}>
                                {passwordVisible && <Feather name={'eye'} size={20} color={'white'} style={styles.visibilityIcon} />}
                                {!passwordVisible && <Feather name={'eye-off'} size={20} color={'white'} style={styles.visibilityIcon} />}
                            </TouchableOpacity>
                            <Text style={{ position: 'absolute', backgroundColor: 'orange', opacity: passwordFocused ? 1 : 0, top: 40, zIndex: 10, right: passwordFocused ? '' : -10000, width: '100%' }}>
                                8 to 24 characters. {<Feather name={password.length >= 8 && password.length <= 24 ? 'check' : 'x'} size={20} color={password.length >= 8 && password.length <= 24 ? 'green' : 'black'} style={{ alignSelf: 'center' }} />}<br />
                                Must include uppercase and lowercase letters, a number and a special character. {<Feather name={PWD_CHARS_REGEX.test(password) ? 'check' : 'x'} size={20} color={PWD_CHARS_REGEX.test(password) ? 'green' : 'black'} style={{ alignSelf: 'center' }} />}<br />
                                Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span><br />
                                Passwords must match {<Feather name={validConfirmPassword ? 'check' : 'x'} size={20} color={validConfirmPassword ? 'green' : 'black'} style={{ alignSelf: 'center' }} />}
                            </Text>
                        </View>
                        <View style={[styles.input, { position: 'relative', zIndex: 5, }]}>
                            <TextInput secureTextEntry={!confirmPasswordVisible} style={styles.passwordInput} onFocus={() => setConfirmPasswordFocused(true)} onBlur={() => setConfirmPasswordFocused(false)} placeholder="Confirm Password" onChangeText={setConfirmPassword} />
                            <TouchableOpacity style={{ position: 'absolute', right: 5, top: 9.5 }} onPress={() => { setConfirmPasswordVisible((prev) => { return !prev }) }}>
                                {confirmPasswordVisible && <Feather name={'eye'} size={20} color={'white'} style={styles.visibilityIcon} />}
                                {!confirmPasswordVisible && <Feather name={'eye-off'} size={20} color={'white'} style={styles.visibilityIcon} />}
                            </TouchableOpacity>
                            <Text style={{ position: 'absolute', backgroundColor: 'orange', opacity: confirmPasswordFocused ? 1 : 0, top: 40, zIndex: 10, right: confirmPasswordFocused ? '' : -10000, width: '100%', }}>
                                8 to 24 characters. {<Feather name={confirmPassword.length >= 8 && confirmPassword.length <= 24 ? 'check' : 'x'} size={20} color={confirmPassword.length >= 8 && confirmPassword.length <= 24 ? 'green' : 'black'} style={{ alignSelf: 'center' }} />}<br />
                                Must include uppercase and lowercase letters, a number and a special character. {<Feather name={PWD_CHARS_REGEX.test(confirmPassword) ? 'check' : 'x'} size={20} color={PWD_CHARS_REGEX.test(confirmPassword) ? 'green' : 'black'} style={{ alignSelf: 'center' }} />}<br />
                                Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span><br />
                                Passwords must match {<Feather name={validConfirmPassword ? 'check' : 'x'} size={20} color={validConfirmPassword ? 'green' : 'black'} style={{ alignSelf: 'center' }} />}
                            </Text>
                        </View>
                        <TouchableOpacity style={[styles.buttonAlt, { opacity: (!validPassword || !validEmail || !validConfirmPassword) ? 0.5 : 1 }]} onPress={onSubmitHandler} disabled={!validPassword || !validEmail || !validConfirmPassword}>
                            <Text style={styles.buttonAltText}>Submit</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                {isError &&
                    <View style={{ marginTop: 75 }}>
                        <Text style={{ color: 'red', fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}>{message}</Text>
                    </View>
                }
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    image: {
        marginTop: '3%',
        width: '10%',
        height: '22.5%',
        resizeMode: 'contain',
    },
    background: {
        flex: 1,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        backgroundColor: 'rgba(11, 11, 69, 1)',
        overflowX: 'hidden',
    },
    card: {
        flex: 1,
        width: '60%',
        borderRadius: 20,
        maxHeight: 380,
        paddingBottom: '20%',
    },
    heading: {
        fontSize: 30,
        fontWeight: 'bold',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '5%',
        marginBottom: '15%',
        color: 'white',
    },
    form: {
        flex: 1,
        justifyContent: 'space-between',
        paddingBottom: '5%',
    },
    inputs: {
        width: '100%',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
    },
    input: {
        width: '80%',
        borderBottomWidth: 1,
        borderBottomColor: 'white',
        fontSize: 16,
        minHeight: 40,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        color: 'white',
    },
    passwordInputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '80%',
        borderBottomWidth: 1,
        borderBottomColor: 'white',
        paddingTop: 10,
        fontSize: 16,
        minHeight: 40,
        color: 'white',
    },
    passwordInput: {
        flex: 1,
        color: 'white',
        fontSize: 16,
    },
    visibilityIcon: {
        width: 24,
        height: 24,
    },
    buttonAlt: {
        marginTop: '5%',
        width: '80%',
        borderWidth: 1,
        height: 40,
        borderRadius: 50,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 5,
    },
    buttonAltText: {
        color: 'white',
        fontSize: 16,
        fontWeight: '400',
    },
});

export default SignupScreen;