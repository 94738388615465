import React, { useEffect, useState } from "react";
import { View, TextInput } from "react-native";
import OverbookedAlert from "../OverbookedAlert";
import { useNavigate } from 'react-router-dom';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { API_URL } from "../constants";
import setupFetchInterceptors from "../../hooks/setupFetchInterceptors";
import { processReservation, handleSuccess, handleError } from "../../utils/PaymentUtils.js";


export default function CheckoutForm({ orderContents, price, dateRangeListFinal, usingReportedValues, showBanner, customerName, customerEmail }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false); // Step 1: Checkbox state
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [modalData, setModalData] = useState({
    sale_id: null,
    dateRange: null,
    rangeList: null,
    newChairs: null,
    newUmbrellas: null,
    oldChairs: null,
    oldUmbrellas: null,
    boxName: null,
    box_id: null,
    payment_element: null,
    inventory: null,
  });

  const navigate = useNavigate();

  setupFetchInterceptors()

  useEffect(() => {

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log(paymentIntent)
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleOK = (data, extraUmbrellas, extraChairs) => {
    // Handle OK button action
    setAlertVisible(false);
    setModalSuccess(true);

    console.log(data)
    console.log(extraUmbrellas)
    console.log(extraChairs)

    console.log("ARE WE ALREADY USING REPORTED VALUES? ", usingReportedValues)
    //update inventory values
    let payload = {
      // box_id: data.box_id,
      //set total_umbrellas or total_chairs equal to null to not add pending entry
      total_umbrellas: extraUmbrellas === 0 ? null : extraUmbrellas,
      total_chairs: extraChairs === 0 ? null : extraChairs,
      mode: 'reported',
    }
    // fetch(`${API_URL}/change_inventory`, {
    fetch(`${API_URL}/inventory/` + data.box_id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Origin': 'http://localhost:19006',
        'Access-Control-Request-Method': 'PUT',
        'Access-Control-Request-Headers': 'Content-Type, Authorization',
      },
      body: JSON.stringify(payload),
    })
      .then(async res => {

        try {
          const jsonRes = await res.json();
          if (res.status !== 200) {
          } else {

            handleSubmit(data.payment_element, 1) // again after inventory changes
          }
        } catch (err) {
          //setMessage(err);
          console.log(err);
        };
      })
      .catch(err => {
        console.log(err);
      })




  };

  const handleSubmit = async (e, overbooked_flag) => {
    e.preventDefault();

    console.log(overbooked_flag)

    if (!stripe || !elements || !isTermsAccepted || name === '' || email === '') {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    try {

      // Process all bookings
      const successfulSales = [];
      const failedSales = [];

      var flag = 0;
      var repeat_id = 0;
      for (const range of dateRangeListFinal) {
        var { success, modalDataRes, sale_id } = await processReservation(range, repeat_id, dateRangeListFinal, e, overbooked_flag, orderContents, modalSuccess, modalData);
        console.log(success, modalDataRes, sale_id)
        if (modalDataRes) {
          setModalData(modalDataRes)
          setAlertVisible(true);
        }
        if (success) {
          successfulSales.push(sale_id);
        } else {
          failedSales.push(range); // Handle reservation failure
        }
        if (flag === 0 && failedSales.length === 0) {
          console.log("Setting this here")
          repeat_id = sale_id;
          flag = 1;
        }
      }
      console.log("fail: " + failedSales)
      if (failedSales.length > 0) {
        console.log("Payment failed 1");
        handleError(repeat_id ? repeat_id : sale_id);
      } else {
        console.log("Successfully reserved");
        try {
          const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: "http://localhost:19006/#/new_booking/",
              payment_method_data: {
                billing_details: {
                  name: name, // Provide a valid name
                  email: email, // Provide a valid email
                  phone: '', // Provide a valid phone number
                  address: {
                    country: 'US', // Provide a valid country code
                    postal_code: '', // Provide a valid postal code
                    state: '', // Provide a valid state abbreviation
                    city: '', // Provide a valid city
                    line1: '', // Provide a valid address
                    line2: '',
                  }
                },
              },
              receipt_email: "jacob@jacobawebb.com",
            },
            redirect: 'if_required'
          });

          if (error) {
            // If credit card fails, remove the temporary booking from inventory and return appropriate error.
            console.error(error);
            setMessage(error.message)
            handleError(repeat_id ? repeat_id : sale_id);
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            // If the credit card succeeds, change the temporary booking to a legit one
            console.log("Payment succeeded");
            handleSuccess(repeat_id ? repeat_id : sale_id, customerName, customerEmail);
            showBanner('success', 'Successfully created booking!');
            navigate('/home')
          } else {
            console.log("Payment failed");
            handleError(repeat_id ? repeat_id : sale_id);
          }
        } catch (error) {
          setMessage(error);
          console.error(error);
        }
      }
    } catch (error) {
      // Handle any errors in the processReservation function
      console.error(error);
    }

    setIsLoading(false);
  };


  const paymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
    fields: {
      billingDetails: {
        name: 'never',
        email: 'never',
        address: 'never',
        phone: 'never',
      }
    },
  };


  return (
    // <View style={{ width: '100%', height: '100%', overflow: 'auto' }}>


    <form id="payment-form" onSubmit={handleSubmit}>
      {modalData && <OverbookedAlert
        onOK={handleOK}
        isVisible={isAlertVisible}
        data={modalData}
        onCancel={() => { setAlertVisible(false) }}
      />}


      {/* Name Input */}
      <TextInput
        type="text"
        name="name"
        placeholder="Name"
        value={name}
        onChangeText={(val) => { setName(val) }}
        style={styles.input}
      />

      {/* Email Input */}
      <TextInput
        type="email"
        name="email"
        placeholder="Email (optional receipt)"
        value={email}
        onChangeText={(val) => { setEmail(val) }}
        style={styles.input}
      />

      {/* <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.target.value)}
      /> */}
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      {/* Step 4: Add Terms of Service Checkbox */}
      <View style={{ marginBottom: '20px', paddingBottom: '20px', maxHeight: '100px', overflow: 'auto' }}>
        <label>
          <input
            type="checkbox"
            checked={isTermsAccepted}
            onChange={() => { setIsTermsAccepted(prev => !prev); }}
          />
          {/* I accept the <a href="/terms">Terms of Service</a>. */}
          * Terms and Conditions. For safety purposes, The City of North Myrtle Beach reserves the right to lower or remove rental umbrella canopies and rental chairs from the beach during adverse wind conditions as well as other weather-related situations. Purchase and use of rental does not guarantee a specific oceanfront location. Listed multiday rentals prices are for consecutive days only. The Renter agrees to pay for the use of rental chairs and umbrellas. In addition, the Renter, for himself/herself and his or her heirs, assigns, executors and administrators shall hereby release and waive any and all claims of injury and or any other causes of action, losses (including but not limited to claims of illness such as MRSA, influenza and COVID-19) associated with, arising out of, or in connection with the Renter’s use of rental chairs and umbrellas. The release and waiver shall include the City of North Myrtle Beach, its employees, administrators, elected officials and agents. No refunds.
        </label>
      </View>

      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
    // </View>
  );

}


const styles = {
  input: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    border: '1px solid #ccc',
    padding: '10px',
    marginBottom: '10px',
    width: '100%',
    boxSizing: 'border-box',
  },
};