import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TextInput, Animated, Platform, Touchable } from 'react-native';
import { API_URL } from '../constants';
import CreateNotice from '../Home/Notices';
import setupFetchInterceptors from '../../hooks/setupFetchInterceptors';


const LIST_BORDER_COLOR = "#E6E6E6"
const cellWidth = 174.166667;

const NoticesList = ({
    filteredDataSource = [],
    onFilter = () => { },
    updateFilteredDataSource = () => { },
    updateHeaderFilter = () => { },
    hidingArchived = {},
    salesFilter = "Today",
    onChangeSalesFilter = {},
}) => {

    const [settingNotice, setSettingNotice] = useState(false);
    const [oldBody, setOldBody] = useState(null)
    const [oldTitle, setOldTitle] = useState(null)


    setupFetchInterceptors()



    const updateValue = async (notice_id, type, new_val, old_val) => {

        console.log(type)
        console.log(new_val)
        console.log(old_val)

        if(old_val === null){
            return;
        }

        if (new_val !== old_val) {
            let payload;

            payload = type === "body" ? { body: new_val } : { title: new_val }
            console.log(payload)
            fetch(`${API_URL}/notices/` + notice_id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'http://localhost:19006',
                    'Access-Control-Request-Method': 'PUT',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                },
                body: JSON.stringify(payload),
            })
                .then(async res => {
                    try {
                        const jsonRes = await res.json();
                        if (res.status !== 200) {
                            console.log(res.statusText)
                        } else {
                            console.log("Success")
                        }
                    } catch (err) {
                        //setMessage(err);
                        console.log(err);
                    };
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }


    const createNotice = (title, body, start_date, end_date, urgent) => {

        var employee_id = userId;

        console.log(start_date)
        console.log(end_date)

        const payload = {
            title,
            body,
            start_date,
            end_date,
            urgent,
            employee_id
        }
        fetch(`${API_URL}/notices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'https://www.jacobawebb.com',
                'Access-Control-Request-Method': 'POST',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            body: JSON.stringify(payload),
        }).then(async res => {
            try {
                if (res.status !== 201) {
                    //problemo
                    console.log(res)
                } else {
                    fetchNotices();
                    showBanner('success', 'Successfully created a notice!');
                    console.log("Successfully created notice!")
                }
            } catch (err) {
                console.log(err);
            };
        })
            .catch(err => {
                console.log(err);
            });

        setSettingNotice(false);
    }



    const renderHeader = () => {

        const headerContent = [];
        headerContent.push(

            <View style={{ verticalAlign: 'bottom', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>ID</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Title</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Body</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Start Date</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>End Date</Text>
                    </View>
                </View>

                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Created By</Text>
                    </View>
                </View>
            </View>
        )
        return (
            headerContent
        );
    }

    const renderContent = () => {

        return (
            filteredDataSource.map((item, index) => (

                <View key={item.employee_id} style={{ zIndex: -1 - index, verticalAlign: 'bottom', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: index % 2 != 0 ? '#F3F3F3' : 'FFFFFF' }}>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{item.notice_id}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', position: 'relative', width: '100%' }}>
                            {/* <TextInput onBlur={(name) => {updateName(item.id, name, item.name);}} style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', appearance: 'none', borderImage: 'initial', display: 'block', height: 'auto', paddingRight: '0.5rem', paddingLeft: '0.5rem', paddingTop: '0.75rem', paddingBottom: '0.75rem', fontSize: '12px', fontWeight: 'normal', lineHeight: '0.75', backgroundColor: 'red', color: 'black', backgroundColor: 'white', backgroundClip: 'padding-box', border: '1px solid ' + LIST_BORDER_COLOR, borderRadius: '0.2rem', width: '100%', }} value={item.name} onChangeText={(text) => {item.name = text}}></TextInput> */}
                            {/* <TextInput onBlur={(name) => {updateName(item.id, name, item.name);}} style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', appearance: 'none', borderImage: 'initial', display: 'block', height: 'auto', paddingRight: '0.5rem', paddingLeft: '0.5rem', paddingTop: '0.75rem', paddingBottom: '0.75rem', fontSize: '12px', fontWeight: 'normal', lineHeight: '0.75', backgroundColor: 'red', color: 'black', backgroundColor: 'white', backgroundClip: 'padding-box', border: '1px solid ' + LIST_BORDER_COLOR, borderRadius: '0.2rem', width: '100%', }} defaultValue={item.name} placeholder={item.name} onChangeText={(text) => {item.name = text}}></TextInput> */}

                            <TextInput
                                // Use the value prop to bind the text input to the filtered data
                                value={item.title}

                                onFocus={() => {setOldTitle(item.title)}}
                                // onChangeText should update the state when the text changes
                                onChangeText={(text) => {

                                    // Create a copy of the data to avoid mutations
                                    const updatedData = filteredDataSource.map((dataItem) => {
                                        if (dataItem.notice_id === item.notice_id) {
                                            // Update the name for the specific item
                                            return { ...dataItem, title: text };
                                        }
                                        return dataItem;
                                    });
                                    // Update the filtered data state
                                    updateFilteredDataSource(updatedData);
                                }}

                                onBlur={(new_title) => { updateValue(item.notice_id, "title", new_title.nativeEvent.text, oldTitle) }}

                                style={{
                                    boxSizing: 'border-box',
                                    margin: '0px',
                                    minWidth: '0px',
                                    appearance: 'none',
                                    borderImage: 'initial',
                                    display: 'block',
                                    height: 'auto',
                                    paddingRight: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.75rem',
                                    paddingBottom: '0.75rem',
                                    fontSize: '12px',
                                    fontWeight: 'normal',
                                    lineHeight: '0.75',
                                    backgroundColor: 'white', // Fix the background color
                                    color: 'black',
                                    backgroundClip: 'padding-box',
                                    border: '1px solid ' + LIST_BORDER_COLOR,
                                    borderRadius: '0.2rem',
                                    width: '100%',
                                }}
                                placeholder="Name" // Set the placeholder text here
                            />


                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', position: 'relative', width: '100%' }}>
                                <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', position: 'relative', width: '100%' }}>

                                    {/* <TextInput onBlur={(value) => finishEditing(value)} defaultValue={currentNotes} onChangeText={setCurrentNotes} multiline style={{ height: '100%', width: '100%', color: 'black', marginTop: '0.1%', fontStyle: 'italic', fontSize: '10px', padding: '0.5%', wordWrap: 'break-word' }} ></TextInput> */}
                                    {/* <TextInput onBlur={(email) => updateEmail(item.id, email, item.email)} style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', appearance: 'none', borderImage: 'initial', display: 'block', height: 'auto', paddingRight: '0.5rem', paddingLeft: '0.5rem', paddingTop: '0.75rem', paddingBottom: '0.75rem', fontSize: '12px', fontWeight: 'normal', lineHeight: '0.75', backgroundColor: 'red', color: 'black', backgroundColor: 'white', backgroundClip: 'padding-box', border: '1px solid ' + LIST_BORDER_COLOR, borderRadius: '0.2rem', width: '100%', }} value={item.email} onChangeText={(text) => item.email = text}></TextInput> */}

                                    <TextInput
                                        // Use the value prop to bind the text input to the filtered data
                                        value={item.body}

                                        onFocus={() => setOldBody(item.body)}
                                        // onChangeText should update the state when the text changes
                                        onChangeText={(text) => {
                                            // let old_value;
                                            // Create a copy of the data to avoid mutations
                                            const updatedData = filteredDataSource.map((dataItem) => {
                                                if (dataItem.notice_id === item.notice_id) {
                                                    // Update the name for the specific item
                                                    console.log(dataItem)
                                                    // old_value = dataItem.body
                                                    
                                                    return { ...dataItem, body: text };
                                                }
                                                return dataItem;
                                            });
                                            // Update the filtered data state
                                            updateFilteredDataSource(updatedData);
                                        }}

                                        onBlur={(new_body) => { updateValue(item.notice_id, "body", new_body.nativeEvent.text, oldBody) }}

                                        style={{
                                            boxSizing: 'border-box',
                                            margin: '0px',
                                            minWidth: '0px',
                                            appearance: 'none',
                                            borderImage: 'initial',
                                            display: 'block',
                                            height: 'auto',
                                            paddingRight: '0.5rem',
                                            paddingLeft: '0.5rem',
                                            paddingTop: '0.75rem',
                                            paddingBottom: '0.75rem',
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            lineHeight: '0.75',
                                            backgroundColor: 'white', // Fix the background color
                                            color: 'black',
                                            backgroundClip: 'padding-box',
                                            border: '1px solid ' + LIST_BORDER_COLOR,
                                            borderRadius: '0.2rem',
                                            width: '100%',
                                        }}
                                        placeholder="Email" // Set the placeholder text here
                                    />

                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ width: '100%', height: '100%', alignItems: 'center', flex: '1', justifyContent: 'center', zIndex: -1 }}>
                            <Text style={styles.itemText}>{item.start_date}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ width: '100%', height: '100%', alignItems: 'center', flex: '1', justifyContent: 'center', zIndex: -1 }}>
                            <Text style={styles.itemText}>{item.end_date}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ width: '100%', height: '100%', alignItems: 'center', flex: '1', justifyContent: 'center', zIndex: -1 }}>
                            <Text style={styles.itemText}>{item.employee.name}</Text>
                        </View>
                    </View>
                </View>
            )));
    }


    return (
        <View style={{ width: '100%', marginBottom: '1rem', color: 'black', backgroundColor: 'white', borderCollapse: 'collapse', borderRight: '1px solid ' + LIST_BORDER_COLOR, borderLeft: '1px solid ' + LIST_BORDER_COLOR, borderBottom: '1px solid ' + LIST_BORDER_COLOR, }}>
            {<CreateNotice
                onCreate={(title, body, startDate, endDate, urgent) => { console.log("TEST YOO"); createNotice(title, body, startDate, endDate, urgent) }}
                isVisible={settingNotice}
                onCancel={() => { setSettingNotice(false) }}
            />}
            {renderHeader()}
            {renderContent()}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        height: '91.5%',
    },
    itemStyle: {
        flexDirection: "row",
        alignItems: "center",
        height: '5%',
        flexWrap: 'nowrap',
        width: '100%',
    },
    column: {
        padding: 3,
        overflow: 'hidden',
    },
    textInputStyle: {
        height: '100%',
        borderWidth: 1,
        paddingLeft: 20,
        margin: 2.5,
        borderColor: '#009688',
        width: '99%',
    },
    columnSeparator: {
        width: '0.1%',
        height: '100%',
        backgroundColor: 'lightgray',
        borderBottom: '2px solid ' + LIST_BORDER_COLOR,
    },
    headerItemText: {
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    itemText: {
        whiteSpace: 'nowrap',
        width: '100%',
    },
    dropdownTextStyle: {
        height: '300pt',
        backgroundColor: 'lightgray',
        borderRadius: 2,
        overflow: 'auto',
    },
});

export default NoticesList;