import React, { useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { View, Text, StyleSheet, TouchableOpacity, Image, TextInput, } from 'react-native';
import Header from '../../components/Header';
import Calendar from '../../components/Calendars/CalendarRange';
import Payments from '../../components/Rentals/Payments';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../components/constants';
import { Feather } from '@expo/vector-icons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import UniversalAlert from '../../components/UniversalAlert';
import setupFetchInterceptors from '../../hooks/setupFetchInterceptors';

import { processReservation, handleSuccess } from '../../utils/PaymentUtils';



const PurchaseScreen = ({ showBanner }) => {

  const navigate = useNavigate();

  let selection = [{ id: 1, name: 'Box 1' }, { id: 2, name: 'Box 2' }, { id: 3, name: 'Box 3' }, { id: 4, name: 'Box 4' }, { id: 5, name: 'Box 4B' }, { id: 6, name: 'Box 5' }, { id: 7, name: 'Box 5B' }, { id: 8, name: 'Box 6' }, { id: 9, name: 'Box 6B' }, { id: 10, name: 'Box 7' }, { id: 11, name: 'Box 8' }, { id: 12, name: 'Box 9' }, { id: 13, name: 'Box 10' }, { id: 14, name: 'Box 10B' }, { id: 15, name: 'Box 11' }, { id: 16, name: 'Box 12' }, { id: 17, name: 'Box 12B' }, { id: 18, name: 'Box 13' }, { id: 19, name: 'Box 14' }, { id: 20, name: 'Box 15' }, { id: 21, name: 'Box 15B' }, { id: 22, name: 'Box 16' }, { id: 23, name: 'Box 17' }, { id: 24, name: 'Box 18' }, { id: 25, name: 'Box 19' }, { id: 26, name: 'Box 20' }, { id: 27, name: 'Box 21' }, { id: 28, name: 'Box 22' }, { id: 29, name: 'Box 22B' }, { id: 30, name: 'Box 23' }, { id: 31, name: 'Box 24' }, { id: 32, name: 'Box 25' }, { id: 33, name: 'Box 26' }, { id: 34, name: 'Box 27' }, { id: 35, name: 'Box 28' }, { id: 36, name: 'Box 29' }, { id: 37, name: 'Box 30' }, { id: 38, name: 'Box 31' }, { id: 39, name: 'Box 32' }, { id: 40, name: 'Box 33' }, { id: 41, name: 'Box 34' }, { id: 42, name: 'Box 35' }, { id: 43, name: 'Box 36' }, { id: 44, name: 'Box 37' }, { id: 45, name: 'Box 37B' }, { id: 46, name: 'Box 38' }, { id: 47, name: 'Box 39' }, { id: 48, name: 'Box 40' }, { id: 49, name: 'Box 40B' }, { id: 50, name: 'Box 41' }, { id: 51, name: 'Box 42' }, { id: 52, name: 'Box 43' }, { id: 53, name: 'Box 44' }, { id: 54, name: 'Box 45' }, { id: 55, name: 'Box 46' }, { id: 56, name: 'Box 46B' }]

  const items = [{ id: 2, name: 'Chair', price: 20, image: require('../../public/images/chair.jpg'), },
  { id: 1, name: 'Umbrella', price: 30, image: require('../../public/images/umbrella.jpg'), },
  { id: 3, name: 'Combo', price: 45, image: require('../../public/images/combo.jpg'), },];

  const ice_items = [
    { id: 4, name: 'Large', price: 9, image: require('../../public/images/ice_images/large.jpg') },
    { id: 5, name: 'Small', price: 6, image: require('../../public/images/ice_images/small.jpg') },
  ]

  const [selectedItem, setSelectedItem] = useState(null);
  const [numCombos, setCombos] = useState(0);
  const [numUmbrellas, setUmbrellas] = useState(0);
  const [numChairs, setChairs] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [currRole, setCurrRole] = useState("");
  const [currPrice, setCurrPrice] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  //hook for customer details
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');

  //hook used to change between the payment screens
  const [currScreen, setCurrScreen] = useState(0);

  //hook used to pick payment method
  const [cashModalVisible, setCashModalVisible] = useState(false);

  //hook used to determine if a valid date has been selected by the calendar component
  const [dateSelected, setDateSelected] = useState(false);

  //
  const [selectedDateRangeFinal, setSelectedDateRangeFinal] = useState([]);
  const [dateRangeList, setDateRangeList] = useState([])

  //today's inventory object
  const [todaysInventory, setTodaysInventory] = useState(null);
  const [rentedChairs, setRentedChairs] = useState(null);
  const [rentedUmbrellas, setRentedUmbrellas] = useState(null);
  const [rentedCombos, setRentedCombos] = useState(null);

  const [chairReported, setChairReported] = useState(false);
  const [umbrellaReported, setUmbrellaReported] = useState(false);
  const [comboReported, setComboReported] = useState(false);

  const [pendingValues, setPendingValues] = useState(false);
  const [pendingUmbrellas, setPendingUmbrellas] = useState(null);
  const [pendingChairs, setPendingChairs] = useState(null);
  const [pendingCombos, setPendingCombos] = useState(null);

  const [isCartExpanded, setIsCartExpanded] = useState(true);

  const [fullScreen, setFullScreen] = useState(false)

  const [boxChangeAlert, setBoxChangeAlert] = useState(false);
  const [switchingTo, setSwitchingTo] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalData, setModalData] = useState({
    sale_id: null,
    dateRange: null,
    rangeList: null,
    newChairs: null,
    newUmbrellas: null,
    oldChairs: null,
    oldUmbrellas: null,
    boxName: null,
    box_id: null,
    payment_element: null,
    inventory: null,
  });

  setupFetchInterceptors()

  const handleToggleCart = () => {
    setIsCartExpanded((prev) => !prev);
  };

  const calculateCombos = (activeInventory, pendingInventory) => {
    const activeUmbrellas = activeInventory.find(item => item.productName === 'umbrella')?.quantity || 0;
    const activeChairs = activeInventory.find(item => item.productName === 'chair')?.quantity || 0;

    const pendingUmbrellas = pendingInventory.find(item => item.productName === 'umbrella')?.quantity || 0;
    const pendingChairs = pendingInventory.find(item => item.productName === 'chair')?.quantity || 0;

    const activeCombos = Math.min(Math.floor(activeUmbrellas / 1), Math.floor(activeChairs / 2));
    const pendingCombos = Math.min(Math.floor(pendingUmbrellas / 1), Math.floor(pendingChairs / 2));

    return { activeCombos, pendingCombos };
  };

  // set data to storage
  async function setData(key, value) {
    try {
      await AsyncStorage.setItem(key, value);
    } catch (error) {
      console.log(error);
    }
  }

  // get data from storage
  async function getData(key, defaultValue = null) {
    try {
      const value = await AsyncStorage.getItem(key);

      if (value !== null) {
        return value;
      } else {
        return defaultValue;
      }
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  }

  const onDateChange = (valid, selectedDateRange, dateRangeList) => {
    if (valid) {
      setDateSelected(true);
      setSelectedDateRangeFinal(selectedDateRange);
      setDateRangeList(dateRangeList);
      console.log(dateRangeList)
    }
    else {
      setDateSelected(false);
      setSelectedDateRangeFinal([]);
      setDateRangeList([]);
    }
  };

  const refreshDropDown = async (box) => {



    if (await getData('boxName') !== null) {
      var jsonString = await getData('boxName')
      var currBox = box != null ? box : JSON.parse(jsonString)
      if (currBox.name) {

        console.log(currBox)
        setSelectedItem(currBox)


        // /get_location_inventory
        // fetch(`${API_URL}/get_location_inventory`, {
        fetch(`${API_URL}/inventory/` + currBox.id, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Origin': 'http://localhost:19006',
            'Access-Control-Request-Method': 'GET',
            'Access-Control-Request-Headers': 'Content-Type, Authorization',
          },
          // body: JSON.stringify({ box_id: currBox.id }),
        })
          .then(async res => {
            try {
              const jsonRes = await res.json();
              console.log(jsonRes)
              if (res.status !== 200) {
              } else {

                let activeUmbrella = 0;
                let activeChair = 0;
                let pendingUmbrella = 0;
                let pendingChair = 0;

                jsonRes.inventory.forEach(item => {
                  if (item.status === 'active') {
                    if (item.productName === 'umbrella') {
                      activeUmbrella += item.quantity;
                    } else if (item.productName === 'chair') {
                      activeChair += item.quantity;
                    }
                  } else if (item.status === 'pending') {
                    if (item.productName === 'umbrella') {
                      pendingUmbrella = item.quantity;
                    } else if (item.productName === 'chair') {
                      pendingChair = item.quantity;
                    }
                  }
                });

                const { activeCombos, pendingCombos } = calculateCombos([
                  { productName: 'umbrella', quantity: activeUmbrella },
                  { productName: 'chair', quantity: activeChair },
                ], [
                  { productName: 'umbrella', quantity: pendingUmbrella },
                  { productName: 'chair', quantity: pendingChair },
                ]);

                const list = {
                  umbrella_total: activeUmbrella,
                  chair_total: activeChair,
                  combo_total: activeCombos,
                };

                console.log(list)

                if (pendingUmbrella > 0) {
                  setPendingValues(true)
                  setPendingUmbrellas(pendingUmbrella)
                  setPendingCombos(pendingCombos)
                }
                if (pendingChair > 0) {
                  setPendingValues(true)
                  setPendingChairs(pendingChair)
                  setPendingCombos(pendingCombos)
                }

                setTodaysInventory(list);
              }
            } catch (err) {
              console.log(err);
            };
          })
          .catch(err => {
            console.log(err);
          });

        // fetch(`${API_URL}/get_todays_inventory`, {
        fetch(`${API_URL}/bookings/` + currBox.id + '?today=true', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Origin': 'http://localhost:19006',
            'Access-Control-Request-Method': 'GET',
            'Access-Control-Request-Headers': 'Content-Type, Authorization',
          },
          // body: JSON.stringify({ box_id: currBox.id }),
        })
          .then(async res => {
            try {

              if (res.status !== 200) {
                console.log("here 1")
                setRentedChairs(0);
                setRentedUmbrellas(0);
                setRentedCombos(0);
              } else if (res.status === 200) {

                const jsonRes = await res.json();
                console.log(jsonRes)

                if (jsonRes.inventory) {
                  // Initialize variables to store totals
                  let totalChairs = 0;
                  let totalUmbrellas = 0;
                  let totalCombos = 0;

                  // Loop through the inventory and calculate totals
                  // jsonRes.inventory.forEach(item => {
                  //   totalChairs += parseInt(item.total_chairs, 10);
                  //   totalUmbrellas += parseInt(item.total_umbrellas, 10);;
                  //   totalCombos += parseInt(item.total_combos, 10);
                  // });

                  // Loop through bookings
                  jsonRes.inventory.forEach(booking => {
                    console.log(booking)

                    // Loop through booking items
                    booking.bookingItems.forEach(bookingItem => {
                      console.log(bookingItem)
                      // Assuming product_id 1 is for umbrellas and product_id 2 is for chairs
                      if (bookingItem.product_id === 1) {
                        totalUmbrellas += bookingItem.quantity;
                      } else if (bookingItem.product_id === 2) {
                        totalChairs += bookingItem.quantity;
                      } else if (bookingItem.product_id === 3) {
                        totalCombos += bookingItem.quantity;
                      }
                    });
                  });


                  setRentedChairs(totalChairs);
                  setRentedUmbrellas(totalUmbrellas);
                  setRentedCombos(totalCombos);
                } else {
                  setRentedChairs(0);
                  setRentedUmbrellas(0);
                  setRentedCombos(0);
                }
              }
            } catch (err) {
              console.log(err);
            };
          })
          .catch(err => {
            console.log(err);
          });

      }
    }
  }

  React.useEffect(() => {

    const getRole = async () => {
      setCurrRole(await getData('role'));
    };

    getRole();


    // const refreshDropDown = async () => {
    //   if (await getData('boxName') !== null) {
    //     var jsonString = await getData('boxName')
    //     var currBox = box != null ? box : JSON.parse(jsonString)
    //     refreshDropDown(null)
    //   }
    // }

    refreshDropDown(null)


    return;
  }, []);

  const onSelect = async (item) => {

    if (currScreen != 0) {
      setBoxChangeAlert(true)
      setSwitchingTo(item)
    } else {
      await setData('boxName', JSON.stringify(item))
      setSelectedItem(item)
      refreshDropDown(item)
    }

  }

  const onConfirmBoxChange = async () => {
    await setData('boxName', JSON.stringify(switchingTo))
    setSelectedItem(switchingTo)
    refreshDropDown(switchingTo)
    setCurrScreen(0)
    setCartItems([])
    setBoxChangeAlert(false);
    setSwitchingTo(null)
  }

  const handleAddToCart = (item) => {
    const itemIndex = cartItems.findIndex((cartItem) => cartItem.id === item.id);

    setCurrPrice(currPrice + item.price);

    if (itemIndex !== -1) {
      const newCartItems = [...cartItems];
      newCartItems[itemIndex].quantity++;
      setCartItems(newCartItems);
    } else {
      const newCartItem = { ...item, quantity: 1 };
      setCartItems([...cartItems, newCartItem]);
    }
  };

  const handleRemoveFromCart = (item) => {

    // if you remove cart contents completely, reset the payment process...
    if (cartItems.length === 1 && cartItems[0].quantity === 1) {
      console.log("len: " + cartItems.length);
      setCurrScreen(0);
      setSelectedDateRangeFinal([]);
      setDateSelected(false);
    }

    const itemIndex = cartItems.findIndex((cartItem) => cartItem.id === item.id);

    setCurrPrice(currPrice - item.price);

    if (itemIndex !== -1) {
      const newCartItems = [...cartItems];
      if (newCartItems[itemIndex].quantity > 1) {
        newCartItems[itemIndex].quantity--;
        setCartItems(newCartItems);
      } else {
        newCartItems.splice(itemIndex, 1);
        setCartItems(newCartItems);
      }
    }

  };

  const handleDrawerStateChange = (newState) => {
    setIsDrawerOpen(newState);
  };

  const handleCashPayment = async (overbooked_flag, name, email, selectedDates, dateRanges, reported) => {

    console.log(overbooked_flag)

    if (false) {
      // Stripe.js hasn't yet loaded.
      console.log("DISPLAY MESSAGE ABOUT TERMS NOT BEING ACCEPTED")
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    try {

      // Process all bookings
      const successfulSales = [];
      const failedSales = [];

      var flag = 0;
      var repeat_id = 0;
      for (const range of dateRanges) {
        var { success, modalDataRes, sale_id } = await processReservation(range, repeat_id, dateRanges, null, overbooked_flag, cartItems, modalSuccess, modalData);
        console.log(success, modalDataRes, sale_id)
        if (modalDataRes) {
          setModalData(modalDataRes)
          setAlertVisible(true);
        }
        if (success) {
          successfulSales.push(sale_id);
        } else {
          failedSales.push(range); // Handle reservation failure
        }
        if (flag === 0 && failedSales.length === 0) {
          console.log("Setting this here")
          repeat_id = sale_id;
          flag = 1;
        }
      }
      console.log("fail: " + failedSales)
      if (failedSales.length > 0) {
        console.log("Payment failed 1");
        handleError(repeat_id ? repeat_id : sale_id);
      } else {
        console.log("Successfully reserved");
        try {
          handleSuccess(repeat_id ? repeat_id : sale_id);
          showBanner('success', 'Successfully created booking!');
          navigate('/home')
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      // Handle any errors in the processReservation function
      console.error(error);
    }

    setIsLoading(false);
  };



  return (
    <View style={styles.back}>
      <Header
        value={selectedItem}
        onSelect={onSelect}
        onDrawerStateChange={handleDrawerStateChange}
        role={currRole}
        currentPage={'Create Booking'}
      />

      <UniversalAlert
        isVisible={boxChangeAlert}
        message={"WARNING! Changing box now will reset payment"}
        onOK={() => { onConfirmBoxChange() }}
        onCancel={() => { setBoxChangeAlert(false) }}
      />

      <UniversalAlert
        isVisible={cashModalVisible}
        message={"Confirm cash purchase?"}
        onOK={() => { handleCashPayment(0, customerName, customerEmail, selectedDateRangeFinal, dateRangeList, true) }}
        onCancel={() => { setCashModalVisible(false) }}
      />


      <View style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 65px)', width: isDrawerOpen ? 'calc(100vw - 250px)' : 'calc(100vw - 60px)', marginLeft: isDrawerOpen ? '250px' : '60px', }}>

        <View style={{ flex: isCartExpanded ? '0.7' : '1', overflow: 'auto', overflowX: 'hidden', height: '100%', }}>

          {/* ADDING AND REMOVING FROM CART */}
          {currScreen == 0 &&
            <View style={{ width: '100%', height: '100%', overflowX: 'auto' }} >
              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto', paddingTop: '40px', paddingBottom: '40px', marginTop: 'auto', marginBottom: 'auto' }}>
                {items.map((item) => (
                  <TouchableOpacity key={item.id} onPress={() => handleAddToCart(item)} style={styles.itemsContainer}>
                    <Image source={item.image} style={styles.itemImage} />
                    <Text style={styles.itemName}>{item.name}</Text>
                    <Text style={styles.itemPrice}>${item.price}/day</Text>
                  </TouchableOpacity>
                ))}
              </View>
              <TouchableOpacity style={{ position: 'absolute', bottom: 20, right: 20 }} onPress={() => { setFullScreen(prev => !prev) }}>
                <Feather name={fullScreen ? "minimize-2" : "maximize-2"} size={24} color="black" />
              </TouchableOpacity>
              <TouchableOpacity style={{ position: 'absolute', top: 20, right: 20 }} onPress={() => { setIsCartExpanded(prev => !prev) }}>
                <Feather name={isCartExpanded ? "chevron-right" : "chevron-left"} size={24} color="black" />
              </TouchableOpacity>
            </View>
          }

          {/* DATE SELECTION */}
          {currScreen == 1 &&
            <View style={{ width: '100%', height: '100%', }}>
              <View style={{ width: "100%", height: '30px', marginBottom: 30, marginTop: 60, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 2 }}>
                <Text style={{ marginBottom: 20, fontSize: 24, fontWeight: 500, position: 'relative' }}>Select the dates of your booking</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
                <View style={{ width: '100%', }}>
                  <View style={{ alignItems: 'center', marginTop: 5, paddingBottom: 30, overflow: 'auto', marginLeft: 'auto', marginRight: 'auto' }}>
                    {selectedItem && <Calendar width={300} height={325} onDateChange={(valid, selectedDateRange, dateRangeList) => onDateChange(valid, selectedDateRange, dateRangeList)} selectedDateRangeFinal={selectedDateRangeFinal} dateRangeList={dateRangeList} box_id={selectedItem.id} todaysInventory={todaysInventory} cartValues={cartItems} />}
                  </View>
                </View>
              </View>
              <TouchableOpacity style={{ position: 'absolute', bottom: 20, right: 20 }} onPress={() => { setFullScreen(prev => !prev) }}>
                <Feather name={fullScreen ? "minimize-2" : "maximize-2"} size={24} color="black" />
              </TouchableOpacity>
              <TouchableOpacity style={{ position: 'absolute', top: 20, right: 20 }} onPress={() => { setIsCartExpanded(prev => !prev) }}>
                <Feather name={isCartExpanded ? "chevron-right" : "chevron-left"} size={24} color="black" />
              </TouchableOpacity>
            </View>
          }

          {/* CARD OR CASH */}
          {currScreen == 2 &&
            <View style={{ marginTop: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', }}>
              <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Payments paymentType={"rental"} customerName={customerName} customerEmail={customerEmail} showBanner={showBanner} orderContents={cartItems} selectedDateRangeFinal={selectedDateRangeFinal} price={currPrice * (selectedDateRangeFinal.length)} dateRangeListFinal={dateRangeList} usingReportedValues={true} />
                <View style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: 40, marginBottom: 20, }}>
                  <Text style={{ fontSize: 28 }}>Testing Card Info:</Text>
                  <Text style={{ fontSize: 28 }}>* Use card number: 4242 4242 4242 4242</Text>
                  <Text style={{ fontSize: 28 }}>* Expiry: any valid date</Text>
                  <Text style={{ fontSize: 28 }}>* CVV: any 3 digits</Text>
                </View>
              </View>
            </View>
          }
        </View>

        <View style={{ backgroundColor: 'white', boxShadow: '-2px 5px 10px 1px #aaaaaa', flex: isCartExpanded ? '0.3' : '0', height: '100%', overflow: 'auto', borderLeftWidth: '1pt', borderLeftColor: 'lightgray' }} >

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', height: 60 }}>
            <View style={{ width: '80%', paddingTop: 20, borderBottomColor: 'lightgray', borderBottomWidth: '1px', alignItems: 'center', marginBottom: 10 }}>
              <Text style={{ fontSize: 22, fontWeight: 500 }}>Order Details</Text>
            </View>
          </View>

          {cartItems.map((cartItem) => {

            return (
              <View key={cartItem.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>
                <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center' }}>
                  <View style={{ padding: 5, marginHorizontal: 20 }}>
                    <Image source={cartItem.image} style={styles.cartItemImage} />
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'center', flex: 1, marginRight: 20, marginVertical: 10 }}>
                    <Text style={{ fontSize: 19, fontWeight: 500 }}>{cartItem.name}</Text>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', marginBottom: 20 }}>
                      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <TouchableOpacity onPress={() => handleRemoveFromCart(cartItem)} >
                          <Feather name={'minus-circle'} size={18} color={'black'} style={{ marginRight: 5 }} />
                        </TouchableOpacity>
                        <Text style={{ fontSize: 16, fontWeight: 450, textAlign: 'center' }}>{cartItem.quantity}</Text>
                        <TouchableOpacity onPress={() => handleAddToCart(cartItem)} >
                          <Feather name={'plus-circle'} size={18} color={'black'} style={{ marginLeft: 5 }} />
                        </TouchableOpacity>
                      </View>
                      <Text style={{ fontSize: 16, fontWeight: 450 }}>${cartItem.price}/each</Text>
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
          {cartItems.length > 0 && (
            <View>
              <Text style={{ marginHorizontal: 10, }}>${currPrice}.00/day</Text>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Text style={{ margin: 10, float: 'right', }}>Total Price: ${currPrice * (selectedDateRangeFinal.length)}.00</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <TouchableOpacity onPress={() => {
                  if (currScreen === 3) { }
                  setCurrScreen(currScreen - 1);
                }} style={[styles.backButton, { opacity: currScreen < 1 ? '0.5' : '1' }]} disabled={currScreen < 1}>
                  <Text style={styles.backButtonLabel}>Back</Text>
                </TouchableOpacity>
                {currScreen < 2 && <TouchableOpacity onPress={() => { setCurrScreen(currScreen + 1) }} style={[styles.nextButton, { opacity: ((currScreen === 1 && !dateSelected) || (currScreen > 1)) ? '0.5' : '1' }]} disabled={((currScreen === 1 && !dateSelected) || (currScreen > 1))}>
                  <Text style={styles.nextButtonLabel}>Next</Text>
                </TouchableOpacity>}
              </View>
              {/* <TouchableOpacity onPress={handlePurchase} style={styles.purchaseButton}>
                <Text style={styles.purchaseButtonLabel}>Purchase</Text>
              </TouchableOpacity> */}
            </View>
          )}
          {currRole != 'Ice' && todaysInventory && rentedCombos != null && (chairReported || comboReported || umbrellaReported) &&
            <View style={{ textAlign: 'center', justifyContent: 'center', }}>
              <Text style={{ height: '20px', fontSize: 15, fontWeight: 'bold', marginLeft: '10px', marginTop: '10px', color: 'black', }}>Inventory:</Text>
              <Text style={{ height: '20px', fontSize: 15, marginLeft: '10px', marginTop: '10px', color: '#F37D2A', }}>Umbrellas: {todaysInventory.umbrella_total - (rentedUmbrellas)}/{todaysInventory.umbrella_total}</Text>
              <Text style={{ height: '20px', fontSize: 15, marginLeft: '10px', color: '#F37D2A', }}>Chairs: {todaysInventory.chair_total - (rentedChairs)}/{todaysInventory.chair_total}</Text>
              <Text style={{ height: '20px', fontSize: 15, marginLeft: '10px', color: '#F37D2A', }}>Combos: {todaysInventory.combo_total - (rentedCombos)}/{todaysInventory.combo_total}</Text>
            </View>
          }
          {currRole != 'Ice' && todaysInventory && rentedCombos != null && !(chairReported || comboReported || umbrellaReported) &&
            <View style={{ textAlign: 'center', justifyContent: 'center', }}>

              <Text style={{ height: '20px', fontSize: 25, fontWeight: 'bold', marginLeft: '10px', marginTop: '10px', color: 'black', }}>Inventory:</Text>

              {/* Umbrellas */}
              <View style={{ textAlign: 'left', marginBottom: '20px', marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Text style={{ fontSize: 22, color: 'black', textDecoration: 'underline', textAlign: 'left' }}>Umbrellas</Text>
                <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5.5px' }}>
                  <Text style={{ fontSize: 18, color: 'black' }}>* In Inventory: {(todaysInventory.umbrella_total - rentedUmbrellas) > 0 ? todaysInventory.umbrella_total - rentedUmbrellas : "SOLD OUT"}</Text>
                  <Text style={{ fontSize: 18, color: 'black' }}>* Rented: {rentedUmbrellas}</Text>
                  {pendingUmbrellas && <Text style={{ fontSize: 18, color: 'black' }}>* Extra: {((todaysInventory.umbrella_total - rentedUmbrellas) > 0) ? (pendingUmbrellas - todaysInventory.umbrella_total) : pendingUmbrellas - rentedUmbrellas}</Text>}
                </View>
              </View>

              {/* Chairs */}
              <View style={{ textAlign: 'left', marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Text style={{ fontSize: 22, color: 'black', textDecoration: 'underline', textAlign: 'left' }}>Chairs</Text>
                <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5.5px' }}>
                  <Text style={{ fontSize: 18, color: 'black' }}>* In Inventory: {(todaysInventory.chair_total - rentedChairs) > 0 ? todaysInventory.chair_total - rentedChairs : "SOLD OUT"}</Text>
                  <Text style={{ fontSize: 18, color: 'black' }}>* Rented: {rentedChairs}</Text>
                  {pendingChairs && <Text style={{ fontSize: 18, color: 'black' }}>* Extra: {((todaysInventory.chair_total - rentedChairs) > 0) ? (pendingChairs - todaysInventory.chair_total) : pendingChairs - rentedChairs}</Text>}
                </View>
              </View>

              {/* Combos */}
              <View style={{ textAlign: 'left', marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Text style={{ fontSize: 22, color: 'black', textDecoration: 'underline', textAlign: 'left' }}>Combos</Text>
                <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5.5px' }}>
                  <Text style={{ fontSize: 18, color: 'black' }}>* In Inventory: {(todaysInventory.combo_total - rentedCombos) > 0 ? todaysInventory.combo_total - rentedCombos : "SOLD OUT"}</Text>
                  <Text style={{ fontSize: 18, color: 'black' }}>* Rented: {rentedCombos}</Text>
                  {(pendingChairs && pendingUmbrellas) && <Text style={{ fontSize: 18, color: 'black' }}>* Extra: {((todaysInventory.combo_total - rentedCombos) > 0) ? (pendingCombos - todaysInventory.combo_total) : pendingCombos - rentedCombos}</Text>}
                </View>
              </View>
            </View>
          }
        </View>


      </View>
    </View >
  );
};




const styles = StyleSheet.create({
  itemsContainer: {
    padding: 10,
    borderRadius: 20,
    margin: 30,
    height: '210px',
    width: '210px',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: 'white',
    shadowColor: 'black',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 15,
  },
  cartItemImage: {
    width: '100px',
    height: '100px',
    borderRadius: 10,
    objectFit: 'cover',
  },
  itemImage: {
    width: '100%',
    height: '75%',
    borderRadius: 10,
  },
  itemName: {
    marginTop: 10,
    fontWeight: 'bold',
    fontSize: 16,
  },
  itemPrice: {
    marginTop: 5,
    color: '#888',
  },
  back: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FAFAFC',
  },
  heading: {
    height: '45px',
    fontSize: 40,
    fontWeight: 'bold',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center',
    color: 'black',
  },
  cartItemButton: {
    backgroundColor: '#eee',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginRight: 10,
  },
  cartItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  cartButton: {
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    padding: 5,
    marginHorizontal: 10,
  },
  cartButtonLabel: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  cartItemName: {
    flex: 1,
    marginLeft: 10,
  },
  cartItemPrice: {
    marginRight: 10,
  },
  cartItemQuantity: {
    paddingHorizontal: 10,
  },
  purchaseButton: {
    backgroundColor: 'green',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignSelf: 'center',
    marginVertical: 20,
    marginLeft: '15px',
  },
  purchaseButtonLabel: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
  backButton: {
    backgroundColor: '#DA012D',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignSelf: 'center',
    marginVertical: 20,
    marginRight: '15px',
  },
  backButtonLabel: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
  nextButton: {
    backgroundColor: '#FDD128',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginVertical: 20,
    alignSelf: 'center',
    marginLeft: '15px',
  },
  nextButtonLabel: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },

});

export default PurchaseScreen;