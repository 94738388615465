// THIS FILE CONTAINS REUSED FUNCTIONS TO IMPORT WHERE NEEDED

import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import { API_URL } from '../components/constants';



export async function getData(key, defaultValue = null) {
    try {
      const value = await AsyncStorage.getItem(key);

      if (value !== null) {
        return value;
      } else {
        return defaultValue;
      }
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  }


export const checkIfOverbooked = async (inventory, orderContents, selectedDateRange, availableInventory, modalSuccess, modalData) => {

  console.log(inventory);
  console.log(orderContents);
  console.log(selectedDateRange);
  console.log(availableInventory)

  // Check if any object in the array has status === 'pending'
  const hasPending = availableInventory.inventory.some(item => item.status === 'pending');

  // Create a mapping to store the latest entry for each productId
  const productMap = {};

  // Iterate through the availableInventory
  availableInventory.inventory.forEach(item => {
    // Update the status based on the condition
    const updatedItem = hasPending
      ? { ...item, status: item.status === 'pending' ? 'pending' : 'active' }
      : { ...item, status: 'active' };

    // Store the latest entry for each productId in the productMap
    productMap[item.product_id] = updatedItem;
  });

  // Convert the values of the productMap into an array (updatedInventory)
  const updatedInventory = Object.values(productMap);

  console.log(updatedInventory)

  const umbrellaInventoryList = updatedInventory.filter(item => item.product_id === 1);
  const chairInventoryList = updatedInventory.filter(item => item.product_id === 2);

  let umbrellaInventory = null
  let chairInventory = null


  umbrellaInventory = umbrellaInventoryList[0]
  chairInventory = chairInventoryList[0]


  if (modalData?.newUmbrellas != null && modalData?.newChairs != null && modalSuccess) {
    umbrellaInventory.quantity = modalData.newUmbrellas
    chairInventory.quantity = modalData.newChairs
  }

  var umbrella_rented = 0;
  var chair_rented = 0;
  var combo_rented = 0;

  // Iterate through orderContents and accumulate quantities
  orderContents.forEach((item) => {
    if (item.name === "Umbrella") {
      umbrella_rented += item.quantity;
    } else if (item.name === "Chair") {
      chair_rented += item.quantity;
    } else if (item.name === "Combo") {
      combo_rented += item.quantity;
    }
  });


  if (inventory == null) {
    for (const date of selectedDateRange) {
      console.log("Test")
      if (chair_rented > chairInventory.quantity) {
        console.log(chair_rented - chairInventory.quantity)
        return [true, chair_rented, umbrella_rented, chairInventory.quantity, umbrellaInventory.quantity];
      }
      if (umbrella_rented > umbrellaInventory.quantity) {
        console.log(umbrella_rented - umbrellaInventory.quantity)
        return [true, chair_rented, umbrella_rented, chairInventory.quantity, umbrellaInventory.quantity];
      }
      // if (combo_rented > availableInventory.location.combo_total) {
      //   console.log(combo_rented - availableInventory.location.combo_total)
      //   return [true, chair_rented - chairInventory.quantity, umbrella_rented - umbrellaInventory.quantity];
      // }
    }
    return [false, 0, 0, 0, 0];
  }
  // Create an object to store the totals for each date
  const totalsByDate = {};

  // Iterate through the query results
  for (const result of inventory) {
    const date = result.date;
    const chairs = parseInt(result.total_chairs);
    const umbrellas = parseInt(result.total_umbrellas);
    const combos = parseInt(result.total_combos);

    // If the date doesn't exist in the totalsByDate object, initialize it
    if (!totalsByDate[date]) {
      totalsByDate[date] = {
        total_chairs: 0,
        total_umbrellas: 0,
        total_combos: 0,
      };
    }

    // Add the chairs, umbrellas, and combos to the totals for that date
    totalsByDate[date].total_chairs += chairs;
    totalsByDate[date].total_umbrellas += umbrellas;
    totalsByDate[date].total_combos += combos;
  }

  console.log(totalsByDate)

  for (const date of selectedDateRange) {

    if (totalsByDate[date]) {
      if ((totalsByDate[date].total_chairs + chair_rented) > chairInventory.quantity) {
        return [true, totalsByDate[date].total_chairs + chair_rented, totalsByDate[date].total_umbrellas + umbrella_rented, chairInventory.quantity, umbrellaInventory.quantity];
      }
      if ((totalsByDate[date].total_umbrellas + umbrella_rented) > umbrellaInventory.quantity) {

        return [true, totalsByDate[date].total_chairs + chair_rented, totalsByDate[date].total_umbrellas + umbrella_rented, chairInventory.quantity, umbrellaInventory.quantity];
      }
      // if ((parseInt(totalsByDate[date].total_combos) + combo_rented) > availableInventory.location.combo_total) {
      //   console.log("3");
      //   return [true, totalsByDate[date].total_chairs + chair_rented - availableInventory.location.chair_total, totalsByDate[date].total_umbrellas + umbrella_rented - availableInventory.location.umbrella_total];
      // }
    }
  }
  console.log("Hey we made it through... looks god.")

  return [false, 0, 0, 0, 0]; // Not overbooked for any day in any booking
};


export const processReservation = async (selectedDateRangeFinal, repeat_id, rangeList, e, overbooked_flag, orderContents, modalSuccess, modalData) => {
  console.log("Processing: " + selectedDateRangeFinal);
  try {
    if (await getData('boxName') !== null) {
      const jsonString = await getData('boxName');
      const currBox = JSON.parse(jsonString);
      if (currBox.name) {
        const response1 = await fetch(`${API_URL}/inventory/today/` + currBox.id, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Origin': 'http://localhost:19006',
            'Access-Control-Request-Method': 'GET',
            'Access-Control-Request-Headers': 'Content-Type, Authorization',
          },
        });

        if (response1.status !== 200) {
          return { success: false, modalDataRes: null, sale_id: 0 }; // Handle the error condition
        }

        const boxInventory = await response1.json();

        const response2 = await fetch(`${API_URL}/bookings/` + currBox.id + '?start=' + selectedDateRangeFinal[0] + '&end=' + selectedDateRangeFinal[selectedDateRangeFinal.length - 1], {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Origin': 'http://localhost:19006',
            'Access-Control-Request-Method': 'GET',
            'Access-Control-Request-Headers': 'Content-Type, Authorization',
          },
        });

        const jsonRes = await response2.json();

        if (jsonRes.results) {
          const [overbooked, new_chairs, new_umbrellas, old_chairs, old_umbrellas] = await checkIfOverbooked(jsonRes.results, orderContents, selectedDateRangeFinal, boxInventory, modalSuccess, modalData);

          if (overbooked) {
            const modalData = {
              sale_id: repeat_id,
              dateRange: selectedDateRangeFinal,
              rangeList: rangeList,
              newChairs: new_chairs,
              newUmbrellas: new_umbrellas,
              oldChairs: old_chairs,
              oldUmbrellas: old_umbrellas,
              boxName: currBox.name,
              box_id: currBox.id,
              payment_element: e,
              inventory: boxInventory,
            };

            return { success: false, modalDataRes: modalData, sale_id: 0 };
          } else {
            const sale_id = await handleReservation(selectedDateRangeFinal, repeat_id, overbooked_flag ? 1 : 0, orderContents);
            return { success: true, modalDataRes: null, sale_id: sale_id };
          }
        } else {
          const [overbooked, new_chairs, new_umbrellas, old_chairs, old_umbrellas] = await checkIfOverbooked(jsonRes.results, orderContents, selectedDateRangeFinal, boxInventory, modalSuccess, modalData);

          if (overbooked) {
            const modalData = {
              sale_id: repeat_id,
              dateRange: selectedDateRangeFinal,
              rangeList: rangeList,
              newChairs: new_chairs,
              newUmbrellas: new_umbrellas,
              oldChairs: old_chairs,
              oldUmbrellas: old_umbrellas,
              boxName: currBox.name,
              box_id: currBox.id,
              payment_element: e,
              inventory: boxInventory,
            };

            return { success: false, modalDataRes: modalData, sale_id: 0 };
          } else {
            const sale_id = await handleReservation(selectedDateRangeFinal, repeat_id, overbooked_flag ? 1 : 0, orderContents);
            return { success: true, modalDataRes: null, sale_id: sale_id };
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
    return { success: false, modalDataRes: null, sale_id: 0 };
  }

  return { success: false, modalDataRes: null, sale_id: 0 };
};




export const handleReservation = async (selectedDateRangeFinal, repeat_id, overbooked_flag, orderContents) => {
    var notes = '';
    var employee_id = await getData('user_id');
    var assembled = false;
    var temp = true;
    var failed = false;
    var delivery_address = 'test';
    var payment_type = "card";
    var customer_id = '1';

    var jsonString = await getData('boxName');
    var currBox = JSON.parse(jsonString);
    var box_id = currBox.id;

    var start_date = moment(selectedDateRangeFinal[0]);
    start_date.set({ hour: 0, minute: 0, second: 0 });
    var end_date = moment(selectedDateRangeFinal[selectedDateRangeFinal.length - 1]);
    end_date.set({ hour: 23, minute: 59, second: 59 });

    var date_booked = moment()

    var repeat_booking_id = repeat_id === 0 ? null : repeat_id;

    var overbooked = overbooked_flag;

    const bookingItems = orderContents.map(item => ({ id: item.id, quantity: item.quantity }));

    console.log(bookingItems)

    const payload = {
      employee_id,
      box_id,
      customer_id,
      start_date,
      end_date,
      notes,
      payment_type,
      delivery_address,
      temp,
      failed,
      assembled,
      overbooked,
      bookingItems,
      repeat_booking_id
    };

    try {
      const res = await fetch(`${API_URL}/bookings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:19006',
          'Access-Control-Request-Method': 'POST',
          'Access-Control-Request-Headers': 'Content-Type, Authorization',
        },
        body: JSON.stringify(payload),
      });

      if (res.status === 201) {
        const jsonRes = await res.json();
        console.log(jsonRes.data);

        return jsonRes.data.booking_id;
      } else {
        // Handle the case where the API call was not successful
        return false;
      }
    } catch (err) {
      // Handle any errors that occur during the API call
      console.log(err);
      return false;
    }
  };


  export const handleSuccess = async (sale_id, name, email) => {
    console.log("saleID: " + sale_id)
    //remove the temporary tag of the booking we just created
    fetch(`${API_URL}/setValid/` + sale_id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Origin': 'http://localhost:19006',
        'Access-Control-Request-Method': 'PUT',
        'Access-Control-Request-Headers': 'Content-Type, Authorization',
      },
      // body: JSON.stringify({ sale_id: sale_id }),
    })
      .then(async res => {
        try {
          const jsonRes = await res.json();
          if (res.status !== 200) {
          } else {

            //post customer to db
            fetch(`${API_URL}/customer`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'POST',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
              },
              body: JSON.stringify({
                name: name,
                email: email === '' ? null : email
              }),
            })
              .then(async res => {
                try {
                  const jsonRes = await res.json();
                  if (res.status !== 201) {
                  } else {
                    console.log("success")
                  }
                } catch (err) {
                  //setMessage(err);
                  console.log(err);
                };
              })
              .catch(err => {
                console.log(err);
              })

            console.log(jsonRes);
          }
        } catch (err) {
          //setMessage(err);
          console.log(err);
        };
      })
      .catch(err => {
        console.log(err);
      })


  };


  export const handleError = async (sale_id) => {

    //delete the booking we just created

    console.log("saleID: " + sale_id)

    fetch(`${API_URL}/setFailed/` + sale_id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Origin': 'http://localhost:19006',
        'Access-Control-Request-Method': 'PUT',
        'Access-Control-Request-Headers': 'Content-Type, Authorization',
      },
      // body: JSON.stringify({ sale_id: sale_id }),
    })
      .then(async res => {
        try {
          const jsonRes = await res.json();
          if (res.status !== 200) {
          } else {
            console.log(jsonRes);
          }
        } catch (err) {
          //setMessage(err);
          console.log(err);
        };
      })
      .catch(err => {
        console.log(err);
      })


  };