import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useState } from 'react';
import { View, StyleSheet, TextInput, Text, Platform, Button, TouchableOpacity, Image, Dimensions, Animated, Touchable } from 'react-native';
import Header from '../../components/Header';
import WeatherWidget from '../../components/Home/WeatherWidget';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Pelican from '../../components/Home/Pelican';
import { Feather } from '@expo/vector-icons';
import PieChart from '../../components/Home/PieChart';
import { API_URL } from '../../components/constants';
import Chart from '../../components/Home/TideChart';
import CreateNotice from '../../components/Home/Notices';
import Ionicons from 'react-native-vector-icons/Ionicons';
import moment from 'moment';
import setupFetchInterceptors from '../../hooks/setupFetchInterceptors';



const WEATHER_API_KEY = "4942f427d65e66de3fa9631287689b6a";

// const API_URL = Platform.OS !== 'ios' ? 'http://posserver-env.eba-n2b3jkg2.us-east-1.elasticbeanstalk.com:80' : 'http://posserver-env.eba-n2b3jkg2.us-east-1.elasticbeanstalk.com:80';

const { width } = Dimensions.get('window');

const HomeScreen = ({ showBanner }) => {

    const [searchParams] = useSearchParams();
    const [selectedItem, setSelectedItem] = useState(null)
    const [currRole, setCurrRole] = useState("")
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [currName, setCurrName] = useState("")
    const [bookings, updateBookings] = useState([])
    const [isScreenBelow1200px, setIsScreenBelow1200px] = useState(false);
    const [weatherAlerts, setWeatherAlerts] = useState([]);
    const [notices, setNotices] = useState(null)
    const [settingNotice, setSettingNotice] = useState(false);
    const [pelicans, setPelicans] = useState([]);
    const [salePieData, setSalePieData] = useState(null);
    const [otherPieData, setOtherPieData] = useState(null);
    const [currentNoticeIndex, setCurrentNoticeIndex] = useState(0);
    const [overbooked, setOverbooked] = useState(false);

    const [todaysInventory, setTodaysInventory] = useState(null);

    const [rentedChairs, setRentedChairs] = useState(null);
    const [rentedUmbrellas, setRentedUmbrellas] = useState(null);
    const [rentedCombos, setRentedCombos] = useState(null);

    const [averagesFilter, setAveragesFilter] = useState("day")

    const [pendingUmbrellas, setPendingUmbrellas] = useState(null);
    const [pendingChairs, setPendingChairs] = useState(null);
    const [pendingCombos, setPendingCombos] = useState(null);


    const [averages, setAverages] = useState(null)


    const [userId, setUserId] = useState(null);

    const navigate = useNavigate();

    setupFetchInterceptors()

    const calculateAverages = (startDate, data) => {
        let start_date = startDate;

        // Initialize counters for umbrellas, chairs, and combos
        let umbrellaCount = 0;
        let chairCount = 0;
        let comboCount = 0;

        let profit = 0;

        console.log(data)

        // Loop through each booking
        data.forEach(booking => {
            const bookingStartDate = moment(booking.start);
            const bookingEndDate = moment(booking.end);

            const saleDate = moment(booking.sale_date);


            let days;

            if (startDate - bookingStartDate >= 0) {
                //count every day, since the booking starts AFTER the date we are looking
                days = bookingEndDate.diff(startDate, 'days') + 1

            } else {
                days = bookingEndDate.diff(bookingStartDate, 'days') + 1
            }

            // Increment counters based on the products in the booking
            booking.bookingItems.forEach(item => {
                if (saleDate - startDate >= 0) {
                    profit += item.quantity * item.product.price * days;
                }
                switch (item.product.name) {
                    case 'umbrella':
                        umbrellaCount += item.quantity * days;
                        break;
                    case 'chair':
                        chairCount += item.quantity * days;
                        break;
                    case 'combo':
                        comboCount += item.quantity * days;
                        break;
                }
            });

        });

        // Calculate the average by dividing the total count by the number of days
        const totalDays = moment().diff(start_date, 'days') + 1;
        const avgUmbrellas = umbrellaCount / totalDays;
        const avgChairs = chairCount / totalDays;
        const avgCombos = comboCount / totalDays;
        const avgProfit = profit / totalDays;
        const revenue = profit;


        let avgItems = {
            avgUmbrellas, avgChairs, avgCombos, avgProfit, revenue
        }

        console.log(avgItems)


        return avgItems;
    }

    const calculateCombos = (activeInventory, pendingInventory) => {
        const activeUmbrellas = activeInventory.find(item => item.productName === 'umbrella')?.quantity || 0;
        const activeChairs = activeInventory.find(item => item.productName === 'chair')?.quantity || 0;

        const pendingUmbrellas = pendingInventory.find(item => item.productName === 'umbrella')?.quantity || 0;
        const pendingChairs = pendingInventory.find(item => item.productName === 'chair')?.quantity || 0;

        const activeCombos = Math.min(Math.floor(activeUmbrellas / 1), Math.floor(activeChairs / 2));
        const pendingCombos = Math.min(Math.floor(pendingUmbrellas / 1), Math.floor(pendingChairs / 2));

        return { activeCombos, pendingCombos };
    };

    const spawnPelican = () => {
        setPelicans([...pelicans, <Pelican key={pelicans.length} onFinish={() => removePelican(pelicans.length)} />]);
    };

    const removePelican = (index) => {
        const updatedPelicans = pelicans.filter((_, i) => i !== index);
        setPelicans(updatedPelicans);
    };


    // set data to storage
    async function setData(key, value) {
        try {
            await AsyncStorage.setItem(key, value);
        } catch (error) {
            console.log(error);
        }
    }

    // get data from storage
    async function getData(key, defaultValue = null) {
        try {
            const value = await AsyncStorage.getItem(key);

            if (value !== null) {
                return value;
            } else {
                return defaultValue;
            }
        } catch (error) {
            console.log(error);
            return defaultValue;
        }
    }

    const parseSaleData = (filter, data) => {
        // Set the start date based on the filter
        const currentDate = new Date();
        let startDate;
        if (filter === 'today') {
            startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        } else if (filter === 'week') {
            startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
        } else if (filter === 'month') {
            startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        } else if (filter === 'year') {
            startDate = new Date(currentDate.getFullYear(), 0, 1);
        } else {
            // Invalid filter
            return 0;
        }

        const filterDataDate = (startDate, data) => {


            // Calculate the total sales amount
            let totalSales = 0;
            data.bookings.forEach(booking => {
                const saleDate = new Date(booking.sale_date);
                if (saleDate >= startDate && saleDate <= currentDate) {
                    const bookingStartDate = new Date(booking.start_date);
                    const bookingEndDate = new Date(booking.end_date);
                    const bookingDays = Math.ceil((bookingEndDate - bookingStartDate) / (1000 * 60 * 60 * 24)); // Calculate the duration of the booking in days
                    const bookingTotalSales = booking.booking_items.reduce((acc, item) => {
                        // Assuming each item has a "quantity" and a "product_id", and there's a global product price lookup
                        const productPrice = (item.product.price);
                        return acc + productPrice * item.quantity;
                    }, 0);
                    totalSales += bookingTotalSales * bookingDays;
                }
            });
            return totalSales;
        };

        const filterPieData = (startDate, data) => {

            // Count the occurrences of each payment type within the specified date range
            const paymentCounts = data.bookings.reduce((counts, booking) => {
                const saleDate = new Date(booking.sale_date);
                if (saleDate >= startDate && saleDate <= currentDate) {
                    const paymentType = booking.payment_type;
                    counts[paymentType] = (counts[paymentType] || 0) + 1;
                }
                return counts;
            }, {});

            // Calculate the total number of bookings within the specified date range
            const totalBookings = Object.values(paymentCounts).reduce((total, count) => total + count, 0);


            // Calculate the percentage of each payment type
            const paymentPercentages = {
                card: (paymentCounts.card || 0) / totalBookings * 100,
                cash: (paymentCounts.cash || 0) / totalBookings * 100,
                website: (paymentCounts.website || 0) / totalBookings * 100,
            };

            // console.log(paymentPercentages);
            let pie_data = [
                { value: paymentPercentages.website || 0, color: 'blue' },
                { value: paymentPercentages.card || 0, color: 'green' },
                { value: paymentPercentages.cash || 0, color: 'orange' },
            ]
            let flag = 0
            pie_data.forEach((d) => {
                if (d.value === 100) {
                    flag = 1
                }
            })
            if (flag === 1) {
                pie_data.forEach((d) => {
                    if (d.value === 100) {
                        d.value = 99.999
                    }
                    else {
                        d.value = 0.0005
                    }
                })
            }
            return pie_data
        }


        setSalePieData(filterPieData(startDate, data))
        setOtherPieData(filterDataDate(startDate, data))

    };

    const parseAvgData = (filter, data) => {

    }

    const getBookings = (b) => {
        updateBookings(b)
    }

    const refreshDropDown = async () => {

        setCurrName(await getData("name"))

        if (await getData('boxName') !== null) {

            var jsonString = await getData('boxName')
            var currBox = JSON.parse(jsonString)
            if (currBox.name) {
                setSelectedItem(currBox)
            }
        }
    }

    const getAverages = async (filter) => {

        console.log("Here?")

        if (await getData('boxName') !== null) {

            var jsonString = await getData('boxName')
            var currBox = JSON.parse(jsonString)
            if (currBox.name) {
                // Get one month ago from the current time
                const startDate = moment().subtract(1, (filter));
                console.log(startDate)

                const response2 = await fetch(`${API_URL}/bookings/` + currBox.id + '?since=' + startDate.format('YYYY-MM-DD'), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': 'http://localhost:19006',
                        'Access-Control-Request-Method': 'GET',
                        'Access-Control-Request-Headers': 'Content-Type, Authorization',
                    },
                    // body: JSON.stringify({
                    //   box_id: currBox.id,
                    //   startDate: selectedDateRangeFinal[0],
                    //   endDate: selectedDateRangeFinal[selectedDateRangeFinal.length - 1],
                    // }),
                });

                // if (response2.status !== 200) {
                //   return false; // Handle the error condition
                // }
                if (response2.status === 200) {
                    const jsonRes = await response2.json();
                    console.log(jsonRes);
                    setAverages(calculateAverages(startDate, jsonRes.inventory))
                } else {
                    console.log(response2)
                    setAverages({avgUmbrellas: 0, avgChairs: 0, avgCombos: 0, avgProfit: 0, revenue: 0})
                }
            }
        } else {
            return;
        }



    };

    const getSales = async (filter) => {

        const getUserId = async () => {
            return await getData('user_id');
        };

        const user_id = await getUserId();
        setUserId(user_id)


        fetch(`${API_URL}/employee/` + user_id + '/sales', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'https://www.jacobawebb.com',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            // body: JSON.stringify({ box_id: item.id }),
        }).then(async res => {
            try {
                if (res.status !== 200) {
                    setSalePieData([])
                } else {
                    const jsonRes = await res.json();
                    console.log(jsonRes.data)
                    parseSaleData(filter, jsonRes.data)
                }
            } catch (err) {
                console.log(err);
            };
        })
            .catch(err => {
                console.log(err);
            });
    };

    const fetchNotices = async () => {
        fetch(`${API_URL}/notices/active?date=today`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'https://www.jacobawebb.com',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
        })
            .then(async res => {
                try {
                    if (res.status !== 200) {
                    } else {
                        const jsonRes = await res.json();
                        console.log(jsonRes.data)
                        setNotices(jsonRes.data)
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });
    }



    React.useEffect(() => {


        const getInventoryData = async () => {

        }

        getInventoryData();

        const spawnInterval = setInterval(spawnPelican, Math.random() * 5000 + 8000000); // Random interval between 3s and 8s

        const func = async () => {
            if (await getData('boxName') !== null) {
                //    refreshing the drop down value if possible
                var jsonString = await getData('boxName')
                var currBox = JSON.parse(jsonString)
                if (currBox.name) {
                    onSelect(currBox)
                }
            }
        };

        const getRole = async () => {
            setCurrRole(await getData('role'));
        };

        const handleResize = () => {
            setIsScreenBelow1200px(window.innerWidth < 1200);
        };

        // Initial check
        handleResize();

        // Event listener for window resize
        window.addEventListener('resize', handleResize);

        const fetchWeatherAlerts = async () => {
            try {
                const response = await fetch("https://api.weather.gov/alerts/active?zone=SCC051&limit=500");
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                // Extracting relevant information from the response and setting the state
                const alerts = data.features.map(feature => ({
                    id: feature.id,
                    areaDesc: feature.properties.areaDesc,
                    geocode: feature.properties.geocode,
                    affectedZones: feature.properties.affectedZones,
                    references: feature.properties.references,
                    sent: feature.properties.sent,
                    effective: feature.properties.effective,
                    expires: feature.properties.expires,
                    ends: feature.properties.ends,
                    status: feature.properties.status,
                    messageType: feature.properties.messageType,
                    category: feature.properties.category,
                    severity: feature.properties.severity,
                    certainty: feature.properties.certainty,
                    urgency: feature.properties.urgency,
                    event: feature.properties.event,
                    headline: feature.properties.headline,
                    description: feature.properties.description,
                    instruction: feature.properties.instruction,
                    response: feature.properties.response,
                }));
                console.log(alerts)
                setWeatherAlerts(alerts);
            } catch (error) {
                console.error('Error fetching weather alerts:', error);
            }
        };


        fetchWeatherAlerts();
        getRole();
        getAverages(averagesFilter)
        func();
        refreshDropDown();
        getSales('today');
        fetchNotices();

        return () => {
            clearInterval(spawnInterval);
            window.removeEventListener('resize', handleResize);
        };
    }, [pelicans]);

    const onSelect = async (item) => {
        await setData('boxName', JSON.stringify(item))
        setSelectedItem(item)
        getAverages(averagesFilter)
        updateBookings([])
        let bookings = []
        let d = new Date()
        d.setHours(0, 0, 0, 0)

        let pad = function (num) { return ('00' + num).slice(-2) };
        let testDate = d.getUTCFullYear() + '-' +
            pad(d.getUTCMonth() + 1) + '-' +
            pad(d.getUTCDate()) + ' ' +
            pad(d.getUTCHours()) + ':' +
            pad(d.getUTCMinutes()) + ':' +
            pad(d.getUTCSeconds());

        // /get_location_inventory
        // fetch(`${API_URL}/get_location_inventory`, {
        fetch(`${API_URL}/inventory/` + item.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            // body: JSON.stringify({ box_id: currBox.id }),
        })
            .then(async res => {
                try {
                    const jsonRes = await res.json();
                    console.log(jsonRes)
                    if (res.status !== 200) {
                    } else {

                        let activeUmbrella = 0;
                        let activeChair = 0;
                        let pendingUmbrella = 0;
                        let pendingChair = 0;

                        jsonRes.inventory.forEach(item => {
                            if (item.status === 'active') {
                                if (item.productName === 'umbrella') {
                                    activeUmbrella += item.quantity;
                                } else if (item.productName === 'chair') {
                                    activeChair += item.quantity;
                                }
                            } else if (item.status === 'pending') {
                                if (item.productName === 'umbrella') {
                                    pendingUmbrella = item.quantity;
                                } else if (item.productName === 'chair') {
                                    pendingChair = item.quantity;
                                }
                            }
                        });

                        const { activeCombos, pendingCombos } = calculateCombos([
                            { productName: 'umbrella', quantity: activeUmbrella },
                            { productName: 'chair', quantity: activeChair },
                        ], [
                            { productName: 'umbrella', quantity: pendingUmbrella },
                            { productName: 'chair', quantity: pendingChair },
                        ]);

                        const list = {
                            umbrella_total: activeUmbrella,
                            chair_total: activeChair,
                            combo_total: activeCombos,
                        };


                        // FIX PENDING VALUES IN HOME PAGE

                        if (pendingUmbrella > 0) {
                            setPendingUmbrellas(pendingCombos)
                        }
                        if (pendingChair > 0) {
                            setPendingChairs(pendingChair)
                        }

                        console.log(list)

                        setTodaysInventory(list);
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });



        // fetch(`${API_URL}/get_todays_inventory`, {
        fetch(`${API_URL}/bookings/` + item.id + '?today=true', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            // body: JSON.stringify({ box_id: currBox.id }),
        })
            .then(async res => {
                try {

                    if (res.status !== 200) {
                        console.log("here 1")
                        setRentedChairs(0);
                        setRentedUmbrellas(0);
                        setRentedCombos(0);
                    } else if (res.status === 200) {

                        const jsonRes = await res.json();
                        console.log(jsonRes)

                        if (jsonRes.inventory) {
                            // Initialize variables to store totals
                            let totalChairs = 0;
                            let totalUmbrellas = 0;
                            let totalCombos = 0;

                            // Loop through the inventory and calculate totals
                            // jsonRes.inventory.forEach(item => {
                            //   totalChairs += parseInt(item.total_chairs, 10);
                            //   totalUmbrellas += parseInt(item.total_umbrellas, 10);;
                            //   totalCombos += parseInt(item.total_combos, 10);
                            // });

                            // Loop through bookings
                            jsonRes.inventory.forEach(booking => {
                                console.log(booking)

                                // Loop through booking items
                                booking.bookingItems.forEach(bookingItem => {
                                    console.log(bookingItem)
                                    // Assuming product_id 1 is for umbrellas and product_id 2 is for chairs
                                    if (bookingItem.product_id === 1) {
                                        totalUmbrellas += bookingItem.quantity;
                                    } else if (bookingItem.product_id === 2) {
                                        totalChairs += bookingItem.quantity;
                                    } else if (bookingItem.product_id === 3) {
                                        totalCombos += bookingItem.quantity;
                                    }
                                });
                            });


                            setRentedChairs(totalChairs);
                            setRentedUmbrellas(totalUmbrellas);
                            setRentedCombos(totalCombos);
                        } else {
                            setRentedChairs(0);
                            setRentedUmbrellas(0);
                            setRentedCombos(0);
                        }
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleDrawerStateChange = (newState) => {
        setIsDrawerOpen(newState);
    };


    const createNotice = (title, body, start_date, end_date, urgent) => {

        var employee_id = userId;

        console.log(start_date)
        console.log(end_date)

        const payload = {
            title,
            body,
            start_date,
            end_date,
            urgent,
            employee_id
        }
        fetch(`${API_URL}/notices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'https://www.jacobawebb.com',
                'Access-Control-Request-Method': 'POST',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            body: JSON.stringify(payload),
        }).then(async res => {
            try {
                if (res.status !== 201) {
                    //problemo
                    console.log(res)
                } else {
                    fetchNotices();
                    showBanner('success', 'Successfully created a notice!');
                    console.log("Successfully created notice!")
                }
            } catch (err) {
                console.log(err);
            };
        })
            .catch(err => {
                console.log(err);
            });

        setSettingNotice(false);
    }

    return (
        <View style={styles.back}>
            <CreateNotice
                onCreate={(title, body, startDate, endDate, urgent) => { console.log("TEST YOO"); createNotice(title, body, startDate, endDate, urgent) }}
                isVisible={settingNotice}
                onCancel={() => { setSettingNotice(false) }}
            />
            <Header
                value={selectedItem}
                onSelect={onSelect}
                onDrawerStateChange={handleDrawerStateChange}
                role={currRole}
                currentPage={'Home'}
            />
            {/* {pelicans} */}

            <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', position: 'relative', }}>



                {/* Home Page Content */}
                <View style={{ boxSizing: 'border-box', minWidth: '0px', width: isDrawerOpen ? 'calc(100vw - 250px)' : 'calc(100vw - 60px)', marginLeft: isDrawerOpen ? '250px' : '60px', height: 'calc(100vh - 65px)', paddingBottom: '75px', overflow: 'auto' }}>
                    <View style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '0px', marginTop: '0px', boxSizing: 'boder-box', minWidth: '0px', height: '100%', padding: '40px 20px', flex: '1 1 0%', width: '100%', }}>

                        {weatherAlerts.length > 0 &&
                            weatherAlerts.map(alert => (
                                <View style={{ width: '100%', alignItems: 'center' }}>
                                    <TouchableOpacity style={{ width: '85%', borderWidth: '0px', borderRadius: '0.25rem', marginLeft: 'auto', marginRight: 'auto', marginTop: '40px', marginBottom: '10px', backgroundColor: '#FFE0A5', opacity: '0.9', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', overflow: 'auto' }}>
                                        <Feather name={'alert-triangle'} size={24} color={'black'} style={{ marginLeft: 20 }} />
                                        <View style={{ flex: 1, margin: 15 }}>
                                            {/* <View style={{ margin: '15px',  */}

                                            <Text style={{ marginBottom: 5, fontSize: 16, fontWeight: 'bold', flexWrap: 'wrap' }}>
                                                {alert.headline}
                                            </Text>
                                            <Text style={{ fontSize: 15, flexWrap: 'wrap' }}>
                                                {alert.areaDesc}
                                                {/* {alert.description.replace(/\n/g, ' ')} */}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            ))}


                        <View style={{ width: '100%', boxSizing: 'border-box', marginBottom: '0px', marginTop: '0px', minWidth: '0px', display: 'flex', flexFlow: 'wrap', justifyContent: 'space-evenly', paddingTop: '30px', paddingBottom: '30px', paddingLeft: '15px', paddingRight: '15px' }}>

                            <View style={{ width: isScreenBelow1200px ? '100%' : 'min(49%, 730px)', paddingRight: isScreenBelow1200px ? '' : '10px', marginBottom: isScreenBelow1200px ? '40px' : '20px', boxSizing: 'border-box', minWidth: '0px', marginLeft: 'auto', marginRight: '', paddingBottom: '20px', }}>

                                <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingBottom: '10px', height: '50px', alignItems: 'center', flexDirection: 'row', paddingHorizontal: '2px' }}>
                                    <Text style={{ fontSize: 22, fontWeight: 450 }}>Notices</Text>
                                    <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex' }}>
                                        <TouchableOpacity style={{ background: 'linear-gradient(to top, #162e56 0%, #162e56 1%, #233f7e 100%)', margin: '0px', appearance: 'none', borderImage: 'initial', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', cursor: 'pointer', textTransform: 'capitalize', fontSize: '0.775rem', boxSizing: 'border-box', padding: '6px', borderWidth: '', borderStyle: 'solid', borderColor: 'black', borderRadius: '0.2rem', lineHeight: '1.25', verticalAlign: 'middle', textAlign: 'center', textDecoration: 'none', minWidth: '40px', }}
                                            onPress={() => { setSettingNotice(true) }}
                                        >

                                            <Text style={{ fontWeight: 475, color: 'white' }}>Add Notice +</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>

                                {!notices &&
                                    <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', borderRadius: '0.2rem', padding: '1rem', borderWidth: '1px', borderImage: 'initial', borderStyle: 'solid', borderColor: 'lightgray', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                        {/* <View style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', overflow: 'hidden', position: 'relative', display: 'block', outline: '0px !important' }}> */}
                                        <View style={{ width: '100%', boxSizing: 'border-box', margin: '0px', minWidth: '0px', minHeight: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                                            <Ionicons name="pin" size={35} color="black" />
                                            <Text style={{ fontSize: 24, fontWeight: 470, marginBottom: '5px', marginTop: '5px' }}>There are no active notices</Text>
                                            <Text style={{ fontSize: 14, marginBottom: '20px' }}>Create a notice by pressing the button below</Text>
                                            <TouchableOpacity style={{ backgroundColor: 'navy', margin: '0px', appearance: 'none', borderImage: 'initial', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', cursor: 'pointer', textTransform: 'capitalize', fontSize: '0.775rem', boxSizing: 'border-box', padding: '6px', borderWidth: '1px', borderStyle: 'solid', borderColor: 'black', borderRadius: '0.2rem', lineHeight: '1.25', verticalAlign: 'middle', textAlign: 'center', textDecoration: 'none', minWidth: '40px', }}
                                                onPress={() => { setSettingNotice(true) }}
                                            >
                                                <Text style={{ fontWeight: 475, color: 'white' }}>Create Notice</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={{ marginTop: '10px', fontSize: '0.775rem', boxSizing: 'border-box', lineHeight: '1.25', verticalAlign: 'middle', textAlign: 'center', textDecoration: 'none', minWidth: '40px', }}
                                                onPress={() => { navigate('/notices') }}
                                            >
                                                <Text style={{ color: 'lightblue', textDecoration: 'underline' }}>View Past Notices</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>}
                                {notices &&
                                    <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', borderRadius: '0.2rem', padding: '1rem', borderWidth: '1px', borderImage: 'initial', borderStyle: 'solid', borderColor: 'lightgray', backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                        <View style={{ outline: 'none', display: 'block', float: 'left', height: '100%', minHeight: '1px' }}>
                                            <View style={{ width: '100%', display: 'inline-block', boxSizing: 'border-box', paddingTop: '20px', paddingBottom: '20px', minWidth: '0px' }}>
                                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center' }}>
                                                    <TouchableOpacity onPress={() => { setCurrentNoticeIndex((prevIndex) => (prevIndex === 0 ? notices.length - 1 : prevIndex - 1)) }} style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '5px' }}>
                                                        <Feather name="arrow-left-circle" size={24} color="black" />
                                                    </TouchableOpacity>
                                                    <View style={{ width: '95%', boxSizing: 'border-box', minHeight: '375px', padding: '10px' }}>

                                                        <View style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignContent: 'flex-start' }}>
                                                            <Text style={{ fontSize: 26, fontWeight: 600 }}>{notices[currentNoticeIndex].title}</Text>
                                                            <Text style={{ fontSize: 17, fontWeight: 420, marginTop: 20 }}>{notices[currentNoticeIndex].body}</Text>
                                                            <Text>{notices[currentNoticeIndex].urgent}</Text>
                                                        </View>


                                                    </View>
                                                    <TouchableOpacity onPress={() => { setCurrentNoticeIndex((prevIndex) => (prevIndex === notices.length - 1 ? 0 : prevIndex + 1)) }} style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '5px' }}>
                                                        <Feather name="arrow-right-circle" size={24} color="black" />
                                                    </TouchableOpacity>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                }


                                <View style={{ boxSizing: 'border-box', margin: '0px', marginTop: '50px', minWidth: '0px', display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingBottom: '10px', height: '50px', alignItems: 'center', flexDirection: 'row', paddingHorizontal: '2px' }}>
                                    <Text>Statistics</Text>
                                </View>
                                <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', borderRadius: '0.2rem', padding: '1rem', borderWidth: '1px', borderImage: 'initial', borderStyle: 'solid', borderColor: 'lightgray', backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <View style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', overflow: 'auto', position: 'relative', display: 'block', outline: '0px !important' }}>
                                        <View style={{ width: '100%', outline: 'none', display: 'block', float: 'left', height: '100%', minHeight: '1px' }}>
                                            <View style={{ width: '100%', display: 'inline-block', boxSizing: 'border-box', minWidth: '0px' }}>
                                                <View style={{ width: '100%', minHeight: '175px', width: '100%', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', }}>

                                                    <View style={{ minHeight: '100px', padding: '0.5rem', }}>
                                                        <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                            <Text style={{ flex: 1, textAlign: 'center' }}>Your Sales</Text>

                                                            <select onChange={(e) => {
                                                                console.log(e.target.value)
                                                                getSales(e.target.value);
                                                            }}>
                                                                <option value="today">Today</option>
                                                                <option value="week">This Week</option>
                                                                <option value="month">This Month</option>
                                                                <option value="year">This Year</option>
                                                            </select>
                                                        </View>
                                                        <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt' }} />

                                                        <View style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', }}>
                                                            <View style={{ width: '65%', padding: '0.1rem', }}>

                                                                {salePieData && <PieChart data={salePieData} sales={otherPieData} />}
                                                            </View>
                                                            <View style={{ width: '35%', padding: '0.1rem', }}>
                                                                <View style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>

                                                                    <View style={{ height: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
                                                                        <View style={{ height: '15px', width: '15px', backgroundColor: 'orange', borderRadius: '10px', margin: '10px' }} />
                                                                        <Text style={{ marginHorizontal: 'auto' }}>Cash</Text>
                                                                    </View>

                                                                    <View style={{ height: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
                                                                        <View style={{ height: '15px', width: '15px', backgroundColor: 'green', borderRadius: '10px', margin: '10px' }} />
                                                                        <Text style={{ marginHorizontal: 'auto' }}>Card</Text>
                                                                    </View>

                                                                    <View style={{ height: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
                                                                        <View style={{ height: '15px', width: '15px', backgroundColor: 'blue', borderRadius: '10px', margin: '10px' }} />
                                                                        <Text style={{ marginHorizontal: 'auto' }}>Website</Text>
                                                                    </View>

                                                                </View>
                                                            </View>
                                                        </View>


                                                    </View>

                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                            </View>


                            <View style={{ width: isScreenBelow1200px ? '100%' : 'min(49%, 730px)', paddingLeft: isScreenBelow1200px ? '' : '10px', marginBottom: isScreenBelow1200px ? '40px' : '20px', boxSizing: 'border-box', minWidth: '0px', alignItems: 'stretch', flexDirection: 'column', marginRight: 'auto', marginLeft: '', }}>

                                {currRole && currRole != "Ice" && <View style={{ boxSizing: 'border-box', marginTop: '0px', minWidth: '0px', display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingBottom: '10px', height: '50px', alignItems: 'center', flexDirection: 'row', paddingHorizontal: '2px' }}>
                                    <Text style={{ fontSize: 22, fontWeight: 450 }}>Today's Inventory</Text>
                                </View>}
                                {currRole && currRole != "Ice" && <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', borderRadius: '0.2rem', padding: '1rem', borderWidth: '1px', borderImage: 'initial', borderStyle: 'solid', borderColor: 'lightgray', backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <View style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', overflow: 'hidden', position: 'relative', display: 'block', outline: '0px !important' }}>
                                        <View style={{ outline: 'none', width: '100%', display: 'block', float: 'left', height: '100%', minHeight: '1px' }}>
                                            <View style={{ width: '100%', display: 'inline-block', boxSizing: 'border-box', minWidth: '0px' }}>
                                                <View style={{ minHeight: '175px', width: '100%', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', }}>
                                                    <View style={{ width: '45%', minHeight: '100%', borderRadius: '0.2rem', padding: '0.5rem', borderWidth: '1px', borderImage: 'initial', borderStyle: 'solid', borderColor: 'lightgray', }}>
                                                        <View style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>

                                                            <View style={{ height: '45%', background: '' }}>
                                                                <Text style={{ marginHorizontal: 'auto' }}>In Box</Text>
                                                                <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                                                    <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Text style={{ paddingRight: '5px' }}>{rentedChairs != null && todaysInventory != null ? todaysInventory.chair_total - rentedChairs : 0}</Text>
                                                                        <Image
                                                                            source={require('../../public/images/beach_icons/chair.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                            style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                        />
                                                                    </View>
                                                                    <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Text style={{ paddingRight: '5px' }}>{rentedChairs != null && todaysInventory != null ? todaysInventory.umbrella_total - rentedUmbrellas : 0}</Text>
                                                                        <Image
                                                                            source={require('../../public/images/beach_icons/umbrella.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                            style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                        />
                                                                    </View>
                                                                    <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Text style={{ paddingRight: '5px' }}>{rentedChairs != null && todaysInventory != null ? todaysInventory.combo_total - rentedCombos : 0}</Text>
                                                                        <Image
                                                                            source={require('../../public/images/beach_icons/combo.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                            style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                        />
                                                                    </View>
                                                                </View>
                                                            </View>

                                                            <View style={{ height: '45%', background: '' }}>
                                                                <Text style={{ marginHorizontal: 'auto' }}>Rented</Text>
                                                                <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                                                    <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Text style={{ paddingRight: '5px' }}>{rentedChairs != null ? rentedChairs : 0}</Text>
                                                                        <Image
                                                                            source={require('../../public/images/beach_icons/chair.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                            style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                        />
                                                                    </View>
                                                                    <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Text style={{ paddingRight: '5px' }}>{rentedUmbrellas != null ? rentedUmbrellas : 0}</Text>
                                                                        <Image
                                                                            source={require('../../public/images/beach_icons/umbrella.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                            style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                        />
                                                                    </View>
                                                                    <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Text style={{ paddingRight: '5px' }}>{rentedCombos != null ? rentedCombos : 0}</Text>
                                                                        <Image
                                                                            source={require('../../public/images/beach_icons/combo.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                            style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                        />
                                                                    </View>
                                                                </View>
                                                            </View>


                                                            {overbooked && <View style={{ height: '45%', background: '' }}>
                                                                <Text style={{ marginHorizontal: 'auto' }}>Available For Rent</Text>
                                                                <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                                                    <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Text style={{ paddingRight: '5px' }}>18</Text>
                                                                        <Image
                                                                            source={require('../../public/images/beach_icons/chair.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                            style={{ width: 34, height: 34, resizeMode: 'contain', flex: 1, aspectRatio: 1, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                        />
                                                                    </View>
                                                                    <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Text style={{ paddingRight: '5px' }}>12</Text>
                                                                        <Image
                                                                            source={require('../../public/images/beach_icons/umbrella.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                            style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                        />
                                                                    </View>
                                                                    <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Text style={{ paddingRight: '5px' }}>9</Text>
                                                                        <Image
                                                                            source={require('../../public/images/beach_icons/combo.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                            style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                        />
                                                                    </View>
                                                                </View>
                                                            </View>}

                                                        </View>
                                                    </View>
                                                    <View style={{ width: '45%', minHeight: '100%', borderRadius: '0.2rem', padding: '0.5rem', borderWidth: '1px', borderImage: 'initial', borderStyle: 'solid', borderColor: 'lightgray', justifyContent: 'space-evenly' }}>
                                                        <select onChange={(e) => {
                                                            console.log(e.target.value)
                                                            setAveragesFilter(e.target.value)
                                                            getAverages(e.target.value);
                                                        }}>
                                                            <option value="day">Today</option>
                                                            <option value="week">This Week</option>
                                                            <option value="month">This Month</option>
                                                            <option value="year">This Year</option>
                                                        </select>
                                                        <View style={{ height: '45%', background: '' }}>
                                                            <Text style={{ marginHorizontal: 'auto', marginTop: 5 }}>Avg Daily Rented</Text>
                                                            <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                                                <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <Text style={{ paddingRight: '5px' }}>{averages ? Math.floor(averages.avgChairs) : 0}</Text>
                                                                    <Image
                                                                        source={require('../../public/images/beach_icons/chair.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                        style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                    />
                                                                </View>
                                                                <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <Text style={{ paddingRight: '5px' }}>{averages ? Math.floor(averages.avgUmbrellas) : 0}</Text>
                                                                    <Image
                                                                        source={require('../../public/images/beach_icons/umbrella.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                        style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                    />
                                                                </View>
                                                                <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <Text style={{ paddingRight: '5px' }}>{averages ? Math.floor(averages.avgCombos) : 0}</Text>
                                                                    <Image
                                                                        source={require('../../public/images/beach_icons/combo.png')} // Replace './path/to/your/image.png' with the actual path to your local image
                                                                        style={{ width: 34, height: 34, tintColor: 'black' }} // Adjust width, height, and tint color as needed
                                                                    />
                                                                </View>
                                                            </View>
                                                        </View>
                                                        <View style={{ height: '45%', background: '' }}>
                                                            <Text style={{ marginHorizontal: 'auto' }}>Revenue</Text>
                                                            <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                                <View style={{ width: '32%', minHeight: '62.5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <Text style={{ paddingRight: '5px', fontSize: 18, fontWeight: 500 }}>${averages ? Math.floor(averages.revenue).toLocaleString() : 0}</Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>}

                                <View style={{ boxSizing: 'border-box', marginTop: currRole && currRole != "Ice" ? '50px' : '0px', minWidth: '0px', display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingBottom: '10px', height: '50px', alignItems: 'center', flexDirection: 'row', paddingHorizontal: '2px' }}>
                                    <Text style={{ fontSize: 22, fontWeight: 450 }}>Weather</Text>
                                </View>
                                <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', borderRadius: '0.2rem', padding: '1rem', borderWidth: '1px', borderImage: 'initial', borderStyle: 'solid', borderColor: 'lightgray', backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <View style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', overflow: 'hidden', position: 'relative', display: 'block', outline: '0px !important' }}>
                                        <View style={{ outline: 'none', width: '100%', display: 'block', float: 'left', height: '100%', minHeight: '1px' }}>
                                            <View style={{ width: '100%', display: 'inline-block', boxSizing: 'border-box', minWidth: '0px' }}>
                                                <View style={{ minHeight: '100px', }}>
                                                    <WeatherWidget></WeatherWidget>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>


                                <View style={{ boxSizing: 'border-box', margin: '0px', marginTop: '50px', minWidth: '0px', display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingBottom: '10px', height: '50px', alignItems: 'center', flexDirection: 'row', paddingHorizontal: '2px' }}>
                                    <Text style={{ fontSize: 22, fontWeight: 450 }}>Tides</Text>
                                </View>
                                <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', borderRadius: '0.2rem', padding: '1rem', borderWidth: '1px', borderImage: 'initial', borderStyle: 'solid', borderColor: 'lightgray', backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <View style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', overflow: 'hidden', position: 'relative', display: 'block', outline: '0px !important' }}>
                                        <View style={{ outline: 'none', width: '100%', display: 'block', float: 'left', height: '100%', minHeight: '1px' }}>
                                            <View style={{ width: '100%', display: 'inline-block', boxSizing: 'border-box', minWidth: '0px' }}>
                                                <View style={{ minHeight: '100px', }}>
                                                    <Chart></Chart>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                            </View>

                        </View>

                    </View>
                </View>

            </View>

        </View>
    );
};

const styles = StyleSheet.create({
    dropdownStyle: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderRadius: 2.5,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 3,
        borderColor: 'black',
    },
    dropdownTextStyle: {
        height: '300pt',
        backgroundColor: 'lightgray',
        borderRadius: 2,
        overflow: 'auto',
    },
    back: {
        overflowX: 'hidden',
        width: '100%',
        height: '100%',
        backgroundColor: '#FBFCFD',
        boxSizing: 'border-box',
    },
    heading: {
        fontSize: 30,
        fontWeight: 'bold',
        marginTop: '2%',
        marginBottom: '5%',
        textAlign: 'center',
        color: 'black',
    },
    body: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: '5%',
        textAlign: 'center',
        color: 'white',
    },
});

export default HomeScreen;