import useAuth from "./useAuth";
import { API_URL } from "../components/constants";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useEmployeeSSE from "./useEmployeeSSE";

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
        await AsyncStorage.clear();
        try {
            //call server logout
            // perform logout action here
            fetch(`${API_URL}/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'https://www.jacobawebb.com',
                    'Access-Control-Request-Method': 'POST',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                },
                credentials: 'include',
            }).then(async res => {
                console.log(res)
                // useEmployeeSSE(null);
            });
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout