import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Touchable } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Feather } from '@expo/vector-icons';
import './scrollbar.css';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip';
import Ionicons from 'react-native-vector-icons/Ionicons';




export default function CustomDrawer({ isOpen, onClose, role, currentPage }) {
  const navigation = useNavigate();

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const privilegedItems = role === "Admin"
    ? [
      { icon: 'ice-cream-outline', route: '/new_sale', color: '#4682B4', name: 'New Sale' }, // Dark Olive Green
      { icon: 'user', route: '/roster', color: '#C8A2C8', name: 'Roster' },           // Lilac
      { icon: 'package', route: '/inventory', color: '#87CEEB', name: 'Inventory' },      // Sky Blue
      { icon: 'bar-chart-2', route: '/sales', color: '#FFC68C', name: 'Sales' },      // Peach
      { icon: 'trello', route: '/notices', color: '#98FB98', name: 'Notices' },         // Mint Green
    ]
    : [];

  const drawerItems = [
    { icon: 'home', route: '/home', color: '#00CED1', name: 'Home' },                 // Dark Turquoise
    { icon: 'map', route: '/map', color: '#FF6347', name: 'Map' },                   // Tomato
    { icon: 'book', route: '/bookings', color: '#FFA07A', name: 'Bookings' },             // Light Salmon
    { icon: 'shopping-cart', route: '/new_booking', color: '#4682B4', name: 'Create Booking' }, // Dark Olive Green
  ];

  const iceItems = [
    { icon: 'home', route: '/home', color: '#00CED1', name: 'Home' },                 // Dark Turquoise
    { icon: 'ice-cream-outline', route: '/new_sale', color: '#4682B4', name: 'New Sale' }, // Dark Olive Green
  ]


  return (
    <View>
      <View className="custom-scrollbar" style={[styles.container, isOpen && styles.open, styles.background, { zIndex: 1000000 }]}>
        <View style={{ top: 2, right: 2, justifyContent: 'flex-end', alignContent: 'center', display: 'flex', flexDirection: 'row', width: '100%' }}>
          <TouchableOpacity
            key={-1}
            onPress={() => { onClose() }}
            disabled={!isOpen}
          >
            {isOpen && <Feather name={'x-circle'} size={14} color={'black'} />}
            {!isOpen && <Text> </Text>}

          </TouchableOpacity>
        </View>

        {role && role === "Ice" && iceItems.map((item, index) => (
          <div data-tooltip-id={index === 0 ? '0' : index} data-tooltip-content={item.name} >
            <TouchableOpacity
              key={index}
              onPress={() => navigation(item.route)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              style={{ width: '100%', paddingHorizontal: 20, }}
            >
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                {isOpen && item.name != "New Sale" && <Text style={[styles.drawerItem, { color: (hoveredIndex === index || currentPage === item.name) ? '#00B2B5' : 'black', marginTop: item.name === "Home" ? 7.5 : 15.5, }]}>{item.name}</Text>}
                {isOpen && item.name === "New Sale" && <Text style={[styles.iceDrawerItem, { color: (hoveredIndex === index || currentPage === item.name) ? '#00B2B5' : 'black', marginTop: item.name === "Home" ? 7.5 : 15.5, }]}>{item.name}</Text>}

                {!isOpen && <Text style={[styles.drawerItem, { marginTop: item.name === "Home" ? 7.5 : 15.5, }]}>&#8203;</Text>}
                {item.name === "Home" && <Feather name={item.icon} size={19} color={(hoveredIndex === index || currentPage === item.name) ? '#00B2B5' : 'black'} />}
                {item.name === "New Sale" && <Ionicons name={item.icon} size={22} color={(hoveredIndex === index || currentPage === item.name) ? '#00B2B5' : 'black'} />}
              </View>
            </TouchableOpacity>
          </div>
        ))}


        {role && role != "Ice" && drawerItems.map((item, index) => (
          <div data-tooltip-id={index === 0 ? '0' : index} data-tooltip-content={item.name} >
            <TouchableOpacity
              key={index}
              onPress={() => navigation(item.route)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              style={{ width: '100%', paddingHorizontal: 20, }}
            >
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                {isOpen && <Text style={[styles.drawerItem, { color: (hoveredIndex === index || currentPage === item.name) ? '#00B2B5' : 'black', marginTop: item.name === "Home" ? 7.5 : 15.5, }]}>{item.name}</Text>}
                {!isOpen && <Text style={[styles.drawerItem, { marginTop: item.name === "Home" ? 7.5 : 15.5, }]}>&#8203;</Text>}
                <Feather name={item.icon} size={19} color={(hoveredIndex === index || currentPage === item.name) ? '#00B2B5' : 'black'} />
              </View>
            </TouchableOpacity>
          </div>
        ))}

        {role && role === "Admin" &&
          privilegedItems.map((item, index) => (
            <div data-tooltip-id={index + drawerItems.length} data-tooltip-content={item.name} >
              <TouchableOpacity
                key={index}
                onPress={() => navigation(item.route)}
                onMouseEnter={() => handleMouseEnter(drawerItems.length + index)}
                onMouseLeave={handleMouseLeave}
                style={{ width: '100%', paddingHorizontal: 20, }}
              >
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  {isOpen && item.name != "New Sale" && <Text style={[styles.drawerItem, { color: (hoveredIndex === index || currentPage === item.name) ? '#00B2B5' : 'black', marginTop: item.name === "Home" ? 7.5 : 15.5, }]}>{item.name}</Text>}
                  {isOpen && item.name === "New Sale" && <Text style={[styles.iceDrawerItem, { marginRight: '128px', color: (hoveredIndex === index || currentPage === item.name) ? '#00B2B5' : 'black', marginTop: item.name === "Home" ? 7.5 : 15.5, }]}>{item.name}</Text>}

                  {!isOpen && <Text style={styles.drawerItem}>&#8203;</Text>}
                  {item.name !== "New Sale" && <Feather name={item.icon} size={19} color={(hoveredIndex === drawerItems.length + index || currentPage === item.name) ? '#00B2B5' : 'black'} />}
                  {item.name === "New Sale" && <Ionicons name={item.icon} size={22} color={(hoveredIndex === drawerItems.length + index || currentPage === item.name) ? '#00B2B5' : 'black'} />}
                </View>
              </TouchableOpacity>
            </div>
          ))}


      </View>
      {!isOpen && <Tooltip id={'0'} style={{ zIndex: 10, }} />}
      {!isOpen && <Tooltip id={'1'} style={{ zIndex: 10, }} />}
      {!isOpen && <Tooltip id={'2'} style={{ zIndex: 10, }} />}
      {!isOpen && <Tooltip id={'3'} style={{ zIndex: 10, }} />}
      {!isOpen && <Tooltip id={'4'} style={{ zIndex: 10, }} />}
      {!isOpen && <Tooltip id={'5'} style={{ zIndex: 10, }} />}
      {!isOpen && <Tooltip id={'6'} style={{ zIndex: 10, }} />}
      {!isOpen && <Tooltip id={'7'} style={{ zIndex: 10, }} />}
      {!isOpen && <Tooltip id={'8'} style={{ zIndex: 10, }} />}
    </View>

  );
}

const styles = StyleSheet.create({
  container: {
    borderRightWidth: '1px',
    borderRightColor: 'lightgray',
    borderImage: 'initial',
    borderStyle: 'solid',
    position: 'absolute',
    marginTop: '65px',
    bottom: 0,
    left: 0,
    height: 'calc(100vh - 65px)',
    width: '60px',
    backgroundColor: 'red',
    // paddingTop: 20,
    // paddingHorizontal: 20,
    overflowY: 'auto',
    overflowX: 'initial',
    // zIndex: 5,
    // transform: [{ translateX: -190 }],
    transitionProperty: 'transform',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-out',
  },
  open: {
    width: '250px',
    // transform: [{ translateX: 0 }],
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  drawerItem: {
    fontSize: 15.5,
    marginTop: 15.5,
    marginBottom: 15.5,
  },
  iceDrawerItem: {
    fontSize: 15.5,
    marginTop: 15.5,
    marginBottom: 15.5,
    textWrap: 'nowrap',
    marginRight: '126px',
  },
  background: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    // zIndex: 1,
  },
  logo: {
    flexDirection: 'row',
    alignItems: 'center',

  },
  logoImage: {
    width: 30,
    height: 30,
    marginRight: 10,
    objectFit: 'contain',
    resizeMode: 'contain',
  },
});
