import React, { useState, useEffect, useRef, useMemo } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TextInput, Animated, Platform, Modal, Touchable } from 'react-native';
import RosterDropdown from '../RosterDropdown';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { API_URL } from '../constants';
import moment from 'moment';


const LIST_BORDER_COLOR = "#E6E6E6"
const cellWidth = 149.2857142857143;

const InventoryList = ({
    inventoryRef,
    filteredDataSource = [],
    onFilter = () => { },
    updateFilteredDataSource = () => { },
    updateHeaderFilter = () => { },
    onChangeSalesFilter = {},
    salesFilter = "Today",
    showBanner
}) => {

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [editingItem, setEditingItem] = useState(null);

    const [isUmbrellaAlertVisible, setUmbrellaAlertVisible] = useState(false);
    const [isChairAlertVisible, setChairAlertVisible] = useState(false);

    const [alertLocation, setAlertLocation] = useState();
    const [alertDates, setAlertDates] = useState();

    const [activeUmbrellaValues, setActiveUmbrellaValues] = useState({});
    const [activeChairValues, setActiveChairValues] = useState({});
    const [pendingUmbrellaValues, setPendingUmbrellaValues] = useState({});
    const [pendingChairValues, setPendingChairValues] = useState({});

    const [umbrellasRentedDate, setUmbrellasRentedDate] = useState(moment().format('YYYY-MM-DD'));
    const [chairsRentedDate, setChairsRentedDate] = useState(moment().format('YYYY-MM-DD'));

    const refs = useRef([]);
    const popupRef = useRef(null);

    const umbrellaRef = useRef(null);
    const newUmbrellaRef = useRef(null);
    const chairRef = useRef(null);
    const newChairRef = useRef(null);
    const boxRef = useRef(null);
    const alertTypeRef = useRef(null);
    const boxNameRef = useRef(null);

    const umbrellasOverbooked = useRef(null);
    const chairsOverbooked = useRef(null);

    const [removePendingUmbrellas, setRemovePendingUmbrellas] = useState(null);
    const [removePendingChairs, setRemovePendingChairs] = useState(null);

    const [totalSalesMap, setTotalSalesMap] = useState({});

    const [hoveredBox, setHoveredBox] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: null, left: null });

    useMemo(() => {
        refs.current = filteredDataSource.map(() => React.createRef());
    }, [filteredDataSource]);


    const calculateTotalSales = (filter, bookings) => {

        // Set the start date based on the filter
        const currentDate = new Date();
        let startDate;
        if (filter === 'Today') {
            startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        } else if (filter === 'Week') {
            startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
        } else if (filter === 'Month') {
            startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        } else if (filter === 'Year') {
            startDate = new Date(currentDate.getFullYear(), 0, 1);
        } else {
            // Invalid filter
            return 0;
        }


        // Calculate the total sales amount
        let totalSales = 0;
        bookings.forEach(booking => {
            const saleDate = new Date(booking.sale_date);
            if (saleDate >= startDate && saleDate <= currentDate) {
                const bookingStartDate = new Date(booking.start_date);
                const bookingEndDate = new Date(booking.end_date);
                const bookingDays = Math.ceil((bookingEndDate - bookingStartDate) / (1000 * 60 * 60 * 24)); // Calculate the duration of the booking in days
                const bookingTotalSales = booking.booking_items.reduce((acc, item) => {
                    // Assuming each item has a "quantity" and a "product_id", and there's a global product price lookup
                    const productPrice = (item.product.price);
                    return acc + productPrice * item.quantity;
                }, 0);
                totalSales += bookingTotalSales * bookingDays;
            }
        });
        console.log(totalSales)
        return totalSales;
    };


    const calculateUmbrellasRented = (date, bookings) => {
        let totalUmbrellas = 0;

        // Get the current date
        const currentDate = moment(date);

        // Loop through bookings
        if (bookings) {
            bookings.forEach(booking => {
                // Check if the booking is active (current date is between sale date and end date)
                if (
                    currentDate.isSameOrAfter(booking.start_date, 'day') &&
                    currentDate.isSameOrBefore(booking.end_date, 'day')
                ) {
                    if (booking.booking_items) {
                        booking.booking_items.forEach(booking_item => {
                            // Assuming product_id 1 is for umbrellas
                            if (booking_item.product_id === 1) {
                                totalUmbrellas += booking_item.quantity;
                            }
                        });
                    }
                }
            });
        }
        return totalUmbrellas;
    };

    const calculateChairsRented = (date, bookings) => {
        let totalChairs = 0;

        // Get the current date
        const currentDate = moment(date);

        // Loop through bookings
        if (bookings) {
            bookings.forEach(booking => {
                // Check if the booking is active (current date is between sale date and end date)
                if (
                    currentDate.isSameOrAfter(booking.start_date, 'day') &&
                    currentDate.isSameOrBefore(booking.end_date, 'day')
                ) {
                    if (booking.booking_items) {
                        booking.booking_items.forEach(booking_item => {
                            // Assuming product_id 1 is for umbrellas
                            if (booking_item.product_id === 2) {
                                totalChairs += booking_item.quantity;
                            }
                        });
                    }
                }
            });
        }
        return totalChairs;
    }

    const handleIconMouseEnter = (item) => {
        console.log(item)

        let elementRef;
        if (item === null) {
            console.log(hoveredBox.box_id)
            elementRef = refs.current[hoveredBox.box_id - 1];
        } else {
            setHoveredBox(item);
            elementRef = refs.current[item.box_id - 1];
        }

        if (item != null && hoveredBox && item.box_id === hoveredBox.box_id) {
            setHoveredBox(null)
            return;
        }

        const inventoryListBounds = inventoryRef.current.getBoundingClientRect();
        const elementBounds = elementRef.current.getBoundingClientRect();

        // Calculate the adjusted position relative to the InventoryList component
        const adjustedTop = elementBounds.top - 150 + elementBounds.height / 2 - inventoryListBounds.top;
        const adjustedLeft = elementBounds.left - 98.5 + elementBounds.width - inventoryListBounds.left;


        setPopupPosition({
            top: adjustedTop,
            left: adjustedLeft,
        });
    };



    const handleAccept = () => {
        console.log(hoveredBox)
        console.log(pendingUmbrellaValues[hoveredBox.box_id])
        console.log(pendingChairValues[hoveredBox.box_id])
        // Handle accept action for the hovered box (hoveredBoxId)
        updateInventory(hoveredBox.box_id, pendingUmbrellaValues[hoveredBox.box_id] > 0 ? pendingUmbrellaValues[hoveredBox.box_id] : activeUmbrellaValues[hoveredBox.box_id], pendingChairValues[hoveredBox.box_id] > 0 ? pendingChairValues[hoveredBox.box_id] : activeChairValues[hoveredBox.box_id], true, true)

        //set inventory
        setHoveredBox(null);
    };

    const rejectPending = async (item) => {
        const response = await fetch(`${API_URL}/inventory/reject/` + item.box_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'DELETE',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
        })

        if (response.ok) {
            showBanner("success", "Rejected pending inventory report.")
        } else {
            showBanner("error", "Error updating location inventory")
        }
    }

    const handleReject = (item) => {
        // Handle reject action for the hovered box (hoveredBoxId)

        //if the box is actively overbooked, submit warning message

        const box_id = item.box_id

        fetch(`${API_URL}/bookings/` + box_id + '?future=true', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
        })
            .then(async res => {
                try {
                    console.log(res.status)
                    if (res.status !== 200) {
                        if (res.status === 204) {
                            rejectPending(item)
                        }
                    } else {
                        // there are foreseeable bookings
                        const jsonRes = await res.json();
                        console.log(jsonRes)

                        // Find the earliest and latest dates
                        let starting_query_date = null;
                        let ending_query_date = null;


                        for (const { start_date, end_date } of jsonRes.dates) {
                            // Use the provided date strings directly

                            const startDateObj = new Date(start_date);
                            const endDateObj = new Date(end_date);


                            // Add 5 hours to the dates
                            // startDateObj.setHours(startDateObj.getHours() + 5);
                            // endDateObj.setHours(endDateObj.getHours() + 5);

                            // Check if the dates are valid
                            if (isNaN(startDateObj.getTime()) || isNaN(endDateObj.getTime())) {
                                console.error('Invalid date format:', start_date, end_date);
                                continue;
                            }

                            // Check for the earliest start date
                            if (!starting_query_date || startDateObj < starting_query_date) {
                                starting_query_date = startDateObj;
                            }

                            // Check for the latest end date
                            if (!ending_query_date || endDateObj > ending_query_date) {
                                ending_query_date = endDateObj;
                            }
                        }

                        if (starting_query_date && ending_query_date) {


                            starting_query_date.setHours(starting_query_date.getHours() - 5);
                            ending_query_date.setHours(ending_query_date.getHours() - 5);

                            const startingQueryFormatted = starting_query_date.toISOString().split('T')[0];
                            const endingQueryFormatted = ending_query_date.toISOString().split('T')[0];


                            const response2 = await fetch(`${API_URL}/bookings/` + box_id + '?start=' + startingQueryFormatted + '&end=' + endingQueryFormatted, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Origin': 'http://localhost:19006',
                                    'Access-Control-Request-Method': 'GET',
                                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                                },
                            });

                            const jsonRes = await response2.json();
                            let resCopy = jsonRes

                            console.log(item)
                            if (isOverbooked(resCopy, activeChairValues[item.box_id], activeUmbrellaValues[item.box_id])) {
                                //overbooked, cannot reject
                                showBanner("error", "This location is currently overbooked. Its pending inventory cannot be rejected at this time.")
                            } else {
                                rejectPending(item)
                            }


                        } else {
                            console.error('No valid dates found.');
                            showBanner("error", "Error updating location inventory")
                        }



                    }
                } catch (err) {
                    console.log(err);
                    showBanner("error", "Error updating location inventory")
                };
            })
            .catch(err => {
                console.log(err);
                showBanner("error", "Error updating location inventory")
            });


        //if the box is not actively overbooked, submit success


        setHoveredBox(null);
    };


    const renderPopup = (item, changes) => {
        if (!hoveredBox || !popupPosition) {
            return null;
        }

        return (
            <View
                ref={popupRef}
                style={{
                    position: 'absolute',
                    top: popupPosition.top,
                    left: popupPosition.left,
                    height: '120px',
                    width: '150px',
                    padding: '10px',
                    background: 'white',
                    // borderWidth: '1px',
                    // borderColor: 'black',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                    zIndex: 999,
                    borderRadius: 8,
                    alignItems: 'center', // Center content horizontally
                    justifyContent: 'center', // Center content vertically
                }}
            >
                <Text style={{ marginBottom: 10, textAlign: 'center' }}>{item.box_id}</Text>
                <TouchableOpacity onPress={() => { handleAccept() }} style={styles.popupButton}>
                    <Text style={styles.popupButtonText}>Accept</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => { handleReject(item) }} style={styles.popupButton}>
                    <Text style={styles.popupButtonText}>Reject</Text>
                </TouchableOpacity>
            </View>
        );
    };

    const updateDimensions = () => {
        console.log("test")
    }

    const resizeHandler = () => handleIconMouseEnter(null);

    // Use useEffect to set the initial values when the component mounts
    useEffect(() => {

        const initialUmbrellaValues = filteredDataSource.reduce((values, item) => {

            const umbrellaAssociations = item.boxProductAssociations.filter(
                (association) => association.product_id === 1 // Assuming product_id for umbrellas is 1
            );

            values[item.box_id] =
                umbrellaAssociations.find((association) => association.status === 'active')
                    ?.quantity || 0;
            return values;
        }, {});
        setActiveUmbrellaValues(initialUmbrellaValues);

        const initialChairValues = filteredDataSource.reduce((values, item) => {

            const chairAssociations = item.boxProductAssociations.filter(
                (association) => association.product_id === 2 // Assuming product_id for chairs is 2
            );

            values[item.box_id] =
                chairAssociations.find((association) => association.status === 'active')
                    ?.quantity || 0;

            return values;
        }, {});
        setActiveChairValues(initialChairValues);

        const pendingUmbrellaValues = filteredDataSource.reduce((values, item) => {

            const umbrellaAssociations = item.boxProductAssociations.filter(
                (association) => association.product_id === 1 // Assuming product_id for umbrellas is 1
            );

            values[item.box_id] =
                umbrellaAssociations.find((association) => association.status === 'pending')
                    ?.quantity || 0;
            return values;
        }, {});
        setPendingUmbrellaValues(pendingUmbrellaValues);

        const pendingChairValues = filteredDataSource.reduce((values, item) => {

            const chairAssociations = item.boxProductAssociations.filter(
                (association) => association.product_id === 2 // Assuming product_id for chairs is 2
            );

            values[item.box_id] =
                chairAssociations.find((association) => association.status === 'pending')
                    ?.quantity || 0;

            return values;
        }, {});
        setPendingChairValues(pendingChairValues);

        const calculateTotalSalesForEmployees = () => {
            const salesMap = {};
            // console.log(filteredDataSource)
            filteredDataSource.forEach(item => {
                const totalSales = calculateTotalSales(salesFilter, item.bookings);
                salesMap[item.box_id] = totalSales;
            });
            console.log(salesMap)
            setTotalSalesMap(salesMap);
        };

        calculateTotalSalesForEmployees();


        window.addEventListener('resize', resizeHandler);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };

    }, [filteredDataSource, hoveredBox, popupPosition, salesFilter]);


    const isOverbooked = (data, num_chairs, num_umbrellas) => {


        console.log(data)
        console.log(num_chairs)
        console.log(num_umbrellas)

        umbrellasOverbooked.current = 0
        chairsOverbooked.current = 0

        const totalsByDate = {};

        for (const result of data.results) {
            const date = result.date;

            // Ensure the date is initialized in the totalsByDate object
            if (!totalsByDate[date]) {
                totalsByDate[date] = {
                    total_chairs: 0,
                    total_umbrellas: 0,
                    total_combos: 0
                };
            }

            // Parse total values as integers
            const totalChairs = parseInt(result.total_chairs, 10);
            const totalUmbrellas = parseInt(result.total_umbrellas, 10);
            const totalCombos = parseInt(result.total_combos, 10);

            // Add the totals to the corresponding date
            totalsByDate[date].total_chairs += totalChairs;
            totalsByDate[date].total_umbrellas += totalUmbrellas;
            totalsByDate[date].total_combos += totalCombos;
        }

        // Check if totals exceed inventory space for each date
        for (const date in totalsByDate) {
            const { total_chairs, total_umbrellas, total_combos } = totalsByDate[date];

            if (total_chairs > num_chairs || total_umbrellas > num_umbrellas) {

                if (total_umbrellas > num_umbrellas) {
                    umbrellasOverbooked.current = total_umbrellas - num_umbrellas
                    console.log("Umbrellas:", total_umbrellas - num_umbrellas)
                }
                if (total_chairs > num_chairs) {
                    chairsOverbooked.current = total_chairs - num_chairs
                    console.log("Chairs:", total_chairs - num_chairs)

                }
                console.log(`Inventory limit exceeded on ${date}.`);
                return true;
            }
        }
        console.log("here4")

        return false;
    }

    const updateInventory = async (box_id, num_umbrellas, num_chairs, remove_pending_chairs, remove_pending_umbrellas) => {


        if (typeof (num_umbrellas) != "number" && num_umbrellas != undefined) {
            if (typeof (num_umbrellas) == "string") {
                var total_umbrellas = parseInt(num_umbrellas)
            } else {
                if (num_umbrellas.nativeEvent.text == '') {
                    var total_umbrellas = 0
                }
                else {
                    var total_umbrellas = parseInt(num_umbrellas.nativeEvent.text)
                }
            }
        } else { var total_umbrellas = num_umbrellas }

        if (typeof (num_chairs) != "number" && num_chairs != undefined) {
            if (typeof (num_chairs) == "string") {
                var total_chairs = parseInt(num_chairs)
            } else {
                if (num_chairs.nativeEvent.text == '') {
                    var total_chairs = 0
                }
                else {
                    var total_chairs = parseInt(num_chairs.nativeEvent.text)
                }
            }
        } else { var total_chairs = num_chairs }

        setRemovePendingUmbrellas(remove_pending_umbrellas)
        setRemovePendingChairs(remove_pending_chairs)


        // fetch(`${API_URL}/get_bookings_range`, {
        fetch(`${API_URL}/bookings/` + box_id + '?future=true', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            // body: JSON.stringify({
            //     box_id: box_id,
            // }),
        })
            .then(async res => {
                try {
                    console.log(res.status)
                    if (res.status !== 200) {
                        if (res.status === 204) {

                            // no reservations exist
                            const payload = {
                                // box_id: boxRef.current,
                                total_umbrellas: total_umbrellas,
                                total_chairs: total_chairs,
                                mode: "",
                                remove_pending_chairs: remove_pending_chairs,
                                remove_pending_umbrellas: remove_pending_umbrellas
                            }
                            // fetch(`${API_URL}/change_inventory`, {
                            fetch(`${API_URL}/inventory/` + box_id, {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Origin': 'http://localhost:19006',
                                    'Access-Control-Request-Method': 'PUT',
                                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                                },
                                body: JSON.stringify(payload),
                            })
                                .then(async res => {
                                    try {
                                        const jsonRes = await res.json();
                                        if (res.status !== 200) {
                                            console.log(jsonRes)
                                        } else {
                                            console.log(jsonRes)
                                            location.reload()
                                        }
                                    } catch (err) {
                                        //setMessage(err);
                                        console.log(err);
                                    };
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        }
                    } else {
                        const jsonRes = await res.json();
                        console.log(jsonRes)

                        // Find the earliest and latest dates
                        let starting_query_date = null;
                        let ending_query_date = null;


                        for (const { start_date, end_date } of jsonRes.dates) {
                            // Use the provided date strings directly

                            const startDateObj = new Date(start_date);
                            const endDateObj = new Date(end_date);


                            // Add 5 hours to the dates
                            // startDateObj.setHours(startDateObj.getHours() + 5);
                            // endDateObj.setHours(endDateObj.getHours() + 5);

                            // Check if the dates are valid
                            if (isNaN(startDateObj.getTime()) || isNaN(endDateObj.getTime())) {
                                console.error('Invalid date format:', start_date, end_date);
                                continue;
                            }

                            // Check for the earliest start date
                            if (!starting_query_date || startDateObj < starting_query_date) {
                                starting_query_date = startDateObj;
                            }

                            // Check for the latest end date
                            if (!ending_query_date || endDateObj > ending_query_date) {
                                ending_query_date = endDateObj;
                            }
                        }

                        if (starting_query_date && ending_query_date) {


                            starting_query_date.setHours(starting_query_date.getHours() - 5);
                            ending_query_date.setHours(ending_query_date.getHours() - 5);

                            const startingQueryFormatted = starting_query_date.toISOString().split('T')[0];
                            const endingQueryFormatted = ending_query_date.toISOString().split('T')[0];

                            const response2 = await fetch(`${API_URL}/bookings/` + box_id + '?start=' + startingQueryFormatted + '&end=' + endingQueryFormatted, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Origin': 'http://localhost:19006',
                                    'Access-Control-Request-Method': 'GET',
                                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                                },
                            });

                            const jsonRes = await response2.json();
                            let resCopy = jsonRes


                            if (isOverbooked(resCopy, total_chairs, total_umbrellas)) {
                                //confirm that the user wants to overbook
                                let resCopy = jsonRes
                                console.log("here1")
                                if (isOverbooked(resCopy, chairRef.current, umbrellaRef.current)) {
                                    console.log("here2")

                                    // if it is now overbooked, AND it WAS overbooked BEFORE
                                    const payload = {
                                        total_umbrellas: total_umbrellas,
                                        total_chairs: total_chairs,
                                        remove_pending_chairs: remove_pending_chairs,
                                        remove_pending_umbrellas: remove_pending_umbrellas
                                    }
                                    fetch(`${API_URL}/inventory/` + box_id, {
                                        method: 'PUT',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Origin': 'http://localhost:19006',
                                            'Access-Control-Request-Method': 'PUT',
                                            'Access-Control-Request-Headers': 'Content-Type, Authorization',
                                        },
                                        body: JSON.stringify(payload),
                                    })
                                        .then(async res => {
                                            try {
                                                const jsonRes = await res.json();
                                                if (res.status !== 200) {
                                                    console.log(jsonRes)
                                                } else {
                                                    console.log(jsonRes)
                                                    const payload = {
                                                        total_umbrellas: umbrellasOverbooked.current,
                                                        total_chairs: chairsOverbooked.current
                                                    }
                                                    fetch(`${API_URL}/overbook/` + box_id, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Origin': 'http://localhost:19006',
                                                            'Access-Control-Request-Method': 'POST',
                                                            'Access-Control-Request-Headers': 'Content-Type, Authorization',
                                                        },
                                                        body: JSON.stringify(payload),
                                                    })
                                                        .then(async res => {
                                                            try {
                                                                const jsonRes = await res.json();
                                                                if (res.status !== 200) {
                                                                    console.log(jsonRes)
                                                                } else {
                                                                    console.log(jsonRes)
                                                                    location.reload();
                                                                }
                                                            } catch (err) {
                                                                console.log(err);
                                                            };
                                                        })
                                                }
                                            } catch (err) {
                                                console.log(err);
                                            };
                                        })
                                        .catch(err => {
                                            console.log(err);
                                        })

                                } else {
                                    console.log("here5")
                                    setUmbrellaAlertVisible(true)
                                }

                            } else {
                                console.log("here3")

                                let payload = {
                                    total_umbrellas: total_umbrellas,
                                    total_chairs: total_chairs,
                                    remove_pending_chairs: remove_pending_chairs,
                                    remove_pending_umbrellas: remove_pending_umbrellas
                                }
                                fetch(`${API_URL}/inventory/` + box_id, {
                                    method: 'PUT',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Origin': 'http://localhost:19006',
                                        'Access-Control-Request-Method': 'PUT',
                                        'Access-Control-Request-Headers': 'Content-Type, Authorization',
                                    },
                                    body: JSON.stringify(payload),
                                })
                                    .then(async res => {
                                        try {
                                            const jsonRes = await res.json();
                                            if (res.status !== 200) {
                                                console.log(jsonRes)
                                            } else {
                                                console.log(jsonRes)
                                                fetch(`${API_URL}/revert-overbook/` + box_id, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Origin': 'http://localhost:19006',
                                                        'Access-Control-Request-Method': 'POST',
                                                        'Access-Control-Request-Headers': 'Content-Type, Authorization',
                                                    },
                                                })
                                                    .then(async res => {
                                                        try {
                                                            const jsonRes = await res.json();
                                                            if (res.status !== 200) {
                                                                console.log(jsonRes)
                                                            } else {
                                                                console.log(jsonRes)
                                                                location.reload();
                                                            }
                                                        } catch (err) {
                                                            console.log(err);
                                                        };
                                                    })
                                                    .catch(err => {
                                                        console.log(err);
                                                    })
                                            }
                                        } catch (err) {
                                            console.log(err);
                                        };
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })

                            }


                        } else {
                            console.error('No valid dates found.');
                        }



                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });

    }


    const renderHeader = () => {

        const headerContent = [];
        headerContent.push(

            <View style={{ verticalAlign: 'bottom', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Location</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Umbrella Inventory</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Chair Inventory</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Umbrellas Rented</Text>
                    </View>
                    <View style={{ width: '100%', height: '100%', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <input
                            type="date"
                            id="umbrellaDate"
                            name="umbrellaDate"
                            value={umbrellasRentedDate}
                            // max={moment().format('YYYY-MM-DD')}
                            onChange={(e) => setUmbrellasRentedDate(e.target.value)}
                        />
                    </View>
                </View>

                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Chairs Rented</Text>
                    </View>
                    <View style={{ width: '100%', height: '100%', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <input
                            type="date"
                            id="chairDate"
                            name="chairDate"
                            value={chairsRentedDate}
                            // min={moment().format('YYYY-MM-DD')}
                            onChange={(e) => setChairsRentedDate(e.target.value)}
                        />
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Total Sales</Text>
                    </View>
                    <View style={{ width: '100%', height: '100%', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <Animated.View style={{ width: '75%', height: '30px', position: 'relative', }}>
                            <RosterDropdown
                                textStyle={styles.dropdownTextStyle}
                                onFilter={(selection) => {

                                    onChangeSalesFilter(selection);
                                }}
                                type={"sale filter"}
                            />
                        </Animated.View>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Status</Text>
                    </View>
                </View>
            </View>
        )
        return (
            headerContent
        );
    }

    const renderContent = () => {

        return (
            filteredDataSource.map((item, index) => (


                <View key={item.box_id} style={{ zIndex: -1 - index, verticalAlign: 'bottom', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: index % 2 != 0 ? '#F3F3F3' : 'FFFFFF' }}>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{item.name}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', position: 'relative', width: '100%' }}>

                            {(pendingUmbrellaValues[item.box_id] != activeUmbrellaValues[item.box_id]) && pendingUmbrellaValues[item.box_id] != 0 &&
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <Ionicons name="warning" size={30} color="#FFD700" />
                                    <Text>Reported value: {pendingUmbrellaValues[item.box_id]}</Text>
                                    <Ionicons name="warning" size={30} color="#FFD700" />
                                </View>
                            }

                            <TextInput
                                key={`umbrella_${item.box_id}`}
                                value={activeUmbrellaValues[item.box_id] || ''} // Use the state variable based on box_id
                                onChangeText={(text) => {

                                    const updatedUmbrellaValues = { ...activeUmbrellaValues, [item.box_id]: parseInt(text) };
                                    setActiveUmbrellaValues(updatedUmbrellaValues);

                                }}
                                onFocus={() => {
                                    umbrellaRef.current = activeUmbrellaValues[item.box_id];
                                    chairRef.current = activeChairValues[item.box_id];
                                    boxRef.current = item.box_id;
                                    alertTypeRef.current = "umbrella";
                                    boxNameRef.current = item.name;
                                }}
                                onBlur={(num_umbrellas) => {

                                    console.log(num_umbrellas.nativeEvent.text)

                                    if (num_umbrellas.nativeEvent.text == "") {
                                        console.log("1")
                                        newUmbrellaRef.current = "0"
                                    } else {
                                        console.log("2")
                                        newUmbrellaRef.current = parseInt(num_umbrellas.nativeEvent.text)
                                    }

                                    newChairRef.current = activeChairValues[item.box_id];

                                    if (typeof (umbrellaRef.current) === "number") {
                                        if (umbrellaRef.current !== parseInt(newUmbrellaRef.current)) {
                                            updateInventory(item.box_id, num_umbrellas, activeChairValues[item.box_id], parseInt(pendingChairValues[item.box_id]) === parseInt(activeChairValues[item.box_id]) ? true : false, parseInt(pendingUmbrellaValues[item.box_id]) === parseInt(activeUmbrellaValues[item.box_id]) ? true : false);
                                        }
                                    } else if (typeof (umbrellaRef.current) === "string") {
                                        if (parseInt(umbrellaRef.current) !== parseInt(newUmbrellaRef.current)) {
                                            updateInventory(item.box_id, num_umbrellas, activeChairValues[item.box_id], parseInt(pendingChairValues[item.box_id]) === parseInt(activeChairValues[item.box_id]) ? true : false, parseInt(pendingUmbrellaValues[item.box_id]) === parseInt(activeUmbrellaValues[item.box_id]) ? true : false);
                                        }
                                    }
                                }}
                                style={{
                                    boxSizing: 'border-box',
                                    margin: '0px',
                                    minWidth: '0px',
                                    appearance: 'none',
                                    borderImage: 'initial',
                                    display: 'block',
                                    height: 'auto',
                                    paddingRight: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.75rem',
                                    paddingBottom: '0.75rem',
                                    fontSize: '12px',
                                    fontWeight: 'normal',
                                    lineHeight: '0.75',
                                    backgroundColor: 'white', // Fix the background color
                                    color: 'black',
                                    backgroundClip: 'padding-box',
                                    border: '1px solid ' + LIST_BORDER_COLOR,
                                    borderRadius: '0.2rem',
                                    width: '100%',
                                }}
                                placeholder="Umbrella Total"
                            />
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', position: 'relative', width: '100%' }}>

                            {(pendingChairValues[item.box_id] != activeChairValues[item.box_id]) && pendingChairValues[item.box_id] != 0 &&
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <Ionicons name="warning" size={30} color="#FFD700" />
                                    <Text>Reported value: {pendingChairValues[item.box_id]}</Text>
                                    <Ionicons name="warning" size={30} color="#FFD700" />
                                </View>
                            }

                            <TextInput
                                key={`chair_${item.box_id}`}
                                value={activeChairValues[item.box_id] || ''} // Use the state variable based on box_id
                                onChangeText={(text) => {
                                    console.log(activeChairValues)
                                    const updatedChairValues = { ...activeChairValues, [item.box_id]: parseInt(text) };
                                    setActiveChairValues(updatedChairValues);
                                }}
                                onFocus={() => {
                                    umbrellaRef.current = activeUmbrellaValues[item.box_id];
                                    chairRef.current = activeChairValues[item.box_id];
                                    boxRef.current = item.box_id;
                                    alertTypeRef.current = "chair";
                                    boxNameRef.current = item.name;
                                }}
                                onBlur={(num_chairs) => {
                                    newUmbrellaRef.current = item.umbrella_total;
                                    newChairRef.current = parseInt(num_chairs.nativeEvent.text);

                                    if (typeof (chairRef.current) === "number") {
                                        if (chairRef.current !== parseInt(num_chairs.nativeEvent.text)) {
                                            updateInventory(item.box_id, activeUmbrellaValues[item.box_id], num_chairs, parseInt(pendingChairValues[item.box_id]) === parseInt(activeChairValues[item.box_id]) ? true : false, parseInt(pendingUmbrellaValues[item.box_id]) === parseInt(activeUmbrellaValues[item.box_id]) ? true : false);
                                        }
                                    } else if (typeof (chairRef.current) === "string") {
                                        if (parseInt(chairRef.current) !== parseInt(num_chairs.nativeEvent.text)) {
                                            updateInventory(item.box_id, activeUmbrellaValues[item.box_id], num_chairs, parseInt(pendingChairValues[item.box_id]) === parseInt(activeChairValues[item.box_id]) ? true : false, parseInt(pendingUmbrellaValues[item.box_id]) === parseInt(activeUmbrellaValues[item.box_id]) ? true : false);
                                        }
                                    }
                                }}
                                style={{
                                    boxSizing: 'border-box',
                                    margin: '0px',
                                    minWidth: '0px',
                                    appearance: 'none',
                                    borderImage: 'initial',
                                    display: 'block',
                                    height: 'auto',
                                    paddingRight: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.75rem',
                                    paddingBottom: '0.75rem',
                                    fontSize: '12px',
                                    fontWeight: 'normal',
                                    lineHeight: '0.75',
                                    backgroundColor: 'white', // Fix the background color
                                    color: 'black',
                                    backgroundClip: 'padding-box',
                                    border: '1px solid ' + LIST_BORDER_COLOR,
                                    borderRadius: '0.2rem',
                                    width: '100%',
                                }}
                                placeholder="Umbrella Total"
                            />
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{calculateUmbrellasRented(umbrellasRentedDate, item.bookings)}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{calculateChairsRented(chairsRentedDate, item.bookings)}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>${totalSalesMap[item.box_id] != undefined ? totalSalesMap[item.box_id].toLocaleString() : 0}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', alignItems: 'center', }}>
                            <View
                                ref={refs.current[item.box_id - 1]}
                                key={item.box_id}
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                }}
                                onClick={() => {
                                    console.log(item.box_id);
                                    boxRef.current = item.box_id;
                                    handleIconMouseEnter(item);
                                }
                                }
                            >
                                {((pendingChairValues[item.box_id] != activeChairValues[item.box_id] || pendingUmbrellaValues[item.box_id] != activeUmbrellaValues[item.box_id]) && !(pendingUmbrellaValues[item.box_id] === 0 && pendingChairValues[item.box_id] === 0)) && <Ionicons name="warning" size={45} color="#FFD700" />}
                            </View>
                            {!((pendingChairValues[item.box_id] != activeChairValues[item.box_id] || pendingUmbrellaValues[item.box_id] != activeUmbrellaValues[item.box_id]) && !(pendingUmbrellaValues[item.box_id] === 0 && pendingChairValues[item.box_id] === 0)) && <Ionicons name="checkmark-circle" size={45} color="#4CAF50" />}
                        </View>
                    </View>
                </View>
            )));
    }


    return (
        <View style={{ width: '100%', marginBottom: '1rem', color: 'black', backgroundColor: 'white', borderCollapse: 'collapse', borderRight: '1px solid ' + LIST_BORDER_COLOR, borderLeft: '1px solid ' + LIST_BORDER_COLOR, borderBottom: '1px solid ' + LIST_BORDER_COLOR, }}>
            {renderHeader()}
            {renderContent()}
            {renderPopup(hoveredBox)}

            {removePendingChairs != null && removePendingUmbrellas != null &&
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={isUmbrellaAlertVisible}
                    onRequestClose={() => setUmbrellaAlertVisible(false)}
                >
                    {/* <View style={{ opacity: 0.5, height: '100%', width: '100%', backgroundColor: 'red'}}>
                        <TouchableOpacity onPress={()=>{setUmbrellaAlertVisible(false)}} style={{margin: 'auto', height: 100, width: 100}}>

                        </TouchableOpacity>
                    </View> */}
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)', }}>
                        <View style={{ width: '35%', backgroundColor: 'white', borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, borderColor: 'black', borderWidth: 1 }}>
                            <Text style={{ fontSize: 18, marginBottom: 20 }}>Warning: Overbooking Confirmation</Text>
                            <Text style={{ marginBottom: 20 }}>You are about to overbook <Text style={{ fontWeight: 'bold' }}>{boxNameRef.current}</Text>.</Text>
                            <Text>Are you sure you want to proceed?</Text>
                            <View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
                                <TouchableOpacity onPress={() => {
                                    setUmbrellaAlertVisible(false);
                                    // Call your function to handle the overbooking confirmation here

                                    console.log("Umbrella ref: ", umbrellasOverbooked.current)

                                    const payload = {
                                        // box_id: boxRef.current,
                                        total_umbrellas: umbrellaRef.current === newUmbrellaRef.current ? null : newUmbrellaRef.current,
                                        total_chairs: chairRef.current === newChairRef.current ? null : newChairRef.current,
                                        remove_pending_chairs: false,
                                        remove_pending_umbrellas: false,
                                        mode: "set_overbooked",
                                    }
                                    // fetch(`${API_URL}/change_inventory`, {
                                    fetch(`${API_URL}/inventory/` + boxRef.current, {
                                        method: 'PUT',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Origin': 'http://localhost:19006',
                                            'Access-Control-Request-Method': 'PUT',
                                            'Access-Control-Request-Headers': 'Content-Type, Authorization',
                                        },
                                        body: JSON.stringify(payload),
                                    })
                                        .then(async res => {
                                            try {
                                                const jsonRes = await res.json();
                                                if (res.status !== 200) {
                                                    console.log(jsonRes)
                                                } else {
                                                    console.log(jsonRes)
                                                    const payload = {
                                                        // box_id: boxRef.current,
                                                        total_umbrellas: umbrellasOverbooked.current,
                                                        total_chairs: chairsOverbooked.current
                                                    }
                                                    fetch(`${API_URL}/overbook/` + boxRef.current, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Origin': 'http://localhost:19006',
                                                            'Access-Control-Request-Method': 'POST',
                                                            'Access-Control-Request-Headers': 'Content-Type, Authorization',
                                                        },
                                                        body: JSON.stringify(payload),
                                                    })
                                                        .then(async res => {
                                                            try {
                                                                const jsonRes = await res.json();
                                                                if (res.status !== 200) {
                                                                    console.log(jsonRes)
                                                                } else {
                                                                    console.log(jsonRes)
                                                                    // location.reload();
                                                                }
                                                            } catch (err) {
                                                                //setMessage(err);
                                                                console.log(err);
                                                            };
                                                        })
                                                }
                                            } catch (err) {
                                                //setMessage(err);
                                                console.log(err);
                                            };
                                        })
                                        .catch(err => {
                                            console.log(err);
                                        })


                                }} style={styles.confirmButton}>
                                    <Text style={{ color: 'black' }}>Confirm</Text>
                                </TouchableOpacity>

                                <TouchableOpacity onPress={() => {
                                    setUmbrellaAlertVisible(false)

                                    setActiveUmbrellaValues((prevValues) => ({
                                        ...prevValues,
                                        [boxRef.current]: umbrellaRef.current,
                                    }));

                                    setActiveChairValues((prevValues) => ({
                                        ...prevValues,
                                        [boxRef.current]: chairRef.current,
                                    }));


                                    newUmbrellaRef.current = umbrellaRef.current
                                    newChairRef.current = chairRef.current


                                }} style={styles.cancelButton}>
                                    <Text style={{ color: 'white' }}>Cancel</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Modal>}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        height: '91.5%',
    },
    itemStyle: {
        flexDirection: "row",
        alignItems: "center",
        height: '5%',
        flexWrap: 'nowrap',
        width: '100%',
    },
    column: {
        padding: 3,
        overflow: 'hidden',
    },
    textInputStyle: {
        height: '100%',
        borderWidth: 1,
        paddingLeft: 20,
        margin: 2.5,
        borderColor: '#009688',
        width: '99%',
    },
    columnSeparator: {
        width: '0.1%',
        height: '100%',
        backgroundColor: 'lightgray',
        borderBottom: '2px solid ' + LIST_BORDER_COLOR,
    },
    headerItemText: {
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    itemText: {
        whiteSpace: 'nowrap',
        width: '100%',
    },
    dropdownTextStyle: {
        height: '300pt',
        backgroundColor: 'lightgray',
        borderRadius: 2,
        overflow: 'auto',
    },
    cancelButton: {
        flex: 1,
        height: 40,
        width: '30%',
        marginLeft: 5,
        backgroundColor: 'blue',
        borderRadius: '1.5rem',
        alignItems: 'center',
        justifyContent: 'center',
    },
    confirmButton: {
        flex: 1,
        height: 40,
        width: '30%',
        marginRight: 5,
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: '1.5rem',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
    },
    popupButton: {
        backgroundColor: '#3498db',
        padding: 10,
        borderRadius: 5,
    },
    popupButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center', // Center text within the button
    },
});

export default InventoryList;