import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TextInput, Animated, Platform } from 'react-native';


const LIST_BORDER_COLOR = "#E6E6E6"
const cellWidth = 125;

const SalesList = ({
    filteredDataSource = [],
    type = {},
}) => {

    console.log(filteredDataSource)

    function calculateBookingDuration(startDateString, endDateString) {
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        // Calculate the difference in milliseconds
        const difference = endDate.getTime() - startDate.getTime();

        // Convert the difference to days
        const daysDifference = difference / (1000 * 3600 * 24);

        // Round down to get the number of whole days
        return Math.floor(daysDifference + 1);

    }

   function formatDate(dateString) {
    const date = new Date(dateString);
    return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
}

    function getPrice(item) {
        let total = 0;

        let days = calculateBookingDuration(item.start_date, item.end_date)


        item.booking_items.forEach(booking_item => {
            total += parseInt(booking_item.product.price * booking_item.quantity) * parseInt(days);
        })

        return total;
    }

    const renderHeader = () => {

        const headerContent = [];
        headerContent.push(

            <View style={{ verticalAlign: 'bottom', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ minWidth: cellWidth - 80, maxWidth: cellWidth - 80, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>ID</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Location</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Employee</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Customer</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Order</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Start Date</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>End Date</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Price</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Status</Text>
                    </View>
                </View>
            </View>
        )
        return (
            headerContent
        );
    }


    return (
        <View style={{ marginBottom: '1rem', color: 'black', backgroundColor: 'white', borderCollapse: 'collapse', borderRight: '1px solid ' + LIST_BORDER_COLOR, borderLeft: '1px solid ' + LIST_BORDER_COLOR, borderBottom: '1px solid ' + LIST_BORDER_COLOR, }}>
            {renderHeader()}
            {filteredDataSource.map((item, index) => (
                <View key={index} style={{ zIndex: -1 - index, verticalAlign: 'bottom', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: index % 2 != 0 ? '#F3F3F3' : 'FFFFFF' }}>
                    <View style={{ minWidth: cellWidth - 80, maxWidth: cellWidth - 80, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ overflowX: 'auto', boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{item.booking_id}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ overflowX: 'auto', boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{item.inventory.name}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ overflowX: 'auto', boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{item.employee.name}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ overflowX: 'auto', boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{item.customer_id}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ overflowX: 'auto', boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            {item.booking_items.map((booking_item, index) => (
                                <React.Fragment key={index}>
                                    {booking_item.product.product_id === 1 && <Text>Umbrellas: {booking_item.quantity}</Text>}
                                    {booking_item.product.product_id === 2 && <Text>Chairs: {booking_item.quantity}</Text>}
                                    {booking_item.product.product_id === 3 && <Text>Combos: {booking_item.quantity}</Text>}
                                </React.Fragment>
                            ))}
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ overflowX: 'auto', boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{formatDate(item.start_date)}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ overflowX: 'auto', boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{formatDate(item.end_date)}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ overflowX: 'auto', boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{getPrice(item)}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ overflowX: 'auto', boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>Cancel Booking</Text>
                        </View>
                    </View>
                </View>
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        height: '91.5%',
    },
    itemStyle: {
        flexDirection: "row",
        alignItems: "center",
        height: '5%',
        flexWrap: 'nowrap',
        width: '100%',
    },
    column: {
        padding: 3,
        overflow: 'hidden',
    },
    textInputStyle: {
        height: '100%',
        borderWidth: 1,
        paddingLeft: 20,
        margin: 2.5,
        borderColor: '#009688',
        width: '99%',
    },
    columnSeparator: {
        width: '0.1%',
        height: '100%',
        backgroundColor: 'lightgray',
        borderBottom: '2px solid ' + LIST_BORDER_COLOR,
    },
    headerItemText: {
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    itemText: {
        whiteSpace: 'nowrap',
        width: '100%',
    },
    dropdownTextStyle: {
        height: '300pt',
        backgroundColor: 'lightgray',
        borderRadius: 2,
        overflow: 'auto',
    },
});

export default SalesList;