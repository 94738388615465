import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./App.css";
import CheckoutForm from "./CheckoutForm";
import SaleCheckoutForm from "../Sales/SaleCheckoutForm";
import { View, Platform } from "react-native";
import { API_URL } from '../constants';


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

const stripePromise = loadStripe("pk_test_51LMbyWBbVllh4lyvvO2cte2uwCBJ2Ca0cxqFqPCqYdZD8DeHI5ZDiDRBNgWAv651ByyFeqjbt2kb2Bsx35Dpf1g400ohHqP2AG");

export default function PaymentForm({ orderContents, selectedDateRangeFinal, price, dateRangeListFinal, availableInventory, usingReportedValues, showBanner, customerName, customerEmail, paymentType }) {
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch(`${API_URL}/create-payment-intent`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };


    return (
        <div className="App">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    {paymentType === "rental" && <CheckoutForm customerName={customerName} customerEmail={customerEmail} showBanner={showBanner} orderContents={orderContents} selectedDateRangeFinal={selectedDateRangeFinal} price={price} dateRangeListFinal={dateRangeListFinal} availableInventory={availableInventory} usingReportedValues={usingReportedValues} />}
                    {paymentType === "sale" && <SaleCheckoutForm showBanner={showBanner} orderContents={orderContents} />}
                </Elements>
            )}

        </div>
    );
}