// UniversalAlert.js
import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TextInput } from 'react-native';
import Modal from 'react-native-modal';

const OverbookedAlert = ({ isVisible, onCancel, onOK, data }) => {

  

  const [screen, setScreen] = useState(1)
  const [extraUmbrellas, setExtraUmbrellas] = useState(0)
  const [extraChairs, setExtraChairs] = useState(0)
  
  console.log(data)
  console.log(screen)

  return (
    <Modal
      animationType={false}
      transparent={true}
      isVisible={isVisible}
      onRequestClose={() => setUmbrellaAlertVisible(false)}
    >
      {screen == 1 &&
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0)', }}>
          <View style={{ width: '35%', backgroundColor: 'white', borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, borderColor: 'black', borderWidth: 1 }}>
            <Text style={{ fontSize: 18, marginBottom: 20 }}>Warning: Overbooking Confirmation</Text>
            <Text style={{ marginBottom: 20 }}>You are about to overbook <Text style={{ fontWeight: 'bold' }}>{data.boxName}</Text>.</Text>
            <Text>Are you sure you want to proceed?</Text>
            <View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
              <TouchableOpacity onPress={() => { setScreen(2) }} style={styles.confirmButton}>
                <Text style={{ color: 'black' }}>Confirm</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => { onCancel() }} style={styles.cancelButton}>
                <Text style={{ color: 'white' }}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      }
      {screen == 2 &&
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0)', }}>
          <View style={{ width: '35%', backgroundColor: 'white', borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, borderColor: 'black', borderWidth: 1 }}>
            <Text style={{ fontSize: 18, marginBottom: 20 }}>Warning: Overbooking Confirmation</Text>
            <Text style={{ marginBottom: 10 }}>Please enter how many extra umbrellas and chairs you have at <Text style={{ fontWeight: 'bold' }}>{data.boxName}</Text>.</Text>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '80%', marginBottom: 10, marginTop: 10 }}>
              <View style={[{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', }, styles.inputView]}>
                <View style={{ width: '100%', justifyContent: 'center' }}>
                  <Text><Text style={{ color: data.newUmbrellas > data.oldUmbrellas ? 'red' : 'black' }}>{parseInt(data.newUmbrellas - data.oldUmbrellas) > 0 ? data.newUmbrellas - data.oldUmbrellas : 0}</Text> umbrellas overbooked</Text>
                </View>
                <TextInput style={styles.input}
                  placeholder='Extra umbrellas'
                  onChangeText={(new_text) => {
                    console.log(data.newUmbrellas)
                    console.log(typeof(data.newUmbrellas))
                    console.log("UMBRELLAS: ", new_text + "CHAIRS: ", extraChairs)

                    if (new_text !== '' && !isNaN(new_text)) {
                      setExtraUmbrellas(parseInt(new_text, 10));
                    } else {
                      setExtraUmbrellas(0);
                    }
                  }}
                >

                </TextInput>
              </View>
              <View style={[{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', }, styles.inputView]}>
                <View style={{ width: '100%', justifyContent: 'center' }}>
                  <Text><Text style={{ color: data.newChairs  > data.oldChairs ? 'red' : 'black' }}>{parseInt(data.newChairs - data.oldChairs) > 0 ? data.newChairs - data.oldChairs : 0}</Text> chairs overbooked</Text>
                </View>
                <TextInput style={styles.input}
                  placeholder='Extra Chairs'
                  onChangeText={(new_text) => {
                    console.log("UMBRELLAS: ", extraUmbrellas + "CHAIRS: ", new_text)
                    
                    if (new_text !== '' && !isNaN(new_text)) {
                      setExtraChairs(parseInt(new_text, 10));
                    } else {
                      setExtraChairs(0);
                    }
                  }}
                >

                </TextInput>
              </View>
            </View>
            <View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
              <TouchableOpacity onPress={() => { onOK(data, extraUmbrellas, extraChairs) }}
                style={[styles.confirmButton, { opacity: ((parseInt(extraUmbrellas) < parseInt(data.newUmbrellas - data.oldUmbrellas)) || (parseInt(extraChairs) < parseInt(data.newChairs - data.oldChairs))) ? 0.5 : 1 }]}
                disabled={((parseInt(extraUmbrellas) < parseInt(data.newUmbrellas - data.oldUmbrellas)) || (parseInt(extraChairs) < parseInt(data.newChairs - data.oldChairs)))}
              >
                <Text style={{ color: 'black' }}>Confirm</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => {
                setScreen(1);
                onCancel();
              }}
                style={styles.cancelButton}
              >

                <Text style={{ color: 'white' }}>Cancel</Text>

              </TouchableOpacity>
            </View>
          </View>
        </View>
      }
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertBox: {
    backgroundColor: 'white',
    padding: 30, // Increased padding
    borderRadius: 8,
    elevation: 5, // For Android shadow
    shadowColor: 'black', // For iOS shadow
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
  },
  message: {
    fontSize: 18, // Increased font size
    marginBottom: 20,
    textAlign: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: '#007BFF', // Blue color for OK
    padding: 15, // Increased button padding
    borderRadius: 5,
    flex: 1,
    marginHorizontal: 5,
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    fontSize: 18, // Increased font size
  },
  cancelButton: {
    flex: 1,
    height: 40,
    width: '30%',
    marginLeft: 5,
    backgroundColor: 'blue',
    borderRadius: '1.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  confirmButton: {
    flex: 1,
    height: 40,
    width: '30%',
    marginRight: 5,
    borderWidth: 2,
    borderColor: 'black',
    borderRadius: '1.5rem',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputView: {
    flex: 1,
    height: 50,
    width: '30%',
    marginRight: 5,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    height: '100%',
    width: '100%',
    borderWidth: 2,
    borderColor: 'black',
    borderRadius: '0.25rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default OverbookedAlert;
