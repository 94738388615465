import React, { useState, useEffect } from 'react';
import { ImageBackground, View, Text, StyleSheet, TouchableOpacity, TextInput, Platform, Button, SafeAreaView, FlatList, Image, Linking, CheckBox } from 'react-native';
import Header from '../../components/Header';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from '../../components/constants';
import setupFetchInterceptors from '../../hooks/setupFetchInterceptors';
import NoticesList from '../../components/Lists/NoticesList';
import { Feather } from '@expo/vector-icons';


const PAGE_SIZE = 20;


/* 

WARNING: THIS METHOD OF PAGE GENERATION MIGHT BECOME VERY SLOW WITH LARGE DATA

IN FUTURE IMPLEMENTATIONS, CANNOT LOAD ENTIRE DB INTO MASTER DATA; INSTEAD MUST
MAKE NEW QUERY WITH EVERY PAGE LOAD TO GRAB ONLY THE SPECIFIED AMOUNT OF DATA

*/


const NoticesScreen = ({ navigation }) => {

    const [search, setSearch] = useState('');
    const [filteredDataSource, setFilteredDataSource] = useState([]);
    const [masterDataSource, setMasterDataSource] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currRole, setCurrRole] = useState("")

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    setupFetchInterceptors()

    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);


    const updateFilteredDataSource = (newData) => {
        setFilteredDataSource(newData);
    };

    const updateHeaderFilter = (filter) => {

        const newData = masterDataSource.filter(function (item) {
            const itemData = item.role.toUpperCase();
            const textData = filter.toUpperCase();

            return itemData.indexOf(textData) > -1;
        });
        setFilteredDataSource(newData.splice(0, newData.length));

    }

    // get data from storage
    async function getData(key, defaultValue = null) {
        try {
            const value = await AsyncStorage.getItem(key);

            if (value !== null) {
                return value;
            } else {
                return defaultValue;
            }
        } catch (error) {
            console.log(error);
            return defaultValue;
        }
    }

    const changePage = (offset) => {
        //get amount of bookings to calculate pages

        let amountOfBookings;

        //get first set of bookings
        fetch(`${API_URL}/notices/count`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
        })
            .then(async res => {
                try {
                    if (res.status !== 200) {
                    } else {
                        const jsonRes = await res.json();
                        console.log(jsonRes)
                        amountOfBookings = jsonRes.totalNotices;
                        const totalSize = Math.ceil(amountOfBookings / PAGE_SIZE);
                        setTotalPages(totalSize)
                        console.log(totalSize)
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });


        //get first set of bookings
        fetch(`${API_URL}/notices?offset=` + offset, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
        })
            .then(async res => {
                try {
                    if (res.status !== 200) {
                        console.log(res)
                    } else {
                        const jsonRes = await res.json();

                        console.log(jsonRes);

                        setFilteredDataSource(jsonRes.data.slice(0, PAGE_SIZE));
                        setMasterDataSource(jsonRes.data);
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });
    }



    useEffect(() => {

        const getRole = async () => {
            setCurrRole(await getData('role'));
        };

        getRole();

        changePage(0)

        // fetch(`${API_URL}/notices`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Origin': 'http://localhost:19006',
        //         'Access-Control-Request-Method': 'GET',
        //         'Access-Control-Request-Headers': 'Content-Type, Authorization',
        //     },
        // })
        //     .then(async res => {
        //         try {
        //             const jsonRes = await res.json();
        //             if (res.status !== 200) {
        //             } else {
        //                 const totalSize = Math.ceil(jsonRes.data.length / PAGE_SIZE);
        //                 setTotalPages(totalSize);
        //                 console.log(jsonRes);
        //                 setFilteredDataSource(jsonRes.data.slice(0, PAGE_SIZE));
        //                 setMasterDataSource(jsonRes.data);
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         };
        //     })
        //     .catch(err => {
        //         console.log(err);
        //     });

    }, []);

    const searchFilterFunction = (category, text) => {
        console.log(category, text);

        if (text) {
            const newData = masterDataSource.filter(function (item) {
                if (category == "name") {
                    var itemData = item.name.toUpperCase();
                } else if (category == "email") {
                    var itemData = item.email.toUpperCase();
                } else if (category == "role") {
                    if (text.toUpperCase() == "ALL") {
                        return 1;
                    }
                    var itemData = item.role.toUpperCase();
                }
                const textData = text.toUpperCase();

                return itemData.indexOf(textData) > -1;
            });
            setFilteredDataSource(newData.splice(0, newData.length));
            setSearch(text);
        } else {
            setFilteredDataSource(masterDataSource.slice((pageNumber - 1) * PAGE_SIZE, pageNumber * PAGE_SIZE));
            setSearch(text);
        }
    };

    const handlePageChange = (pageNumber) => {
        setFilteredDataSource(masterDataSource.slice((pageNumber - 1) * PAGE_SIZE, pageNumber * PAGE_SIZE));
        setPageNumber(pageNumber);
    };


    const handleDrawerStateChange = (newState) => {
        setIsDrawerOpen(newState);
    };


    return (
        <SafeAreaView style={{ flex: 1, height: '100vh' }}>
            <View style={styles.container}>

                <Header
                    value={null}
                    onSelect={null}
                    onDrawerStateChange={handleDrawerStateChange}
                    role={currRole}
                    hideDropdown={true}
                />

                <View style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 65px)', width: isDrawerOpen ? 'calc(100vw - 250px)' : 'calc(100vw - 60px)', marginLeft: isDrawerOpen ? '250px' : '60px', overflow: 'auto' }}>


                    <View style={{ display: 'flex', flexDirection: 'row', height: '100%', padding: '1%', width: '100%', }}>
                        <View style={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'column', marginHorizontal: 'auto', }}>
                            <View style={{ boxSizing: 'border-box', minWidth: '0px', maxWidth: '1600px', marginHorizontal: 'auto', height: '100%', paddingVertical: '40px', paddingHorizontal: '20px', flex: '1 1 0%', width: '100%', marginBottom: '5rem' }}>
                                <View style={{ marginBottom: '1rem', minWidth: '0px', display: 'flex', textAlign: 'left', flexWrap: 'wrap', justifyContent: 'space-between', }}>
                                    <Text style={{ marginBottom: '0.5rem', lineHeight: '1.1', fontWeight: '500', fontSize: '1.75rem', }}>Notices</Text>
                                    <Text style={{ marginBottom: '0.6rem', fontWeight: '400', fontSize: '0.875rem', }}>Create, delete and view new and old notices. Notices help share messages across the entire platform to every user logged in.</Text>
                                </View>
                            </View>


                            <View style={{ boxSizing: 'border-box', minWidth: '0px', maxWidth: '1600px', marginBottom: '0px', minWidth: '0px', position: 'relative', paddingBottom: '125px', paddingHorizontal: '20px', marginHorizontal: 'auto', width: '100%' }}>
                                <View style={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '100%', }}>
                                    <View style={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: '0.5rem', paddingBottom: '0.5rem', alignItems: 'center', width: '100%' }}>
                                        <Text style={{ color: 'darkcyan', textDecorationLine: 'underline', float: 'right', }}
                                            onPress={() => Linking.openURL('http://google.com')}>
                                            Download CSV
                                        </Text>
                                    </View>

                                    <NoticesList
                                        filteredDataSource={filteredDataSource}
                                        onFilter={(category, text) => searchFilterFunction(category, text)}
                                        updateFilteredDataSource={updateFilteredDataSource}
                                        updateHeaderFilter={(filter) => updateHeaderFilter(filter)}
                                    />

                                    <View style={{ height: '35px', marginTop: '30px', display: 'flex', flexDirection: 'row', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', }}>
                                        <TouchableOpacity onPress={() => {
                                            if (pageNumber === 2) {
                                                changePage(((pageNumber - 1) * PAGE_SIZE) - 20); setPageNumber((prev) => { return prev - 1 })
                                            } else {
                                                changePage(((pageNumber - 2) * PAGE_SIZE) - 20); setPageNumber((prev) => { return prev - 2 })
                                            }
                                        }} disabled={pageNumber <= 1} style={{ width: 35, justifyContent: 'center', alignContent: 'center', borderRadius: '0.25rem', backgroundColor: pageNumber <= 1 ? 'lightgrey' : 'white', border: '1px solid rgb(233, 236, 239)', }}>


                                            <Feather style={{ marginLeft: 'auto', marginRight: 'auto' }} name="chevrons-left" size={24} color="black" />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => { changePage(((pageNumber - 1) * PAGE_SIZE) - 20); setPageNumber((prev) => { return prev - 1 }) }} disabled={pageNumber <= 1} style={{ width: 35, justifyContent: 'center', alignContent: 'center', borderRadius: '0.25rem', backgroundColor: pageNumber <= 1 ? 'lightgrey' : 'white', border: '1px solid rgb(233, 236, 239)', }}>
                                            <Feather style={{ marginLeft: 'auto', marginRight: 'auto' }} name="chevron-left" size={24} color="black" />
                                        </TouchableOpacity>
                                        {pageNumbers
                                            .filter(page => Math.abs(page - pageNumber) <= 1 && page >= 1 && page <= pageNumbers.length)
                                            .map(page => (
                                                <TouchableOpacity onPress={() => { changePage((page * PAGE_SIZE) - 20); setPageNumber(page) }} disabled={pageNumber === page} key={page} style={{ width: 35, justifyContent: 'center', alignContent: 'center', borderRadius: '0.25rem', backgroundColor: pageNumber === page ? 'lightgrey' : 'white', border: '1px solid rgb(233, 236, 239)', }}>
                                                    <Text style={{ textAlign: 'center', }}>{page}</Text>
                                                </TouchableOpacity>
                                            ))}
                                        <TouchableOpacity onPress={() => { changePage(((pageNumber + 1) * PAGE_SIZE) - 20); setPageNumber((prev) => { return prev + 1 }) }} disabled={pageNumber >= totalPages} style={{ width: 35, justifyContent: 'center', alignContent: 'center', borderRadius: '0.25rem', backgroundColor: pageNumber >= totalPages ? 'lightgrey' : 'white', border: '1px solid rgb(233, 236, 239)', }}>
                                            <Feather style={{ marginLeft: 'auto', marginRight: 'auto' }} name="chevron-right" size={24} color="black" />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => {
                                            if (pageNumber === totalPages - 1) {
                                                changePage(((pageNumber + 1) * PAGE_SIZE) - 20); setPageNumber((prev) => { return prev + 1 })
                                            } else {
                                                changePage(((pageNumber + 2) * PAGE_SIZE) - 20); setPageNumber((prev) => { return prev + 2 })
                                            }

                                        }} disabled={pageNumber >= totalPages} style={{ width: 35, justifyContent: 'center', alignContent: 'center', borderRadius: '0.25rem', backgroundColor: pageNumber >= totalPages ? 'lightgrey' : 'white', border: '1px solid rgb(233, 236, 239)', }}>
                                            <Feather style={{ marginLeft: 'auto', marginRight: 'auto' }} name="chevrons-right" size={24} color="black" />
                                        </TouchableOpacity>
                                    </View>

                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#FBFCFD',
        height: '100%',
    },
    pageSelectorContainer: {
        flexDirection: 'row',
    },
    pageButton: {

    },
    itemStyle: {
        flexDirection: "row",
        alignItems: "center",
        height: '5%',
        flexWrap: 'nowrap',
        width: '100%',
    },
    column: {
        padding: 3,
        overflow: 'hidden',
    },
    textInputStyle: {
        height: '50%',
        marginTop: '1.5%',
        borderWidth: 1,
        paddingLeft: 20,
        marginLeft: '1.5%',
        borderColor: 'black',
        width: '65%',
        backgroundColor: 'white',
    },
    columnSeparator: {
        width: '0.1%',
        height: '100%',
        backgroundColor: 'lightgray',
    },
    itemText: {
        whiteSpace: 'nowrap',
    },
});


export default NoticesScreen;