import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TextInput, Animated, Platform, Touchable } from 'react-native';
import RosterDropdown from '../RosterDropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from '../constants';
import { filter } from 'lodash';
import setupFetchInterceptors from '../../hooks/setupFetchInterceptors';
import { Feather } from '@expo/vector-icons';
import UniversalAlert from '../UniversalAlert';



const LIST_BORDER_COLOR = "#E6E6E6"
const cellWidth = 174.166667;

const RosterList = ({
    filteredDataSource = [],
    onFilter = () => { },
    updateFilteredDataSource = () => { },
    updateHeaderFilter = () => { },
    hidingArchived = {},
    salesFilter = "Today",
    onChangeSalesFilter = {},
}) => {

    const [totalSalesMap, setTotalSalesMap] = useState({});
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);


    setupFetchInterceptors()



    const calculateTotalSales = (filter, bookings) => {

        // Set the start date based on the filter
        const currentDate = new Date();
        let startDate;
        if (filter === 'Today') {
            startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        } else if (filter === 'Week') {
            startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
        } else if (filter === 'Month') {
            startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        } else if (filter === 'Year') {
            startDate = new Date(currentDate.getFullYear(), 0, 1);
        } else {
            // Invalid filter
            return 0;
        }


        // Calculate the total sales amount
        let totalSales = 0;
        bookings.forEach(booking => {
            const saleDate = new Date(booking.sale_date);
            if (saleDate >= startDate && saleDate <= currentDate) {
                const bookingStartDate = new Date(booking.start_date);
                const bookingEndDate = new Date(booking.end_date);
                const bookingDays = Math.ceil((bookingEndDate - bookingStartDate) / (1000 * 60 * 60 * 24)); // Calculate the duration of the booking in days
                const bookingTotalSales = booking.booking_items.reduce((acc, item) => {
                    // Assuming each item has a "quantity" and a "product_id", and there's a global product price lookup
                    const productPrice = (item.product.price);
                    return acc + productPrice * item.quantity;
                }, 0);
                totalSales += bookingTotalSales * bookingDays;
            }
        });
        console.log(totalSales)
        return totalSales;
    };

    useEffect(() => {
        const calculateTotalSalesForEmployees = () => {
            const salesMap = {};
            filteredDataSource.forEach(item => {
                const totalSales = calculateTotalSales(salesFilter, item.bookings);
                salesMap[item.employee_id] = totalSales;
            });
            console.log(salesMap)
            setTotalSalesMap(salesMap);
        };

        calculateTotalSalesForEmployees();
    }, [salesFilter, filteredDataSource]);

    // set data to storage
    async function setData(key, value) {
        try {
            await AsyncStorage.setItem(key, value);
        } catch (error) {
            console.log(error);
        }
    }

    // get data from storage
    async function getData(key, defaultValue = null) {
        try {
            const value = await AsyncStorage.getItem(key);

            if (value !== null) {
                return value;
            } else {
                return defaultValue;
            }
        } catch (error) {
            console.log(error);
            return defaultValue;
        }
    }

    const updateRole = async (uid, new_role, existing_role) => {

        if (new_role !== existing_role) {
            const payload = {
                role: new_role
            }
            fetch(`${API_URL}/employee/` + uid, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'http://localhost:19006',
                    'Access-Control-Request-Method': 'PUT',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                },
                body: JSON.stringify(payload),
            })
                .then(async res => {
                    try {
                        const jsonRes = await res.json();
                        if (res.status !== 200) {
                            console.log(res.statusText)
                        } else {
                            if (await getData('user_id') == uid) {
                                await setData('role', new_role);
                                window.location.reload()
                            }
                        }
                    } catch (err) {
                        //setMessage(err);
                        console.log(err);
                    };
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    const updateActive = async (uid, active) => {

        const payload = {
            active
        }
        fetch(`${API_URL}/employee/` + uid, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'PUT',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            body: JSON.stringify(payload),
        })
            .then(async res => {
                try {
                    const jsonRes = await res.json();
                    if (res.status !== 200) {
                        console.log(res.statusText)
                    } else {
                        window.location.reload()
                    }
                } catch (err) {
                    //setMessage(err);
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            })
    }

    const updateEmail = async (uid, new_email) => {

        var email = new_email.nativeEvent.text

        const payload = {
            email
        }
        fetch(`${API_URL}/employee/` + uid, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'PUT',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            body: JSON.stringify(payload),
        })
            .then(async res => {
                try {
                    const jsonRes = await res.json();
                    if (res.status !== 200) {
                        console.log(res.statusText)
                    } else {
                        console.log("Success");
                    }
                } catch (err) {
                    //setMessage(err);
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            })

    }

    const updateName = async (uid, new_name) => {

        var name = new_name.nativeEvent.text

        const payload = {
            name
        }
        fetch(`${API_URL}/employee/` + uid, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'PUT',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            body: JSON.stringify(payload),
        })
            .then(async res => {
                try {
                    const jsonRes = await res.json();
                    if (res.status !== 200) {
                        console.log(res.statusText)
                    } else {
                        console.log("Success");
                        console.log(jsonRes)
                        if (await getData('user_id') == uid) {
                            await setData('name', name);
                            window.location.reload();
                        }
                    }
                } catch (err) {
                    //setMessage(err);
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            })
    }

    const deleteUser = async (uid) => {
        //
        if (await getData('user_id') == uid) {
            //spawn banner with failed message
            return;
        }

        fetch(`${API_URL}/employee/` + uid, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'DELETE',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
        })
            .then(async res => {
                try {
                    if (res.status !== 204) {
                        //spawn banner with failed message
                        console.log(res.statusText)
                    } else {
                        console.log("Success");
                        //spawn banner with success message
                        window.location.reload();
                    }
                } catch (err) {
                    //setMessage(err);
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            })
    }



    const renderHeader = () => {

        const headerContent = [];
        headerContent.push(

            <View style={{ verticalAlign: 'bottom', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>ID</Text>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Name</Text>
                    </View>
                    <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', position: 'relative', width: '100%' }}>
                        <TextInput onChangeText={(text) => onFilter("name", text)} style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', appearance: 'none', borderImage: 'initial', display: 'block', height: 'auto', paddingRight: '0.5rem', paddingLeft: '0.5rem', paddingTop: '0.75rem', paddingBottom: '0.75rem', fontSize: '12px', fontWeight: 'normal', lineHeight: '0.75', backgroundColor: 'red', color: 'black', backgroundColor: 'white', backgroundClip: 'padding-box', border: '1px solid ' + LIST_BORDER_COLOR, borderRadius: '0.2rem', width: '100%', }} placeholder={"Search by Name"}></TextInput>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Email</Text>
                    </View>
                    <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', position: 'relative', width: '100%' }}>
                        <TextInput onChangeText={(text) => onFilter("email", text)} style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', appearance: 'none', borderImage: 'initial', display: 'block', height: 'auto', paddingRight: '0.5rem', paddingLeft: '0.5rem', paddingTop: '0.75rem', paddingBottom: '0.75rem', fontSize: '12px', fontWeight: 'normal', lineHeight: '0.75', backgroundColor: 'red', color: 'black', backgroundColor: 'white', backgroundClip: 'padding-box', border: '1px solid ' + LIST_BORDER_COLOR, borderRadius: '0.2rem', width: '100%', }} placeholder={"Search by Email"}></TextInput>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Role</Text>
                    </View>
                    <View style={{ width: '100%', height: '100%', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <Animated.View style={{ width: '75%', height: '30px', position: 'relative', }}>
                            <RosterDropdown
                                header={true}
                                textStyle={styles.dropdownTextStyle}
                                onFilter={(selection) => {
                                    onFilter("role", selection)
                                }}
                                type={"role"}
                            />
                        </Animated.View>
                    </View>
                </View>
                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Total Sales</Text>
                    </View>
                    <View style={{ width: '100%', height: '100%', alignItems: 'center', flex: '1', justifyContent: 'center' }}>
                        <Animated.View style={{ width: '75%', height: '30px', position: 'relative', }}>
                            <RosterDropdown
                                textStyle={styles.dropdownTextStyle}
                                onFilter={(selection) => {
                                    onChangeSalesFilter(selection);
                                }}
                                type={"sale filter"}
                            />
                        </Animated.View>
                    </View>
                </View>

                <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderTop: '1px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', height: '100%' }}>
                    <View style={{ boxSizing: 'border-box', marginBottom: '10px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                        <Text style={styles.headerItemText}>Active</Text>
                    </View>
                </View>
            </View>
        )
        return (
            headerContent
        );
    }

    const renderContent = () => {

        return (
            filteredDataSource.map((item, index) => (
                ((hidingArchived && item.active) || (!hidingArchived)) &&

                <View key={item.employee_id} style={{ zIndex: -1 - index, verticalAlign: 'bottom', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: index % 2 != 0 ? '#F3F3F3' : 'FFFFFF' }}>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>{item.employee_id}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', position: 'relative', width: '100%' }}>
                            {/* <TextInput onBlur={(name) => {updateName(item.id, name, item.name);}} style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', appearance: 'none', borderImage: 'initial', display: 'block', height: 'auto', paddingRight: '0.5rem', paddingLeft: '0.5rem', paddingTop: '0.75rem', paddingBottom: '0.75rem', fontSize: '12px', fontWeight: 'normal', lineHeight: '0.75', backgroundColor: 'red', color: 'black', backgroundColor: 'white', backgroundClip: 'padding-box', border: '1px solid ' + LIST_BORDER_COLOR, borderRadius: '0.2rem', width: '100%', }} value={item.name} onChangeText={(text) => {item.name = text}}></TextInput> */}
                            {/* <TextInput onBlur={(name) => {updateName(item.id, name, item.name);}} style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', appearance: 'none', borderImage: 'initial', display: 'block', height: 'auto', paddingRight: '0.5rem', paddingLeft: '0.5rem', paddingTop: '0.75rem', paddingBottom: '0.75rem', fontSize: '12px', fontWeight: 'normal', lineHeight: '0.75', backgroundColor: 'red', color: 'black', backgroundColor: 'white', backgroundClip: 'padding-box', border: '1px solid ' + LIST_BORDER_COLOR, borderRadius: '0.2rem', width: '100%', }} defaultValue={item.name} placeholder={item.name} onChangeText={(text) => {item.name = text}}></TextInput> */}

                            <TextInput
                                // Use the value prop to bind the text input to the filtered data
                                value={item.name}
                                // onChangeText should update the state when the text changes
                                onChangeText={(text) => {

                                    // Create a copy of the data to avoid mutations
                                    const updatedData = filteredDataSource.map((dataItem) => {
                                        if (dataItem.employee_id === item.employee_id) {
                                            // Update the name for the specific item
                                            return { ...dataItem, name: text };
                                        }
                                        return dataItem;
                                    });
                                    // Update the filtered data state
                                    updateFilteredDataSource(updatedData);
                                }}

                                onBlur={(new_name) => { updateName(item.employee_id, new_name) }}

                                style={{
                                    boxSizing: 'border-box',
                                    margin: '0px',
                                    minWidth: '0px',
                                    appearance: 'none',
                                    borderImage: 'initial',
                                    display: 'block',
                                    height: 'auto',
                                    paddingRight: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.75rem',
                                    paddingBottom: '0.75rem',
                                    fontSize: '12px',
                                    fontWeight: 'normal',
                                    lineHeight: '0.75',
                                    backgroundColor: 'white', // Fix the background color
                                    color: 'black',
                                    backgroundClip: 'padding-box',
                                    border: '1px solid ' + LIST_BORDER_COLOR,
                                    borderRadius: '0.2rem',
                                    width: '100%',
                                }}
                                placeholder="Name" // Set the placeholder text here
                            />


                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', position: 'relative', width: '100%' }}>
                                <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', position: 'relative', width: '100%' }}>

                                    {/* <TextInput onBlur={(value) => finishEditing(value)} defaultValue={currentNotes} onChangeText={setCurrentNotes} multiline style={{ height: '100%', width: '100%', color: 'black', marginTop: '0.1%', fontStyle: 'italic', fontSize: '10px', padding: '0.5%', wordWrap: 'break-word' }} ></TextInput> */}
                                    {/* <TextInput onBlur={(email) => updateEmail(item.id, email, item.email)} style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', appearance: 'none', borderImage: 'initial', display: 'block', height: 'auto', paddingRight: '0.5rem', paddingLeft: '0.5rem', paddingTop: '0.75rem', paddingBottom: '0.75rem', fontSize: '12px', fontWeight: 'normal', lineHeight: '0.75', backgroundColor: 'red', color: 'black', backgroundColor: 'white', backgroundClip: 'padding-box', border: '1px solid ' + LIST_BORDER_COLOR, borderRadius: '0.2rem', width: '100%', }} value={item.email} onChangeText={(text) => item.email = text}></TextInput> */}

                                    <TextInput
                                        // Use the value prop to bind the text input to the filtered data
                                        value={item.email}
                                        // onChangeText should update the state when the text changes
                                        onChangeText={(text) => {

                                            // Create a copy of the data to avoid mutations
                                            const updatedData = filteredDataSource.map((dataItem) => {
                                                if (dataItem.employee_id === item.employee_id) {
                                                    // Update the name for the specific item
                                                    return { ...dataItem, email: text };
                                                }
                                                return dataItem;
                                            });
                                            // Update the filtered data state
                                            updateFilteredDataSource(updatedData);
                                        }}

                                        onBlur={(new_email) => { updateEmail(item.employee_id, new_email) }}

                                        style={{
                                            boxSizing: 'border-box',
                                            margin: '0px',
                                            minWidth: '0px',
                                            appearance: 'none',
                                            borderImage: 'initial',
                                            display: 'block',
                                            height: 'auto',
                                            paddingRight: '0.5rem',
                                            paddingLeft: '0.5rem',
                                            paddingTop: '0.75rem',
                                            paddingBottom: '0.75rem',
                                            fontSize: '12px',
                                            fontWeight: 'normal',
                                            lineHeight: '0.75',
                                            backgroundColor: 'white', // Fix the background color
                                            color: 'black',
                                            backgroundClip: 'padding-box',
                                            border: '1px solid ' + LIST_BORDER_COLOR,
                                            borderRadius: '0.2rem',
                                            width: '100%',
                                        }}
                                        placeholder="Email" // Set the placeholder text here
                                    />

                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ width: '100%', height: '100%', alignItems: 'center', flex: '1', justifyContent: 'center', zIndex: -1 }}>
                            <Animated.View style={{ width: '75%', height: '30px', position: 'relative', }}>
                                <RosterDropdown
                                    header={false}
                                    type={"role filter"}
                                    value={item.role}
                                    userId={item.employee_id}
                                    onSelect={(user_id, role) => updateRole(user_id, role, item.role)}
                                    onValueChange={(newValue) => {
                                        const updatedData = filteredDataSource.map((dataItem) => {
                                            if (dataItem.employee_id === item.employee_id) {
                                                return { ...dataItem, role: newValue };
                                            }
                                            return dataItem;
                                        });
                                        updateFilteredDataSource(updatedData);
                                    }}
                                />

                            </Animated.View>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderRight: '2px solid ' + LIST_BORDER_COLOR, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                            <Text style={styles.itemText}>${totalSalesMap[item.employee_id] != undefined ? totalSalesMap[item.employee_id].toLocaleString() : 0}</Text>
                        </View>
                    </View>
                    <View style={{ minWidth: cellWidth, maxWidth: cellWidth, borderTop: '2px solid ' + LIST_BORDER_COLOR, marginTop: '0px', fontWeight: 'bold', fontSize: '0.875rem', padding: '0.75rem', verticalAlign: 'top', flexGrow: '1', minHeight: '100%', maxHeight: '100%' }}>
                        <View style={{ boxSizing: 'border-box', margin: '0px', minWidth: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', height: '100%' }}>
                            {item.active &&
                                <TouchableOpacity onPress={() => updateActive(item.employee_id, false)} style={{ boxSizing: 'border-box', borderRadius: '18px', height: '16px', width: '1.75rem', padding: '2px', position: 'relative', pointerEvents: 'all', borderWidth: '1px', borderStyle: 'solid', borderColor: 'darkgrey', backgroundColor: "black", alignItems: 'center', marginRight: '10px' }}>
                                    <View style={{ height: '12px', width: '12px', position: 'absolute', top: '1px', right: '1px', background: 'lightgrey', borderWidth: '0.5px', borderStyle: 'solid', borderColor: 'grey', borderRadius: '50%', alignItems: 'center' }} />
                                </TouchableOpacity>
                            }
                            {!item.active &&
                                <TouchableOpacity onPress={() => updateActive(item.employee_id, true)} style={{ boxSizing: 'border-box', borderRadius: '18px', height: '16px', width: '1.75rem', padding: '2px', position: 'relative', pointerEvents: 'all', borderWidth: '1px', borderStyle: 'solid', borderColor: 'darkgrey', backgroundColor: "rgb(221, 221, 221, 1)", alignItems: 'center', marginRight: '10px' }}>
                                    <View style={{ height: '12px', width: '12px', position: 'absolute', top: '1px', left: '1px', background: 'lightgrey', borderWidth: '0.5px', borderStyle: 'solid', borderColor: 'grey', borderRadius: '50%', alignItems: 'center' }} />
                                </TouchableOpacity>
                            }
                            <TouchableOpacity onPress={() => { setDeleteModalVisible(true); setUserToDelete(item.employee_id) }}>
                                <Feather name={'trash-2'} size={20} color={'darkred'} style={{}} />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            )));
    }


    return (
        <View style={{ width: '100%', marginBottom: '1rem', color: 'black', backgroundColor: 'white', borderCollapse: 'collapse', borderRight: '1px solid ' + LIST_BORDER_COLOR, borderLeft: '1px solid ' + LIST_BORDER_COLOR, borderBottom: '1px solid ' + LIST_BORDER_COLOR, }}>
            {userToDelete && <UniversalAlert
                isVisible={deleteModalVisible}
                message={"Permanently delete user"}
                onOK={() => { deleteUser(userToDelete) }}
                onCancel={() => { setDeleteModalVisible(false); setUserToDelete(null) }}
            />}
            {renderHeader()}
            {renderContent()}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        height: '91.5%',
    },
    itemStyle: {
        flexDirection: "row",
        alignItems: "center",
        height: '5%',
        flexWrap: 'nowrap',
        width: '100%',
    },
    column: {
        padding: 3,
        overflow: 'hidden',
    },
    textInputStyle: {
        height: '100%',
        borderWidth: 1,
        paddingLeft: 20,
        margin: 2.5,
        borderColor: '#009688',
        width: '99%',
    },
    columnSeparator: {
        width: '0.1%',
        height: '100%',
        backgroundColor: 'lightgray',
        borderBottom: '2px solid ' + LIST_BORDER_COLOR,
    },
    headerItemText: {
        whiteSpace: 'nowrap',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    itemText: {
        whiteSpace: 'nowrap',
        width: '100%',
    },
    dropdownTextStyle: {
        height: '300pt',
        backgroundColor: 'lightgray',
        borderRadius: 2,
        overflow: 'auto',
    },
});

export default RosterList;