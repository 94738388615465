import React, { useState, } from 'react';
import { View, Text, TouchableOpacity, TextInput, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { Feather } from '@expo/vector-icons';


const EditNotes = ({ isVisible, onCancel, booking, onUpdate, onOk }) => {
    const [notes, setNotes] = useState(booking != null ? booking.notes : '');

    console.log(booking)
  

    return (
        <Modal
            animationType="slide"
            transparent={true}
            isVisible={isVisible}
            onRequestClose={onCancel}
            backdropOpacity={0.375}
            style={{ margin: 0 }}
        >
            <View style={styles.modalContainer}>
                <View style={styles.alertBox}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
                        <Text style={styles.title}>Edit Notes</Text>
                        <TouchableOpacity onPress={() => {onCancel(); setNotes(booking.notes);}}>
                            <Feather name="x-circle" size={24} color="black" />
                        </TouchableOpacity>
                    </View>
                    <Text>Notes</Text>
                    <TextInput
                        style={[styles.input, styles.textArea]}
                        placeholder="Add notes here..."
                        value={notes}
                        onChangeText={(new_notes) => {onUpdate(new_notes); setNotes(new_notes);}}
                        multiline={true}
                        numberOfLines={4}
                    />
                    <View style={styles.buttonContainer}>

                        <TouchableOpacity onPress={() => {onCancel(); setNotes(booking.notes);}} style={styles.cancelButton}>
                            <Text>Cancel</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => {onOk(notes);}} style={[styles.confirmButton, {opacity: notes != '' ? 1 : 0.5}]} disabled={notes == ''}>
                            <Text style={{ color: 'white' }}>Update Notes</Text>
                        </TouchableOpacity>

                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        // backgroundColor: 'red'
    },
    alertBox: {
        backgroundColor: 'white',
        padding: 30,
        borderRadius: '0.25rem',
        elevation: 5,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 4,
        marginTop: '50px',
        width: '600px',
        overflowX: 'auto'
        // maxHeight: '100%',
    },
    title: {
        fontSize: 22,
        marginBottom: 20,
        fontWeight: 500,
        textAlign: 'center',
    },
    input: {
        height: 40,
        width: '100%',
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: 5,
        paddingHorizontal: 10,
        marginBottom: '20px',
        marginTop: 10,
    },
    inputDate: {
        height: 40,
        width: '99%',
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: 5,
        paddingHorizontal: 10,
        marginBottom: '20px',
        marginTop: 10,
    },
    textArea: {
        height: '275px',
        textAlignVertical: 'top',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    confirmButton: {
        flex: 1,
        height: 40,
        marginLeft: 5,
        backgroundColor: 'blue',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cancelButton: {
        flex: 1,
        height: 40,
        marginRight: 5,
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: 10,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default EditNotes;
