import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';


const ProtectedRoute = () => {
    return (
        
            <Outlet />
    )
}

export default ProtectedRoute;