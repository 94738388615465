import React, { useState, useEffect, forceUpdate } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ImageBackground, View, Text, StyleSheet, TouchableOpacity, TextInput, Platform, Image, Button, Animated, Modal } from 'react-native';
import Header from '../../components/Header';
import Calendar from '../../components/Calendars/CalendarSingle';
import { useNavigate } from 'react-router-dom';
import CustomMap from '../../components/CustomMap';
import { API_URL } from '../../components/constants';
import { Feather } from '@expo/vector-icons';
import EditNotes from '../../components/NotesModal';
import useFetchInterceptors from '../../hooks/useFetchInterceptors';
import setupFetchInterceptors from '../../hooks/setupFetchInterceptors';


const LocationScreen = ({ navigation }) => {

    //hook initialization
    const [selectedItem, setSelectedItem] = useState(null);
    const [bookings, updateBookings] = useState([]);
    const [currentBooking, setCurrentBooking] = useState(null);
    const [bookingSelected, setBookingSelected] = useState(false);
    const [editingNotes, setEditingNotes] = useState(false);
    const [currentNotes, setCurrentNotes] = useState('');
    const [globalOpacity, setGlobalOpacity] = useState('1');
    const [currentPrice, setCurrentPrice] = useState(0);
    const [currRole, setCurrRole] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isSelectingDate, setIsSelectingDate] = useState(false);

    let d = new Date()
    d.setHours(0, 0, 0, 0)

    const [daySelected, setDaySelected] = useState(d);
    const [modalVisible, setModalVisible] = useState(false);

    const [isInfoExpanded, setIsInfoExpanded] = useState(true);

    const [updatingNotes, setUpdatingNotes] = useState(false);

    setupFetchInterceptors()


    //Box list initialization
    let selection = [{ id: 1, name: 'Box 1' }, { id: 2, name: 'Box 2' }, { id: 3, name: 'Box 3' }, { id: 4, name: 'Box 4' }, { id: 5, name: 'Box 4B' }, { id: 6, name: 'Box 5' }, { id: 7, name: 'Box 5B' }, { id: 8, name: 'Box 6' }, { id: 9, name: 'Box 6B' }, { id: 10, name: 'Box 7' }, { id: 11, name: 'Box 8' }, { id: 12, name: 'Box 9' }, { id: 13, name: 'Box 10' }, { id: 14, name: 'Box 10B' }, { id: 15, name: 'Box 11' }, { id: 16, name: 'Box 12' }, { id: 17, name: 'Box 12B' }, { id: 18, name: 'Box 13' }, { id: 19, name: 'Box 14' }, { id: 20, name: 'Box 15' }, { id: 21, name: 'Box 15B' }, { id: 22, name: 'Box 16' }, { id: 23, name: 'Box 17' }, { id: 24, name: 'Box 18' }, { id: 25, name: 'Box 19' }, { id: 26, name: 'Box 20' }, { id: 27, name: 'Box 21' }, { id: 28, name: 'Box 22' }, { id: 29, name: 'Box 22B' }, { id: 30, name: 'Box 23' }, { id: 31, name: 'Box 24' }, { id: 32, name: 'Box 25' }, { id: 33, name: 'Box 26' }, { id: 34, name: 'Box 27' }, { id: 35, name: 'Box 28' }, { id: 36, name: 'Box 29' }, { id: 37, name: 'Box 30' }, { id: 38, name: 'Box 31' }, { id: 39, name: 'Box 32' }, { id: 40, name: 'Box 33' }, { id: 41, name: 'Box 34' }, { id: 42, name: 'Box 35' }, { id: 43, name: 'Box 36' }, { id: 44, name: 'Box 37' }, { id: 45, name: 'Box 37B' }, { id: 46, name: 'Box 38' }, { id: 47, name: 'Box 39' }, { id: 48, name: 'Box 40' }, { id: 49, name: 'Box 40B' }, { id: 50, name: 'Box 41' }, { id: 51, name: 'Box 42' }, { id: 52, name: 'Box 43' }, { id: 53, name: 'Box 44' }, { id: 54, name: 'Box 45' }, { id: 55, name: 'Box 46' }, { id: 56, name: 'Box 46B' }]

    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    // set data to storage
    async function setData(key, value) {
        try {
            await AsyncStorage.setItem(key, value);
        } catch (error) {
            console.log(error);
        }
    }

    // get data from storage
    async function getData(key, defaultValue = null) {
        try {
            const value = await AsyncStorage.getItem(key);

            if (value !== null) {
                return value;
            } else {
                return defaultValue;
            }
        } catch (error) {
            console.log(error);
            return defaultValue;
        }
    }


    //populate left side of screen with bookings
    const populateBooking = (booking) => {
        setBookingSelected(true)
        setCurrentBooking(booking)
        setCurrentNotes(booking.notes)
        // Calculate total price
        const totalPrice = booking.booking_items.reduce((total, item) => {
            // Assuming item.product.price and item.quantity are valid properties
            return total + (item.product.price * item.quantity);
        }, 0);

        setCurrentPrice(totalPrice);
    }

    const updateDatesInArray = (arr) => {
        return arr.map((item) => {
            const updatedItem = { ...item }; // Create a shallow copy of the object

            if (updatedItem.start_date) {
                const startDate = new Date(updatedItem.start_date);
                startDate.setHours(startDate.getHours());
                updatedItem.start_date = startDate.toLocaleString(); // Convert to a formatted string
            }

            if (updatedItem.end_date) {
                const endDate = new Date(updatedItem.end_date);
                endDate.setHours(endDate.getHours());
                updatedItem.end_date = endDate.toLocaleString(); // Convert to a formatted string
            }

            return updatedItem;
        });
    };

    //check if an array already contains an id...
    const getBookings = (b) => {
        if (b.length > 0) {
            // If the array is not empty, update the dates
            const updatedBookings = updateDatesInArray(b);
            updateBookings(updatedBookings);
        } else {
            // If the array is empty, set it directly
            updateBookings(b);
        }
    }

    const toggleEditing = () => {
        setEditingNotes(!editingNotes)
        if (globalOpacity == '0.5') {
            setGlobalOpacity('1')
        } else {
            setGlobalOpacity('0.5')
        }

    }

    const finishEditing = (newNotes) => {
        // if (editingNotes) {
        //     toggleEditing()
        // }
        // else {
        //     return;
        // }
        // if (typeof (newNotes) == 'undefined') {
        //     return;
        // }

        //update notes in database...................

        var notes = newNotes
        var sale_id = currentBooking.booking_id

        const payload = {
            notes
        }
        if (notes.length < 4000) {
            // fetch(`${API_URL}/update_notes`, {
                console.log(sale_id)
                console.log(`${API_URL}/bookings/` + sale_id + "/notes")
            fetch(`${API_URL}/bookings/` + sale_id + "/notes", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'https://www.jacobawebb.com',
                    'Access-Control-Request-Method': 'PUT',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                },
                body: JSON.stringify(payload),
            })
                .then(async res => {
                    try {
                        const jsonRes = await res.json();
                        if (res.status !== 200) {
                        } else {
                            onSelect(JSON.parse(await getData('boxName')))
                            setUpdatingNotes(false);
                        }
                    } catch (err) {
                        //setMessage(err);
                        console.log(err);
                    };
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    const convertDate = (sqlDate) => {
        let splitDate = sqlDate.split("-")
        let newDate = Array()
        newDate.push(splitDate[0])
        newDate.push(splitDate[1])

        let dayNotFormatted = splitDate[2]
        let day = dayNotFormatted.split("T")[0]

        newDate.push(day)

        return newDate;
    }

    React.useEffect(() => {

        const getDate = async () => {
            const dateFromStorage = await getData('date_selected');
            let parsedDate;
            if(dateFromStorage){
                parsedDate = new Date(dateFromStorage)
            }else{
                let date_temp = new Date()
                date_temp.setHours(0, 0, 0, 0)
                parsedDate = date_temp
            }
            
            setDaySelected(parsedDate);
            console.log(parsedDate)

            const func = async () => {
                if (await getData('boxName') !== null) {
                    //    refreshing the drop down value if possible
                    var jsonString = await getData('boxName')
                    var currBox = JSON.parse(jsonString)
                    if (currBox.name) {
                        // onSelect(currBox)
                        await setData('boxName', JSON.stringify(currBox))
                        setSelectedItem(currBox)

                        let pad = function (num) { return ('00' + num).slice(-2) };
                        console.log(parsedDate)
                        let testDate = parsedDate.getFullYear() + '-' +
                            pad(parsedDate.getMonth() + 1) + '-' +
                            pad(parsedDate.getDate()) + ' ' +
                            pad(parsedDate.getHours()) + ':' +
                            pad(parsedDate.getMinutes()) + ':' +
                            pad(parsedDate.getSeconds());
                            console.log(testDate)
                        //bookings.splice(0, bookings.length)
                        // fetching data from SQL database using local API
                        // fetch(`${API_URL}/get_bookings_date`, {
                        fetch(`${API_URL}/bookings/` + currBox.id + '?date=' + testDate, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Origin': 'https://www.jacobawebb.com',
                                'Access-Control-Request-Method': 'GET',
                                'Access-Control-Request-Headers': 'Content-Type, Authorization',
                            },
                            // body: JSON.stringify({
                            //     box_id: currBox.id,
                            //     date: testDate,
                            //     // date: daySelected
                            // }),
                        })
                            .then(async res => {
                                try {

                                    if (res.status == 204) {
                                        const jsonRes = await res.json();
                                        console.log(jsonRes.bookings)
                                        getBookings([])
                                    } else if (res.status === 200){
                                        const jsonRes = await res.json();
                                        console.log(jsonRes.bookings)
                                        getBookings(jsonRes.bookings)
                                    } else {
                                        console.log(res.status)
                                        getBookings([])
                                    }
                                } catch (err) {
                                    console.log(err);
                                };
                            })
                            .catch(err => {
                                console.log(err);
                            });
                    }
                }
            };


            func();


        };

        getDate();
        // getBoxInfo();


        // onSelectDate(daySelected);

        const getRole = async () => {
            setCurrRole(await getData('role'));
        };

        getRole();


        // Return the function to unsubscribe from the event so it gets removed on unmount
    }, []);

    const onSelect = async (item) => {
        await setData('boxName', JSON.stringify(item))
        setSelectedItem(item)
        updateBookings([])
        let bookings = []

        console.log(daySelected)
        let pad = function (num) { return ('00' + num).slice(-2) };
        let testDate = daySelected.getUTCFullYear() + '-' +
            pad(daySelected.getUTCMonth() + 1) + '-' +
            pad(daySelected.getUTCDate()) + ' ' +
            pad(daySelected.getUTCHours()) + ':' +
            pad(daySelected.getUTCMinutes()) + ':' +
            pad(daySelected.getUTCSeconds());
        //bookings.splice(0, bookings.length)

        // fetching data from SQL database using local API
        // fetch(`${API_URL}/get_bookings_date`, {
        console.log(testDate)
        fetch(`${API_URL}/bookings/` + item.id + '?date=' + testDate, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'https://www.jacobawebb.com',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            // body: JSON.stringify({
            //     box_id: item.id,
            //     date: testDate,
            //     // date: daySelected
            // }),
        })
            .then(async res => {
                try {
                    if (res.status !== 200) {
                        getBookings([])
                    } else {
                        const jsonRes = await res.json();
                        getBookings(jsonRes.bookings)
                        console.log(jsonRes)
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleDrawerStateChange = (newState) => {
        setIsDrawerOpen(newState);
    };

    const placeBooking = () => {
        setModalVisible(true);
    };

    const onSelectDate = async (new_date) => {
        if (await getData('boxName') !== null) {
            //    refreshing the drop down value if possible
            var jsonString = await getData('boxName')
            var currBox = JSON.parse(jsonString)
            if (currBox.name) {
                await setData('date_selected', new_date)
                setDaySelected(new_date);
                setIsSelectingDate(false);

                console.log(new_date)
                let pad = function (num) { return ('00' + num).slice(-2) };
                let testDate = new_date.getUTCFullYear() + '-' +
                    pad(new_date.getUTCMonth() + 1) + '-' +
                    pad(new_date.getUTCDate()) + ' ' +
                    pad(new_date.getUTCHours()) + ':' +
                    pad(new_date.getUTCMinutes()) + ':' +
                    pad(new_date.getUTCSeconds());
                //bookings.splice(0, bookings.length)
                // fetching data from SQL database using local API
                // fetch(`${API_URL}/get_bookings_date`, {
                fetch(`${API_URL}/bookings/` + currBox.id + '?date=' + testDate, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Origin': 'https://www.jacobawebb.com',
                        'Access-Control-Request-Method': 'GET',
                        'Access-Control-Request-Headers': 'Content-Type, Authorization',
                    },
                    // body: JSON.stringify({
                    //     box_id: currBox.id,
                    //     date: testDate,
                    //     // date: daySelected
                    // }),
                })
                    .then(async res => {
                        try {


                            if (res.status !== 200) {
                                console.log(res.status)
                                getBookings([])
                            } else {
                                const jsonRes = await res.json();
                                console.log(jsonRes)
                                getBookings(jsonRes.bookings)
                            }
                        } catch (err) {
                            console.log(err);
                        };
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }
    }

    return (
        <View style={styles.back}>
            {updatingNotes && <EditNotes
                isVisible={updatingNotes}
                onCancel={() => { setUpdatingNotes(false); setCurrentNotes(currentBooking.notes) }}
                booking={currentBooking}
                onUpdate={(new_notes) => {setCurrentNotes(new_notes)}}
                onOk={(new_notes) => finishEditing(new_notes)}
            />}
            <Header
                value={selectedItem}
                onSelect={onSelect}
                onDrawerStateChange={handleDrawerStateChange}
                role={currRole}
                currentPage={'Bookings'}
            />

            <View style={{ width: '100%', display: 'flex', flexDirection: 'row', opacity: globalOpacity, height: 'calc(100vh - 65px)', width: isDrawerOpen ? 'calc(100vw - 250px)' : 'calc(100vw - 60px)', marginLeft: isDrawerOpen ? '250px' : '60px', }} >

                <View style={{ backgroundColor: '#FBFCFD', flex: isInfoExpanded ? '0.3' : '0', height: 'auto', maxHeight: '100%', overflow: 'auto', borderRightColor: '#ddd', borderRightWidth: '1pt' }} >
                    <Text style={styles.heading}>Booking Info</Text>
                    <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt' }} />

                    {bookingSelected &&
                        <View>
                            <Text style={{ color: 'black', marginTop: '2.5%', paddingLeft: '5%' }} >{currentBooking.customer.name}</Text>
                            <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt', marginTop: '2.5%' }} />

                            <Text style={{ fontSize: 17, color: 'black', marginTop: '2.5%', paddingLeft: '5%', fontStyle: 'italic', }} >Order Number: {currentBooking.booking_id}</Text>
                            <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt', marginTop: '2.5%' }} />

                            {/* <Text style={{ color: 'lightgray', marginTop: '2.5%', paddingLeft: '5%', fontSize: '10pt', paddingRight: '5%' }} >Delivery Address</Text>
                            <Text style={{ color: 'lightgray', marginTop: '0.1%', paddingLeft: '5%', fontStyle: 'italic', fontSize: '10pt', paddingRight: '5%' }} >undefined undefined, North Myrtle Beach, South Carolina, 29582</Text>
                            <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt', marginTop: '2.5%' }} /> */}

                            <Text style={{ fontSize: 17, color: 'black', marginTop: '2.5%', paddingLeft: '5%', fontSize: '10pt', paddingRight: '5%' }} >Total</Text>
                            <Text style={{ fontSize: 17, color: 'black', marginTop: '0.1%', paddingLeft: '5%', fontStyle: 'italic', paddingRight: '5%' }} >${currentPrice}</Text>
                            <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt', marginTop: '2.5%' }} />

                            <Text style={{ fontSize: 17, color: 'black', marginTop: '2.5%', paddingLeft: '5%', fontSize: '10pt', paddingRight: '5%' }} >Date</Text>
                            <Text style={{ fontSize: 17, color: 'black', marginTop: '0.1%', paddingLeft: '5%', fontStyle: 'italic', paddingRight: '5%' }} >{(currentBooking.start_date)} - {(currentBooking.end_date)}</Text>
                            <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt', marginTop: '2.5%' }} />

                            <Text style={{ fontSize: 17, color: 'black', marginTop: '2.5%', paddingLeft: '5%', fontSize: '10pt', paddingRight: '5%' }} >Payment Method</Text>
                            <Text style={{ fontSize: 17, color: 'black', marginTop: '0.1%', paddingLeft: '5%', fontStyle: 'italic', paddingRight: '5%' }} >Card</Text>
                            <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt', marginTop: '2.5%' }} />



                            {/* notes section of booking details */}
                            <Text style={{ fontSize: 17, color: 'black', marginTop: '2.5%', paddingLeft: '5%', paddingRight: '5%' }} >Notes</Text>

                            <View style={{ backgroundColor: 'lightgrey', marginLeft: '4.75%', marginRight: '4.75%', marginBottom: '1%', marginTop: '0.25%' }}>
                                {currentNotes != '' &&
                                    <Text style={{ fontSize: 17, color: 'black', marginTop: '0.1%', paddingLeft: '1%', fontStyle: 'italic', paddingRight: '1%' }} >{currentNotes}</Text>
                                }
                            </View>
                            <Text style={{ fontSize: 17, color: 'lightblue', marginTop: '0.1%', paddingLeft: '5%', paddingRight: '5%' }}
                                onPress={() => {setUpdatingNotes(true)}}>
                                Edit Notes
                            </Text>


                            <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt', marginTop: '2.5%' }} />

                            <Text style={{ fontSize: 17, color: 'black', marginTop: '2.5%', paddingLeft: '5%', paddingRight: '5%' }} >Order Content</Text>
                            {currentBooking.booking_items.map((item) => (
                                <React.Fragment key={item.booking_item_id}>
                                    <Text style={{ fontSize: 17, color: 'black', marginTop: '0.1%', paddingLeft: '5%', fontStyle: 'italic', paddingRight: '5%' }} >{item.product.name === 'combo' && `Combos: ${item.quantity}\t`}</Text>
                                    <Text style={{ fontSize: 17, color: 'black', marginTop: '0.1%', paddingLeft: '5%', fontStyle: 'italic', paddingRight: '5%' }} >{item.product.name === 'umbrella' && `Umbrellas: ${item.quantity}\t`}</Text>
                                    <Text style={{ fontSize: 17, color: 'black', marginTop: '0.1%', paddingLeft: '5%', fontStyle: 'italic', paddingRight: '5%' }} >{item.product.name === 'chair' && `Chairs: ${item.quantity}\t`}</Text>
                                </React.Fragment>
                            ))}
                            <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt', marginTop: '2.5%' }} />

                            <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '75px', }}>
                                <TouchableOpacity onPress={() => placeBooking()} style={{ height: '60%', width: '40%', borderRadius: '2rem', borderWidth: '2px', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                                    <Text style={{ fontSize: 18, }}>Place Booking</Text>
                                </TouchableOpacity>
                            </View>

                            {/* Modal */}
                            <Modal
                                animationType="slide"
                                transparent={true}
                                visible={modalVisible}
                                onRequestClose={() => setModalVisible(false)}
                            >
                                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    {/* Map inside the modal */}
                                    <View style={{ width: '75%', height: '75%', backgroundColor: 'white', borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                        {selectedItem && <View style={{ width: '100%', height: '90%' }}>
                                            <CustomMap mode={'single-place'} location={selectedItem.id} sale_id={currentBooking.booking_id} booking_contents={currentBooking}></CustomMap>
                                        </View>}
                                        <View style={{ width: '100%', height: '10%', backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                            <TouchableOpacity onPress={() => { setModalVisible(false) }} style={{ width: '20%', height: '65%', borderRadius: '2rem', borderWidth: '2px', marginLeft: '2%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                <Text>Done</Text>
                                            </TouchableOpacity>
                                            {/* <TouchableOpacity style={{ width: '10%', height: '65%', borderRadius: '2rem', borderWidth: '2px', marginRight: '2%' }}>

                                            </TouchableOpacity> */}
                                        </View>
                                    </View>
                                </View>
                            </Modal>
                        </View>

                    }
                    {!bookingSelected &&
                        <View>
                            <Text style={{ color: 'black', marginTop: '2.5%', paddingLeft: '5%', paddingRight: '5%', textAlign: 'center' }} >Select a booking to see its details.</Text>
                        </View>
                    }

                </View>
                <View style={{ overflow: 'auto', height: 'auto', maxHeight: '100%', flex: isInfoExpanded ? '0.7' : '1' }}>
                    <View style={{ width: '100%', display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'flex-start' }}>
                        <TouchableOpacity onPress={() => { setIsInfoExpanded((prev) => { return !prev }) }} style={{ justifyContent: 'center' }}>
                            <Feather name={isInfoExpanded ? 'arrow-left-circle' : 'arrow-right-circle'} size={24} color={'black'} style={{ marginLeft: 5 }} />
                        </TouchableOpacity>
                        <Text style={[styles.heading, { marginLeft: 'auto', marginRight: 'auto', }]}>Current Bookings</Text>
                    </View>
                    <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1pt' }} />
                    <TouchableOpacity onPress={() => { isSelectingDate ? setIsSelectingDate(false) : setIsSelectingDate(true) }} style={{ width: '100%', height: '50px', backgroundColor: 'navy', borderRadius: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ color: 'white', fontSize: '16px', fontWeight: 'bold', height: 'auto' }}>Selected Date: {months[daySelected.getMonth()] + " " + daySelected.getDate() + " " + daySelected.getFullYear()}</Text>
                    </TouchableOpacity>
                    {isSelectingDate &&
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Calendar width={300} height={300} openToDate={daySelected}
                                onSelect={async (new_date) => { onSelectDate(new_date) }}
                            />
                        </View>
                    }
                    {bookings && bookings.map((booking) => {
                        return (
                            <View key={booking.booking_id}>
                                <TouchableOpacity disabled={editingNotes} onPress={() => { populateBooking(booking) }}>
                                    <Text style={{ fontSize: 24, color: 'black', paddingBottom: '1%', paddingTop: '1%', paddingLeft: '1%' }}>{booking.customer ? booking.customer.name : "Test"}</Text>
                                    <Text style={{ fontSize: 15.5, color: 'black', paddingLeft: '1%', fontStyle: 'italic', }}>
                                        full day {booking.start_date} - {booking.end_date}
                                    </Text>
                                    <View style={{ backgroundColor: 'lightgray', marginLeft: '1%', marginBottom: '1%', marginTop: '0.25%' }}>
                                        <Text style={{ fontSize: 15.5, color: 'black', paddingLeft: '0.5%', marginTop: '0.5%', marginBottom: '0.5%' }}>
                                            {booking.booking_items.map((item) => (
                                                <React.Fragment key={item.booking_item_id}>
                                                    {item.product.name === 'combo' && `Combos: ${item.quantity}\t`}
                                                    {item.product.name === 'umbrella' && `Umbrellas: ${item.quantity}\t`}
                                                    {item.product.name === 'chair' && `Chairs: ${item.quantity}\t`}
                                                </React.Fragment>
                                            ))}
                                            {'\n'}
                                            {booking.notes}
                                        </Text>
                                    </View>
                                </TouchableOpacity>

                                <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1px' }} />
                            </View>
                            // <View>
                            //     <TouchableOpacity disabled={editingNotes} key={booking.sale_id} onPress={() => { populateBooking(booking) }}>
                            //         <Text style={{ color: 'black', paddingBottom: '1%', paddingTop: '1%', paddingLeft: '1%' }} >{booking.name}</Text>
                            //         <Text style={{ color: 'black', paddingLeft: '1%', fontStyle: 'italic', fontSize: '10px' }} >full day ({convertDate(booking.start_date)[1]}/{convertDate(booking.start_date)[2]}/{convertDate(booking.start_date)[0]} - {convertDate(booking.end_date)[1]}/{convertDate(booking.end_date)[2]}/{convertDate(booking.end_date)[0]})</Text>
                            //         <View style={{ backgroundColor: 'lightgray', marginLeft: '1%', marginBottom: '1%', marginTop: '0.25%' }}>
                            //             <Text style={{ color: 'black', paddingLeft: '0.5%', fontSize: '10px', marginTop: '0.5%', marginBottom: '0.5%' }} >
                            //                 {booking.combo_rented > 0 && "Combos: " + booking.combo_rented + '\t'}{booking.umbrella_rented > 0 && "Umbrellas: " + booking.umbrella_rented + '\t'}{booking.chair_rented > 0 && "Chairs: " + booking.chair_rented + '\t'}{'\n'}
                            //                 {booking.notes}
                            //             </Text>
                            //         </View>
                            //     </TouchableOpacity>

                            //     <View style={{ borderBottomColor: 'lightgray', borderBottomWidth: '1px' }} />
                            // </View>
                        )
                    })}
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    back: {
        width: '100%',
        height: '100%',
        backgroundColor: '#FBFCFD',
        overflow: 'hidden',
    },
    heading: {
        fontSize: 30,
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
        textAlign: 'center',
        color: 'black',
    },
});

export default LocationScreen;

