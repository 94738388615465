import React, { useState, useRef } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Animated, Image, Dimensions } from 'react-native';
import DropDown from './Dropdown';
import CustomDrawer from './CustomDrawer';
import { useNavigate } from 'react-router-dom';
import { Feather } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from './constants';
import useLogout from '../hooks/useLogout';
import SettingsModal from './SettingsModal';
import setupFetchInterceptors from '../hooks/setupFetchInterceptors';


const Header = ({
  role = {},
  value = {},
  onDrawerStateChange = () => { },
  onSelect = () => { },
  hideDropdown = false,
  currentPage = {},
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownZIndex = dropdownOpen ? 1 : -1; // Set the dropdown zIndex to be higher when it's open

  let selection = [{ id: 1, name: 'Box 1' }, { id: 2, name: 'Box 2' }, { id: 3, name: 'Box 3' }, { id: 4, name: 'Box 4' }, { id: 5, name: 'Box 4B' }, { id: 6, name: 'Box 5' }, { id: 7, name: 'Box 5B' }, { id: 8, name: 'Box 6' }, { id: 9, name: 'Box 6B' }, { id: 10, name: 'Box 7' }, { id: 11, name: 'Box 8' }, { id: 12, name: 'Box 9' }, { id: 13, name: 'Box 10' }, { id: 14, name: 'Box 10B' }, { id: 15, name: 'Box 11' }, { id: 16, name: 'Box 12' }, { id: 17, name: 'Box 12B' }, { id: 18, name: 'Box 13' }, { id: 19, name: 'Box 14' }, { id: 20, name: 'Box 15' }, { id: 21, name: 'Box 15B' }, { id: 22, name: 'Box 16' }, { id: 23, name: 'Box 17' }, { id: 24, name: 'Box 18' }, { id: 25, name: 'Box 19' }, { id: 26, name: 'Box 20' }, { id: 27, name: 'Box 21' }, { id: 28, name: 'Box 22' }, { id: 29, name: 'Box 22B' }, { id: 30, name: 'Box 23' }, { id: 31, name: 'Box 24' }, { id: 32, name: 'Box 25' }, { id: 33, name: 'Box 26' }, { id: 34, name: 'Box 27' }, { id: 35, name: 'Box 28' }, { id: 36, name: 'Box 29' }, { id: 37, name: 'Box 30' }, { id: 38, name: 'Box 31' }, { id: 39, name: 'Box 32' }, { id: 40, name: 'Box 33' }, { id: 41, name: 'Box 34' }, { id: 42, name: 'Box 35' }, { id: 43, name: 'Box 36' }, { id: 44, name: 'Box 37' }, { id: 45, name: 'Box 37B' }, { id: 46, name: 'Box 38' }, { id: 47, name: 'Box 39' }, { id: 48, name: 'Box 40' }, { id: 49, name: 'Box 40B' }, { id: 50, name: 'Box 41' }, { id: 51, name: 'Box 42' }, { id: 52, name: 'Box 43' }, { id: 53, name: 'Box 44' }, { id: 54, name: 'Box 45' }, { id: 55, name: 'Box 46' }, { id: 56, name: 'Box 46B' }]
  // Notification dropdown
  const [showNotifications, setShowNotifications] = useState(false);
  const [username, setUsername] = useState("John Doe");
  const [employeeId, setEmployeeId] = useState(null);

  const [notifications, setNotifications] = useState([]);
  const [notificationsCopy, setNotificationsCopy] = useState([]);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);


  // Settings dropdown
  const [showSettings, setShowSettings] = useState(false);
  const toggleSettings = () => setShowSettings(!showSettings);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();

  const [settingsVisible, setSettingsVisible] = useState(false);

  setupFetchInterceptors()



  // get data from storage
  async function getData(key, defaultValue = null) {
    try {
      const value = await AsyncStorage.getItem(key);

      if (value !== null) {
        return value;
      } else {
        return defaultValue;
      }
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  }

  React.useEffect(() => {

    const func = async () => {
      const user_id = await getData('user_id')
      setUsername(await getData('name'))
      setEmployeeId(user_id)


      fetch(`${API_URL}/notifications/active/` + user_id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:19006',
          'Access-Control-Request-Method': 'GET',
          'Access-Control-Request-Headers': 'Content-Type, Authorization',
        },
      })
        .then(async res => {
          if (res.status !== 200) {
            console.log(res.status);
          } else {
            const jsonRes = await res.json();
            console.log(jsonRes)
            setNotifications(jsonRes.notifications);
            setNotificationsCopy(jsonRes.notifications);
            // Check if there are unread notifications
            const hasUnread = jsonRes.notifications.some(notification => notification.viewed != 1);
            setHasUnreadNotifications(hasUnread);
          }
        })
        .catch(err => {
          console.log(err);
        });
    };

    func();

  }, []);

  const handleDrawerPress = () => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
      onDrawerStateChange(false);
    } else {
      setIsDrawerOpen(true);
      onDrawerStateChange(true);
    }

  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    onDrawerStateChange(false);
  };

  // Logout function
  const handleLogout = async () => {

    await logout();
    navigate('/');

  };

  const handleToggleNotifications = () => {
    setShowNotifications(prevState => !prevState);
  };

  const handleNotificationPress = (notification) => {

    if (!notification.viewed) {
      // Fetch notifications from the server
      fetch(`${API_URL}/notifications/read`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Origin': 'http://localhost:19006',
          'Access-Control-Request-Method': 'POST',
          'Access-Control-Request-Headers': 'Content-Type, Authorization',
        },
        body: JSON.stringify({
          notificationId: notification.notification_id,
          employeeId
        }),
      })
        .then(async res => {
          if (res.status !== 201) {
            console.log(res.status);
          } else {
            console.log("success")

            fetch(`${API_URL}/notifications/active/` + employeeId, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
              },
            })
              .then(async res => {
                if (res.status !== 200) {
                  console.log(res.status);
                } else {
                  const jsonRes = await res.json();
                  console.log(jsonRes)
                  setNotifications(jsonRes.notifications);
                  setNotificationsCopy(jsonRes.notifications);
                  // Check if there are unread notifications
                  const hasUnread = jsonRes.notifications.some(notification => notification.viewed != 1);
                  setHasUnreadNotifications(hasUnread);
                }
              })
              .catch(err => {
                console.log(err);
              });

          }
        })
        .catch(err => {
          console.log(err);
        });
    }


    //set notification as read
    if (notification.relation_type === "Booking") {
      //route to bookings
      navigate('/bookings')
    } else if (notification.relation_type === "New Notice") {
      //route to notices
      navigate('/home')
    } else if (notification.relation_type === "New Booking") {
      //route to new bookings
      navigate('/new_booking')
    }
  }


  return (
    <View style={styles.header}>

      <SettingsModal
        isVisible={settingsVisible}
        user_id={employeeId}
        onCancel={() => { setSettingsVisible(false) }}
      />

      <TouchableOpacity onPress={handleDrawerPress} style={{ paddingRight: 10 }}>
        <Feather name="menu" size={24} color="black" />
      </TouchableOpacity>

      <CustomDrawer currentPage={currentPage} isOpen={isDrawerOpen} onClose={handleDrawerClose} role={role} />

      {/* Logo and company name */}
      <TouchableOpacity style={styles.logo} onPress={() => navigate('/home')}>
        <Image source={require('../public/images/nmblogo_black.png')} style={styles.logoImage} />
        <Text style={styles.companyName}>Beach Services</Text>
      </TouchableOpacity>

      <View style={{ width: '100%', alignItems: 'center', flex: '1', justifyContent: 'center', height: '100%' }}>
        {!hideDropdown && role && role != "Ice" && <Animated.View style={{ width: '30%', zIndex: '10', height: '75%', position: 'relative', }}>
          <DropDown
            textStyle={styles.dropdownTextStyle}
            value={value}
            data={selection}
            onSelect={onSelect}
          />
        </Animated.View>}
      </View>

      {/* Container for bell icon and notifications */}
      <View style={styles.bellContainer}>
        {/* Notification bell */}
        <TouchableOpacity onPress={handleToggleNotifications} style={styles.bellIcon}>
          <Feather name={'bell'} size={30} style={styles.iconImage} />
          {hasUnreadNotifications && <View style={styles.notificationDot} />}
        </TouchableOpacity>

        {/* Notifications */}
        {showNotifications && (
          <View style={[styles.dropdown]}>
            {notifications.map((notification, index) => (
              <TouchableOpacity onPress={() => { handleNotificationPress(notification) }} style={{ height: 50, display: 'flex', borderBottomWidth: 1, borderTopWidth: index === 0 ? 1 : 0, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                {notification.severity === "info" && <Feather name={'alert-circle'} color={'blue'} size={24} style={[styles.notificationIconImage, { alignItems: 'center', justifyContent: 'center' }]} />}
                {notification.severity === "warning" && <Feather name={'alert-triangle'} color={'#FFD700'} size={24} style={[styles.notificationIconImage, { alignItems: 'center', justifyContent: 'center' }]} />}
                {notification.severity === "severe" && <Feather name={'alert-octagon'} color={'#FF0000'} size={24} style={[styles.notificationIconImage, { alignItems: 'center', justifyContent: 'center' }]} />}
                <View style={{ width: 208 }}>
                  <Text key={notification.notification_id} style={{ fontSize: 15, fontWeight: notification.viewed ? '425' : 'bold' }}>{notification.message}</Text>

                </View>
                {!notification.viewed && <View style={{ height: 12, width: 12, backgroundColor: 'navy', borderRadius: 10 }} />}
              </TouchableOpacity>
            ))}
          </View>
        )}
      </View>

      {/* Settings wheel */}
      <TouchableOpacity style={styles.icon} onPress={() => { setSettingsVisible(true) }}>
        <Feather name={'settings'} size={30} color={'black'} style={styles.iconImage} />
      </TouchableOpacity>

      {/* User name */}
      <Text style={styles.userName}>{username}</Text>

      {/* Settings wheel */}
      <TouchableOpacity style={[styles.icon, { marginRight: 0 }]} onPress={() => { handleLogout() }}>
        <Feather name={'log-out'} size={30} color={'black'} style={styles.logoutLogoImage} />
      </TouchableOpacity>

    </View>
  );
};

const styles = {
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    //height: '10%',
    //maxHeight: '10%',
    height: '65px',
    zIndex: '5',
  },
  bellContainer: {
    position: 'relative',
    marginRight: '10px',
  },
  logo: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoImage: {
    width: 30,
    height: 30,
    marginRight: 10,
    objectFit: 'contain',
    resizeMode: 'contain',
  },
  logoutLogoImage: {
    width: 30,
    height: 30,
    marginLeft: 12.5,
    objectFit: 'contain',
    resizeMode: 'contain',
  },
  notificationDot: {
    position: 'absolute',
    top: 0,
    right: 15,
    backgroundColor: 'red',
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  companyName: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  icon: {
    marginRight: '10px',
    position: 'relative',
  },
  iconImage: {
    width: 30,
    height: 30,
    marginRight: 10,
    objectFit: 'contain',
    resizeMode: 'contain',
  },
  notificationIconImage: {
    marginRight: 10,
    objectFit: 'contain',
    resizeMode: 'contain',
  },
  dropdown: {
    position: 'absolute',
    top: '110%', // Position below the bell icon
    right: 10,
    width: 275,
    maxHeightY: 350,
    overflow: 'auto',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    padding: 10,
    zIndex: 10,
  },
  dropdownTextStyle: {
    height: '300pt',
    backgroundColor: 'lightgray',
    borderRadius: 2,
    overflow: 'auto',
    zIndex: '1',
  },

};

export default Header;

