import AuthScreen from './screens/AuthorizationScreens/AuthScreen';
import HomeScreen from './screens/MainScreens/HomeScreen';
import PurchaseScreen from './screens/MainScreens/PurchaseScreen';
import LocationScreen from './screens/MainScreens/LocationScreen';
import AnalyticsScreen from './screens/AdminScreens/AnalyticsScreen';
import RosterScreen from './screens/AdminScreens/RosterScreen';
import ControlsScreen from './screens/AdminScreens/ControlsScreen';
import InventoryScreen from './screens/AdminScreens/InventoryScreen';
import MapScreen from './screens/MainScreens/MapScreen';
import TestPage from './screens/TestScreen/TestScreen';
import SignupScreen from './screens/AuthorizationScreens/SignupScreen';
import NoticesScreen from './screens/AdminScreens/NoticesScreen';

import useFetchInterceptors from './hooks/useFetchInterceptors';

import PersistLogin from './components/PersistLogin';
import RequireAuth from './components/RequireAuth';

import Banner from './components/Banner';

import { AuthProvider } from './context/AuthProvider';

import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
// import { HashRouter, Route, Routes } from 'react-router-dom';
import { HashRouter, Route, Routes, Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ProtectedRoute from './components/ProtectedRoute';
import { filter } from 'lodash';
import IcePurchaseScreen from './screens/MainScreens/IcePurchaseScreen';

const STRIPE_PUBLISHABLE_KEY = 'pk_test_51LMbyWBbVllh4lyvvO2cte2uwCBJ2Ca0cxqFqPCqYdZD8DeHI5ZDiDRBNgWAv651ByyFeqjbt2kb2Bsx35Dpf1g400ohHqP2AG'
const stripeTestPromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function App() {




  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerType, setBannerType] = useState('');
  const [bannerMessage, setBannerMessage] = useState('');


  const showBanner = (type, message) => {
    setBannerType(type);
    setBannerMessage(message);
    setBannerVisible(true);

    // Hide the banner after 5 seconds
    setTimeout(() => {
      setBannerVisible(false);
    }, 5500);
  };

  return (
    <Elements stripe={stripeTestPromise}>

      <HashRouter>
        <AuthProvider>
          {bannerVisible && <Banner type={bannerType} message={bannerMessage} onClose={() => setBannerVisible(false)} see={bannerVisible} />}
          <Routes>

            <Route path="/" element={<ProtectedRoute />}>
              {/* public routes */}
              <Route path="/" element={<AuthScreen />} />
              <Route path="/signup" element={<SignupScreen />} />
              {/* <Route path="/unauthorized" element={<TestPage />} exact /> */}

              {/* private routes */}
              <Route element={<PersistLogin />}>

                {/* all users */}
                <Route element={<RequireAuth allowedRoles={["Admin", "Ice", "Boxes", "Manager"]} />}>
                  <Route path="/home" element={<HomeScreen showBanner={(type, message) => { showBanner(type, message) }} />} exact />
                </Route>

                <Route element={<RequireAuth allowedRoles={["Admin", "Ice", "Manager"]} />}>
                  <Route path="/new_sale" element={<IcePurchaseScreen showBanner={(type, message) => { showBanner(type, message) }} />} exact />
                </Route>

                <Route element={<RequireAuth allowedRoles={["Admin", "Boxes", "Manager"]} />}>
                  <Route path="/bookings" element={<LocationScreen />} exact />
                  <Route path="/map" element={<MapScreen />} exact />
                  <Route path="/new_booking" element={<PurchaseScreen showBanner={(type, message) => { showBanner(type, message) }} />} exact />
                </Route>

                {/* admin/manager pages */}
                <Route element={<RequireAuth allowedRoles={["Admin", "Manager"]} />}>
                  <Route path="/sales" element={<AnalyticsScreen />} exact />
                  <Route path="/roster" element={<RosterScreen />} exact />
                  <Route path="/inventory" element={<InventoryScreen showBanner={(type, message) => {showBanner(type, message) }} />} exact />
                  <Route path="/controls" element={<ControlsScreen />} exact />
                  <Route path="/notices" element={<NoticesScreen />} />
                  <Route path="/testing" element={<TestPage />} exact />
                </Route>

              </Route>
            </Route>
          </Routes>
        </AuthProvider>
      </HashRouter>

    </Elements>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});