import React, { useState, useEffect } from 'react';
import { Text, View, Image, StyleSheet, ScrollView } from 'react-native';
import Chart from './TideChart';
import { Feather } from '@expo/vector-icons';
import Ionicons from 'react-native-vector-icons/Ionicons';


const data2 = [50, 10, 40, 95, -4, -24, 85, 91, 35, 53, -53, 24, 50, -20, -80]


const WeatherWidget = () => {
    const [weatherData, setWeatherData] = useState(null);

    // Replace the URL with your own OpenWeatherAPI URL that retrieves hourly forecast data
    const WEATHER_API_KEY = "4942f427d65e66de3fa9631287689b6a";
    const WEATHER_API_URL = 'https://api.openweathermap.org/data/2.5/forecast?zip=29582,us&appid=' + WEATHER_API_KEY;
    // const WEATHER_API_URL = 'https://api.openweathermap.org/data/2.5/forecast/hourly?lat=33.817&lon=-78.605&appid=' + WEATHER_API_KEY;

    // -78.60502583234951,33.83888820159298
    const NOAA_API_URL = 'https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?begin_date=20230512&end_date=20230517&station=8660266&product=predictions&datum=MLLW&time_zone=lst_ldt&interval=hilo&units=english&application=DataAPI_Sample&format=xml'



    useEffect(() => {
        fetch(WEATHER_API_URL)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                setWeatherData(result);
            })
            .catch(error => console.log('error', error));
    }, []);
    // fetch(NOAA_API_URL)
    //     .then(response => response.text())
    //     .then(data => console.log(data))
    //     .catch(error => console.error(error));

    // }, []);

    const getWeatherIcon = (condition, size) => {
        switch (condition) {
            case 'Clear':
                return <Feather name="sun" size={size} color="orange" />;
            case 'Clouds':
                return <Ionicons name="cloudy-outline" size={size} color="gray" />;
            case 'Rain':
                return <Feather name="cloud-rain" size={size} color="blue" />;
                
            // Add more cases here for other weather conditions
            default:
                return null;
        }
    };

    const formatTime = (time) => {
        const date = new Date(time * 1000);
        let hours = date.getHours();
        const ampm = hours >= 12 ? 'p.m.' : 'a.m.';
        hours = hours % 12 || 12;
        return `${hours}:00 ${ampm}`;
    };

    return (
        <View style={styles.container}>
            {weatherData && (
                <>
                    <View style={styles.currentWeatherContainer}>
                        <Text style={styles.currentWeatherText}>Current Weather</Text>
                        <View style={styles.currentWeatherDetails}>
                            <Text style={styles.temperatureText}>{Math.round(((weatherData.list[0].main.temp - 273.15) * (9 / 5)) + 32)}°</Text>
                            <View style={styles.weatherIconContainer}>
                                {getWeatherIcon(weatherData.list[0].weather[0].main, 50)}
                                {/* <Text>{weatherData.list[0].weather[0].main}</Text> */}
                            </View>
                        </View>
                    </View>
                    {/* <View style={{ width: '100%', justifyContent: 'center', alignContent: 'center', marginLeft: 'auto', marginRight: 'auto' }}> */}
                        <ScrollView horizontal style={styles.hourlyForecastContainer}>
                            {weatherData.list.slice(0, 8).map(hourlyData => (
                                <View key={hourlyData.dt} style={styles.hourlyForecastItem}>
                                    <Text style={styles.hourlyForecastHour}>{formatTime(hourlyData.dt)}</Text>
                                    {getWeatherIcon(hourlyData.weather[0].main, 24)}
                                    <Text style={styles.hourlyForecastTemperature}>{Math.round(((hourlyData.main.temp - 273.15) * (9 / 5)) + 32)}°</Text>
                                </View>
                            ))}
                        </ScrollView>
                    {/* </View> */}

                </>
            )}
            {/* <View style={{ height: '250px', }}>
                <Chart/>
            </View> */}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    currentWeatherContainer: {
        marginBottom: 20,
    },
    currentWeatherText: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    currentWeatherDetails: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    temperatureText: {
        fontSize: 60,
        fontWeight: 'bold',
        marginRight: 10,
    },
    weatherIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    hourlyForecastContainer: {
        display: 'flex',
        
        // justifyContent: 'center',
        flexDirection: 'row',
        overflowX: 'auto',
    },
    hourlyForecastItem: {
        justifyContent: 'center',
        // backgroundColor: 'red',
        alignItems: 'center',
        marginRight: 19,
    },
    hourlyForecastHour: {
        fontSize: 11,
        marginBottom: 5,
    },
    hourlyForecastTemperature: {
        fontSize: 11,
        fontWeight: 'bold',
    },
});

export default WeatherWidget;
