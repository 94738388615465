import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import moment from 'moment';
import { Feather } from '@expo/vector-icons';
import { isDate } from 'lodash';
import { API_URL } from '../constants';



const Calendar = ({ width, height, onDateChange, selectedDateRangeFinal, dateRangeListFinal, box_id, todaysInventory, cartValues }) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedDateRange, setSelectedDateRange] = useState(selectedDateRangeFinal);
  const [dateRangeList, setDateRangeList] = useState(dateRangeListFinal);
  const [inventoryList, setInventoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    console.log("here")
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    console.log("here2")
    setHoveredIndex(null);
  };


  const getDaysArray = (start, end) => {
    let arr = [];
    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
      arr.push(m.format('YYYY-MM-DD'));
    }
    return arr;
  };


  const getMonthArray = (date) => {

    const firstDay = moment(date).startOf('month').format('YYYY-MM-DD');
    const startOfWeek = moment(firstDay).startOf('week');

    const finalDay = moment(date).endOf('month').format('YYYY-MM-DD');
    const endOfWeek = moment(finalDay).endOf('week');

    let daysArr = getDaysArray(startOfWeek, endOfWeek);
    // Ensure daysArr always contains 6 rows of days
    while (daysArr.length <= 41) {
      daysArr.push(moment(startOfWeek).add(daysArr.length, 'days').format('YYYY-MM-DD'));
    }
    // Trim daysArr to contain at most 42 days
    if (daysArr.length > 42) {
      daysArr = daysArr.slice(0, 42);
    }
    return daysArr;
  };


  const handleDatePress = (date) => {

    function checkConsecutiveDates(selectedDateRange) {
      const result = [];
      let consecutiveList = [];

      for (let i = 0; i < selectedDateRange.length; i++) {
        if (i === 0) {
          // The first date, start a new consecutive list
          consecutiveList.push(selectedDateRange[i]);
        } else {
          const currentDate = moment(selectedDateRange[i]);
          const prevDate = moment(selectedDateRange[i - 1]);

          if (currentDate.diff(prevDate, 'days') === 1) {
            // Dates are consecutive, add to the current list
            consecutiveList.push(selectedDateRange[i]);
          } else {
            // Dates are not consecutive, start a new list
            result.push([...consecutiveList]);
            consecutiveList = [selectedDateRange[i]];
          }
        }
      }

      // Add the last consecutive list to the result
      if (consecutiveList.length > 0) {
        result.push([...consecutiveList]);
      }

      return result;
    }

    const currentDate = moment();

    if (moment(date).isSameOrAfter(currentDate, 'day')) {
      let updatedSelectedDateRange;

      if (selectedDateRange.length === 0) {
        // No dates selected yet, set the start date
        updatedSelectedDateRange = [date];
      } else if (selectedDateRange.length === 1) {
        const startDate = moment(selectedDateRange[0]);
        const selectedDate = moment(date);

        if (selectedDate.isSame(startDate, 'day')) {
          // If the selected date is the same as the start date, deselect it
          updatedSelectedDateRange = [];
        } else if (selectedDate.isAfter(startDate, 'day')) {
          // Selected date is after the start date, set it as the end date
          updatedSelectedDateRange = [startDate.format('YYYY-MM-DD'), date];
        } else {
          // Selected date is before the start date, swap start and end dates
          updatedSelectedDateRange = [date, startDate.format('YYYY-MM-DD')];
        }
      } else {
        // Both start and end dates are already selected, reset to start new selection
        updatedSelectedDateRange = [date];
      }

      // Generate the range of dates between start and end dates
      const rangeDates = [];
      if (updatedSelectedDateRange.length === 2) {
        const startDate = moment(updatedSelectedDateRange[0]);
        const endDate = moment(updatedSelectedDateRange[1]);

        for (let d = startDate; d.isSameOrBefore(endDate, 'day'); d.add(1, 'day')) {
          rangeDates.push(d.format('YYYY-MM-DD'));
        }
        updatedSelectedDateRange = [...rangeDates]
      }

      console.log(updatedSelectedDateRange.length > 0)
      console.log(updatedSelectedDateRange)
      console.log(checkConsecutiveDates(updatedSelectedDateRange))

      // Send the updated, sorted array to onDateChange
      onDateChange(updatedSelectedDateRange.length > 0, updatedSelectedDateRange, checkConsecutiveDates(updatedSelectedDateRange));

      // Update the local state with the new sorted array
      setSelectedDateRange(updatedSelectedDateRange);
    }
  };



  const getNextMonth = () => {
    setCurrentDate(moment(currentDate).add(1, 'months'));
  };

  const getPrevMonth = () => {
    setCurrentDate(moment(currentDate).subtract(1, 'months'));
  };

  const monthArray = getMonthArray(currentDate);

  useEffect(() => {
    // Fetch data from the server and update inventoryList
    const fetchDataFromDatabase = async () => {
      try {
        const response = await fetch(`${API_URL}/bookings/calendar/` + box_id, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Origin': 'http://localhost:19006',
            'Access-Control-Request-Method': 'POST',
            'Access-Control-Request-Headers': 'Content-Type, Authorization',
          },
          body: JSON.stringify({
            month: currentDate.format('MM'),
            year: currentDate.format('YYYY'),
          }),
        });

        if (response.status === 200) {
          const data = await response.json();
          console.log(data)
          setInventoryList(data.bookingCounts);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false); // Update loading state
      }
    };

    fetchDataFromDatabase();
  }, [currentDate]);

  return (
    <View style={{ width: '100%', height: '100%' }}>
      <View style={[styles.container, { width, height }]}>
        <View style={[styles.header, { height: '20%', width: '100%' }]}>
          <TouchableOpacity onPress={getPrevMonth}>
            <Feather name="arrow-left" size={18} color="black" />
          </TouchableOpacity>
          <Text style={styles.month}>{currentDate.format('MMMM YYYY')}</Text>
          <TouchableOpacity onPress={getNextMonth}>
            <Feather name="arrow-right" size={18} color="black" />
          </TouchableOpacity>
        </View>
        <View style={[styles.days, { height: '10%', width: '100%', }]}>
          <Text style={styles.day}>Sun</Text>
          <Text style={styles.day}>Mon</Text>
          <Text style={styles.day}>Tue</Text>
          <Text style={styles.day}>Wed</Text>
          <Text style={styles.day}>Thu</Text>
          <Text style={styles.day}>Fri</Text>
          <Text style={styles.day}>Sat</Text>
        </View>
        {inventoryList.length != 0 &&
          <View style={[styles.dates, { height: '70%', width: '100%', }]}>
            {monthArray.map((date, index) => {
              const isToday = moment().isSame(date, 'day');
              const isSelected = selectedDateRange.includes(date);
              const isValidDay = moment(date).isSameOrAfter(moment(), 'day');
              const inventoryForDate = inventoryList != null ? inventoryList[moment(date).format('YYYY-MM-DD')] : null;
              const isBeforeMonth = moment(date).isBefore(moment(currentDate).startOf('month'));
              const isNextMonth = moment(date).isAfter(moment(currentDate).endOf('month'));
              let isDateDisabled = isLoading || !isValidDay || isNextMonth || isBeforeMonth || (inventoryForDate && inventoryForDate.booking_count > 0); // Define your threshold here

              let backgroundColor = ''
              let changeNumberColorOnSelect = false;
              // Compare the rented counts with the available counts and update the background color accordingly
              const umbrellasInCart = cartValues.find(cartItem => cartItem.id === 1) || 0;
              const chairsInCart = cartValues.find(cartItem => cartItem.id === 2) || 0;
              const combosInCart = cartValues.find(cartItem => cartItem.id === 3) || 0;

              const overBookedDate = inventoryForDate && todaysInventory && (inventoryForDate.umbrellas + umbrellasInCart?.quantity > todaysInventory.umbrella_total || inventoryForDate.chairs + chairsInCart?.quantity > todaysInventory.chair_total)

              if (overBookedDate) {
                backgroundColor = 'rgba(255, 200, 200, 0.5)'; // Dull red background color
                changeNumberColorOnSelect = true;
              }

              backgroundColor = isSelected ? 'rgb(232, 240, 254)' : backgroundColor;

              const textStyle = [
                styles.dateText,
                // isToday && styles.todayText,
                isSelected && styles.selectedText,
                { color: changeNumberColorOnSelect && isSelected ? 'red' : '#000' }
              ];

              // Additional styles for the first and last dates in the selected range
              //const isFirstDate = selectedDateRange[0] === date;
              //const isLastDate = selectedDateRange[selectedDateRange.length - 1] === date;
              //const isFirstOrLast = isFirstDate || isLastDate;

              //const ringStyle = isFirstDate ? styles.firstDateRing : isLastDate ? styles.lastDateCircle : null;

              return (
                <TouchableOpacity
                  onMouseEnter={() => {
                    if (overBookedDate) {
                      handleMouseEnter(index)
                    }
                  }}
                  onMouseLeave={() => { handleMouseLeave() }}
                  style={{
                    height: '16.2857%',
                    width: '14.2857%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    backgroundColor: backgroundColor,
                    position: 'relative',
                    elevation: 2
                    // backgroundColor: (inventoryForDate && inventoryForDate.booking_count > 0) ? 'red' : ''
                  }}
                  key={index}
                  onPress={() => handleDatePress(date)}
                  disabled={isDateDisabled}
                >
                  <View style={[styles.date, isSelected && styles.selectedDate, { opacity: isDateDisabled ? 0.5 : 1 }]}>
                    <Text style={[textStyle]}>{moment(date).format('D')}</Text>
                  </View>
                  {index === hoveredIndex && (
                    <View style={{ minHeight: 120, minWidth: 300, pointerEvents: 'none', backgroundColor: 'white', boxShadow: '0px 0px 2.5px 2px #eee', position: 'absolute', bottom: '100%', right: 'calc(50% - 150px)', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'nowrap' }}>
                      <View style={{ width: '100%', alignContent: 'center', textAlign: 'center', padding: 20, justifyContent: 'center', }}>
                        <Text style={{ fontSize: 16, fontWeight: 450, marginLeft: 'auto', marginRight: 'auto', }}>Remaining items on {date}</Text>
                      </View>
                      <View style={{ width: '100%', alignContent: 'center', textAlign: 'center', marginTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <Text style={{ fontSize: 16, fontWeight: 450, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>* Umbrellas: {todaysInventory.umbrella_total - inventoryForDate.umbrellas}</Text>
                        <Text style={{ fontSize: 16, fontWeight: 450, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>* Chairs: {todaysInventory.chair_total - inventoryForDate.chairs}</Text>
                      </View>
                    </View>
                  )}
                </TouchableOpacity>
              );
            })}
          </View>
        }
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  getPrevMonth: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  arrow: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  month: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  days: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  day: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
  },
  dates: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  date: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateText: {
    fontSize: 16,
    color: '#000',
  },
  todayText: {
    color: 'blue',
  },
  selectedText: {
    opacity: '1',
    // color: 'red',
  },
  disabledText: {
    opacity: '0.5',
    color: '#ccc',
  },
  selectedDate: {

  },
  firstDateRing: {
    borderWidth: 2,
    borderColor: 'blue',
    borderRadius: 50,
    width: 30,
    height: 30,
    textAlign: 'center',
    lineHeight: 30,
  },
  lastDateCircle: {
    backgroundColor: 'purple',
    borderRadius: 50,
    width: 30,
    height: 30,
    textAlign: 'center',
    lineHeight: 30,
    color: '#fff',
  },
});

export default Calendar;