import React, { useState } from 'react';
import AuthScreen from '../AuthorizationScreens/AuthScreen';
import Header from '../../components/Header';
import { SafeAreaView, ImageBackground, View, Text, StyleSheet, TouchableOpacity, TextInput, Platform, Button } from 'react-native';
import { API_URL } from '../../components/constants';
import setupFetchInterceptors from '../../hooks/setupFetchInterceptors';
// const API_URL = Platform.OS !== 'ios' ? 'http://localhost:5000' : 'http://10.0.2.2:5000';
//const API_URL = 'http://localhost:5000';

const ControlsScreen = ({ navigation }) => {


    setupFetchInterceptors()


    /*
        Cancel Orders
        Comp Sales
        Create Discounts
        Update Inventory
    */



    return (
        <SafeAreaView style={{ flex: 1, height: '100%' }}>
            <View style={styles.container}>

                <View style={{ display: 'flex', flexDirection: 'row', height: '100%', padding: '1%' }}>
                    <View style={{ display: 'flex', flexDirection: 'column', width: '80%', paddingRight: '5%' }}>
                        <View style={{ height: '13%', width: '100%', backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'row' }}>

                        </View>

                    </View>
                    <View style={{ height: '100%', width: '20%', borderWidth: '1pt', borderColor: 'black', backgroundColor: '#f2f2f2' }}>
                        <View style={{ height: '7.5%', width: '100%', backgroundColor: '#5FA9D6', textAlign: 'center' }}>
                            <Text style={{ fontSize: '20pt', color: 'white', fontFamily: 'Arial', margin: 'auto' }} >FILTER</Text>
                        </View>
                    </View>
                </View>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        height: '91.5%',
    },
    pageSelectorContainer: {
        flexDirection: 'row',
    },
    pageButton: {

    },
    itemStyle: {
        flexDirection: "row",
        alignItems: "center",
        height: '5%',
        flexWrap: 'nowrap',
        width: '100%',
    },
    column: {
        padding: 3,
        overflow: 'hidden',
    },
    textInputStyle: {
        height: '50%',
        marginTop: '1.5%',
        borderWidth: 1,
        paddingLeft: 20,
        marginLeft: '1.5%',
        borderColor: 'black',
        width: '65%',
        backgroundColor: 'white',
    },
    columnSeparator: {
        width: '0.1%',
        height: '100%',
        backgroundColor: 'lightgray',
    },
    itemText: {
        whiteSpace: 'nowrap',
    },
});

export default ControlsScreen;