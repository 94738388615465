import { useRef } from "react";
import { API_URL } from "../components/constants";
import useAuth from "./useAuth";
import AsyncStorage from '@react-native-async-storage/async-storage';
import useLogout from "./useLogout";



const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();
    const isRefreshingRef = useRef(false);
    const refreshQueueRef = useRef([]);
    const logout = useLogout();

    const refresh = async () => {
        try {


            // If token refresh is already in progress, wait for it to complete
            if (isRefreshingRef.current) {
                // Create a promise that resolves when token refresh completes
                const refreshPromise = new Promise((resolve) => {
                    refreshQueueRef.current.push(resolve);
                });
                await refreshPromise;
                // Return the refreshed token
                return auth.token;
            }

            // Set the flag to indicate token refresh is in progress
            isRefreshingRef.current = true;

            const response = await fetch(`${API_URL}/refresh`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Referrer-Policy': 'no-referrer-when-downgrade',
                    'Origin': 'https://www.jacobawebb.com',
                    'Access-Control-Request-Method': 'GET',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                },
                credentials: 'include'
            });

            if (!response?.ok) {
                throw new Error('Failed to refresh token');
            }

            const jsonRes = await response.json();
            
            // Retrieve user data from AsyncStorage
            const name = await AsyncStorage.getItem('name') || '';
            const role = await AsyncStorage.getItem('role') || '';
            const email = await AsyncStorage.getItem('email') || '';
            const user_id = await AsyncStorage.getItem('user_id') || '';


            setAuth(prev => ({
                ...prev,
                token: jsonRes.accessToken,
                name,
                role,
                email,
                id: user_id,
            }));


            // Reset the flag since token refresh is completed
            isRefreshingRef.current = false;

            // Resolve all pending fetch requests waiting for token refresh
            refreshQueueRef.current.forEach(resolve => resolve());
            refreshQueueRef.current = [];

            return jsonRes.accessToken;
        } catch (error) {
            console.error('Error refreshing token:', error);
            isRefreshingRef.current = false;
            await logout();
            throw error;
        }
    };

    return refresh;
}

export default useRefreshToken;
