import React, { useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Svg, Path, Circle } from 'react-native-svg';

const PieChart = ({ data, sales }) => {

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const total = data.reduce((sum, item) => sum + item.value, 0);
  const center = 50; // Assuming the pie chart should be centered
  let startAngle = 0;

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <View style={styles.container}>

      <Svg width="100%" height="100%" viewBox="0 0 100 100">
        {data.map((item, index) => {
          const percentage = (item.value / total) * 100;
          const endAngle = startAngle + (percentage * 360) / 100;
          const x1 = center + 40 * Math.cos((Math.PI * startAngle) / 180);
          const y1 = center + 40 * Math.sin((Math.PI * startAngle) / 180);
          const x2 = center + 40 * Math.cos((Math.PI * endAngle) / 180);
          const y2 = center + 40 * Math.sin((Math.PI * endAngle) / 180);

          const largeArcFlag = percentage > 50 ? 1 : 0;

        
          const pathData = `
            M${center},${center}
            L${x1},${y1}
            A40,40 0 ${largeArcFlag} 1 ${x2},${y2}
            Z
          `;

          startAngle = endAngle;
          const isHovered = hoveredIndex === index;

          return (
            <Path
              key={index}
              d={pathData}
              fill={isHovered ? 'lightblue' : item.color}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            />
          );
        })}
        <Circle cx={center} cy={center} r="30" fill="white" />
      </Svg>
      {hoveredIndex !== null && (
        <View style={styles.tooltip}>
          <Text>{data[hoveredIndex].category}</Text>
          <Text>Amount: {data[hoveredIndex].value}</Text>
        </View>
      )}
      <Text style={styles.centerText}>Total Sales: ${sales ? parseInt(sales).toLocaleString() : 0}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  tooltip: {
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.8)',
    padding: '0.5rem',
    borderRadius: 4,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  centerText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 14,
    fontWeight: 'bold',
  },
});

export default PieChart;