import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { format } from 'date-fns';

const TideChart = () => {
  const [tooltipData, setTooltipData] = useState(null);
  const [tideData, setTideData] = useState(null);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }

  useEffect(() => {
    async function fetchTideData() {
      const currentDate = new Date();
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(currentDate.getDate() + 1);

      const beginDate = formatDate(yesterday);
      const endDate = formatDate(tomorrow);

      const url = `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?begin_date=${beginDate}&end_date=${endDate}&station=8660266&product=predictions&datum=MLLW&time_zone=lst_ldt&interval=hilo&units=english&application=DataAPI_Sample&format=json`;

      // const url = 'https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?begin_date=20240128&end_date=20240130&station=8660266&product=predictions&datum=MLLW&time_zone=lst_ldt&interval=hilo&units=english&application=DataAPI_Sample&format=json';

      try {
        const response = await fetch(url);
        console.log(response)
        const data = await response.json();
        console.log(data)
        const filteredTides = filterTidesForCurrentDate(data.predictions);
        console.log(filteredTides);
        setTideData(filteredTides);
      } catch (error) {
        console.error('Error fetching tide data:', error);
      }
    }

    fetchTideData();
  }, []); // Empty dependency array ensures the effect runs only once on component mount


  function filterTidesForCurrentDate(tideData) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
    const currentDay = String(currentDate.getDate()).padStart(2, '0');
    const currentDateString = `${currentYear}-${currentMonth}-${currentDay}`;
    
    // Get the last piece of tide data of the day prior
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    const yesterdayYear = yesterday.getFullYear();
    const yesterdayMonth = String(yesterday.getMonth() + 1).padStart(2, '0');
    const yesterdayDay = String(yesterday.getDate()).padStart(2, '0');
    const yesterdayDateString = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay}`;
    
    const lastTideOfYesterday = tideData.filter(tide => {
        const tideDate = new Date(tide.t);
        return tideDate.getFullYear() === yesterdayYear && tideDate.getMonth() + 1 === yesterday.getMonth() + 1 && tideDate.getDate() === yesterday.getDate();
    }).pop();
    
    // Get all tides for the current day
    const currentDayTides = tideData.filter(tide => {
        const tideDate = new Date(tide.t);
        return tideDate.getFullYear() === currentYear && tideDate.getMonth() + 1 === currentDate.getMonth() + 1 && tideDate.getDate() === currentDate.getDate();
    });
    
    // Get the first piece of data from the day after the current date
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    const tomorrowYear = tomorrow.getFullYear();
    const tomorrowMonth = String(tomorrow.getMonth() + 1).padStart(2, '0');
    const tomorrowDay = String(tomorrow.getDate()).padStart(2, '0');
    const tomorrowDateString = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`;
    
    const firstTideOfTomorrow = tideData.find(tide => {
        const tideDate = new Date(tide.t);
        return tideDate.getFullYear() === tomorrowYear && tideDate.getMonth() + 1 === tomorrow.getMonth() + 1 && tideDate.getDate() === tomorrow.getDate();
    });
    
    return [lastTideOfYesterday, ...currentDayTides, firstTideOfTomorrow];
  }




  const handleMouseMove = (e) => {
    if (e.activePayload && e.activePayload[0]) {
      setTooltipData({
        time: e.activePayload[0].payload.time,
        height: e.activePayload[0].payload.height,
        type: e.activePayload[0].payload.type,
        x: e.chartX,
        y: e.chartY,
      });
    } else {
      setTooltipData(null);
    }
  };

  const formatXAxisTick = (value) => {
    const date = new Date(value);
    return format(date, 'h:mm a');
  };

  return (
    tideData && <View style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
      <Text style={{ fontSize: 24, fontWeight: 'bold', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }} >Tides</Text>
      <div style={{ position: 'relative' }}>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            data={tideData}
            onMouseMove={handleMouseMove}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="t"
              tick={{ fontSize: 10, fontWeight: 'bold', fill: '#555' }} // Optional: styling for tick marks
              tickFormatter={formatXAxisTick} // Format XAxis tick labels to display in AM/PM format
            />
            <YAxis
              label={{ value: 'Height', angle: -90, position: 'insideLeft' }}
              hide={true}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="v" stroke="#8884d8" />
            {/* <ReferenceLine x={2} stroke="red" strokeDasharray="3 3" /> */}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </View>

  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ background: '#fff', padding: '5px', border: '1px solid #ccc', borderRadius: '5px' }}>
        <p>Time: {payload[0].payload.t}</p>
        <p>Type: {payload[0].payload.type}</p>
        <p>Height: {payload[0].payload.v}</p>
      </div>
    );
  }

  return null;
};

export default TideChart;
