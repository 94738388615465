// setupFetchInterceptors.js

import useFetchInterceptors from './useFetchInterceptors';
import { API_URL } from '../components/constants';

const filterFn = (url) => {
  // Add your logic to determine which requests should be intercepted
  // For example, intercept only requests to a specific API endpoint

  if (typeof url !== 'string' || url.indexOf(API_URL) === -1 || url.indexOf("/refresh") != -1 || url.indexOf("/logout") != -1) return false;
  // if (url.indexOf("/refresh") === -1) return false;
  return true;
};

export default function setupFetchInterceptors() {
  useFetchInterceptors(filterFn);
}