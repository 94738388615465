import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Animated, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useLocationSSE from '../hooks/useLocationSSE';

const DropDown = ({
    data = [],
    value = {},
    onSelect = () => { }
}) => {
    const [showOption, setShowOption] = useState(false);
    const [selectorPosition, setSelectorPosition] = useState({ x: 0, y: 0 });
    const [selectedValueForHook, setSelectedValueForHook] = useState(null)

    useLocationSSE(value ? value.id : null)


    const measureSelector = (event) => {
        const { x, y } = event.nativeEvent.layout;
        setSelectorPosition({ x, y });
    };

    const onSelectedItem = async (val) => {
        await AsyncStorage.setItem("boxName", JSON.stringify(val));
        setSelectedValueForHook(val)
        setShowOption(false);
        onSelect(val);
    };

    return (
        <View style={styles.container}>
            <TouchableOpacity
                style={styles.selector}
                onLayout={measureSelector}
                activeOpacity={0.8}
                onPress={() => setShowOption(!showOption)}
            >
                <Text style={styles.selectorText}>{!!value ? value.name : 'Choose your location'}</Text>
                <Animated.Image
                    style={[styles.dropdownIcon, { transform: [{ rotate: showOption ? '180deg' : '0deg' }] }]}
                    source={require('../public/images/dropdown_icon.png')}
                />
            </TouchableOpacity>
            {showOption && (
                <View style={[styles.dropdownMenu, { top: selectorPosition.y + 32.5 }]}>
                    {data.map((val, i) => (
                        <TouchableOpacity
                            key={String(i)}
                            onPress={() => onSelectedItem(val)}
                        >
                            <Text>{val.name}</Text>
                        </TouchableOpacity>
                    ))}
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        borderWidth: 1,
        borderColor: 'black',
    },
    selector: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 10,
        height: '100%',
    },
    selectorText: {
        color: 'black',
        fontSize: 16,
    },
    dropdownIcon: {
        width: 20,
        height: 20,
        resizeMode: 'contain',
    },
    dropdownMenu: {
        position: 'absolute',
        left: 0,
        right: 0,
        backgroundColor: 'white',
        borderRadius: 5,
        padding: 10,
        shadowColor: 'black',
        shadowOpacity: 0.5,
        shadowRadius: 5,
        elevation: 5,
        maxHeight: 300,
        zIndex: 10,
        overflow: 'auto',
    },
});

export default DropDown;
