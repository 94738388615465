import React, { useEffect, useState } from 'react';
import { View, Image, StyleSheet, Animated, Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');

const Pelican = ({ onFinish }) => {
  const position = new Animated.ValueXY({ x: width, y: Math.random() * height });

  const flyAnimation = Animated.timing(position, {
    toValue: { x: -100, y: position.y },
    duration: Math.random() * 6000 + 15000, // Random duration between 3s and 6s
    useNativeDriver: false,
  });

  useEffect(() => {
    flyAnimation.start(() => {
      onFinish();
    });
  }, []);

  return (
    <Animated.Image
      source={require('../../public/gif/pelican-unscreen.gif')}
      style={[
        styles.pelican,
        {
          transform: [{ translateX: position.x }, { translateY: position.y }],
        },
      ]}
    />
  );
};

const styles = StyleSheet.create({
  pelican: {
    width: 100, // Adjust size as needed
    height: 100, // Adjust size as needed
    position: 'absolute',
  },
});

export default Pelican;
