import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ImageBackground, View, Text, StyleSheet, TouchableOpacity, TextInput, Platform, Image } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../components/constants';
import useAuth from '../../hooks/useAuth';
import useEmployeeSSE from '../../hooks/useEmployeeSSE';


const AuthScreen = ({ navigation }) => {

    const navigate = useNavigate();

    const { setAuth } = useAuth();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');

    const [userId, setUserId] = useState(null);

    // set data to storage
    async function setData(key, value) {
        try {
            await AsyncStorage.setItem(key, value);
        } catch (error) {
            console.log(error);
        }
    }

    // get data from storage
    async function getData(key, defaultValue = null) {
        try {
            const value = await AsyncStorage.getItem(key);

            if (value !== null) {
                return value;
            } else {
                return defaultValue;
            }
        } catch (error) {
            console.log(error);
            return defaultValue;
        }
    }

    const onLoggedIn = async token => {
        fetch(`${API_URL}/private`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'no-referrer-when-downgrade',
                'Origin': 'http://localhost:19006',
                'Access-Control-Request-Method': 'GET',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(async res => {
                try {
                    const jsonRes = await res.json();
                    if (res.status === 200) {
                        setMessage(jsonRes.message);
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });


        const currRole = await getData("role")
        navigate('/home');
    }

    const onSubmitHandler = () => {
        const payload = {
            email,
            name,
            password,
        };
        fetch(`${API_URL}/${'login'}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'no-referrer-when-downgrade',
                'Origin': 'https://www.jacobawebb.com',
                'Access-Control-Request-Method': 'POST',
                'Access-Control-Request-Headers': 'Content-Type, Authorization',
            },
            credentials: 'include',
            body: JSON.stringify(payload),
        })
            .then(async res => {
                try {
                    const jsonRes = await res.json();
                    if (res.status !== 200) {
                        setIsError(true);
                        setMessage(jsonRes.message);
                    } else {

                        var valueArr = [jsonRes.name, jsonRes.role, jsonRes.email, jsonRes.id]

                        await setData('name', valueArr[0])
                        await setData('role', valueArr[1])
                        await setData('email', valueArr[2])
                        await setData('user_id', valueArr[3])
                        await setData('date_selected', new Date())
                        console.log(valueArr[3])
                        
                        
                        setUserId(jsonRes.id)
                        onLoggedIn(jsonRes.token);
                        setAuth({ id: jsonRes.id, name: jsonRes.name, email: jsonRes.email, role: jsonRes.role,  token: jsonRes.token, })
                        setIsError(false);
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <View style={styles.background}>
            <Image
                style={styles.image}
                source={require('../../public/images/nmblogo.png')}
            />
            <View style={styles.card}>
                <Text style={styles.heading}>Login</Text>
                <View style={styles.form}>
                    <View style={styles.inputs}>
                        <TextInput style={styles.input} placeholder="Email" autoCapitalize="none" onChangeText={setEmail}></TextInput>
                        <TextInput secureTextEntry={true} style={styles.input} placeholder="Password" onChangeText={setPassword}></TextInput>
                        <TouchableOpacity style={styles.buttonAlt} onPress={onSubmitHandler}>
                            <Text style={styles.buttonAltText}>Submit</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                {isError &&
                    <View style={{marginTop: 75}}>
                        <Text style={{ color: 'red', fontSize: 24, fontWeight: 420, textAlign: 'center' }}>{message}</Text>
                    </View>
                }
            </View>
           
        </View>
    );
};

const styles = StyleSheet.create({
    image: {
        marginTop: '3%',
        width: '10%',
        height: '22.5%',
        resizeMode: 'contain',
        //objectFit: 'cover',
        //objectPosition: '50% 50%',
    },
    background: {
        flex: 1,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        backgroundColor: 'rgba(11, 11, 69, 1)',
    },
    card: {
        flex: 1,
        width: '60%',
        //marginTop: '8%',
        borderRadius: 20,
        maxHeight: 380,
        paddingBottom: '20%',
    },
    heading: {
        fontSize: 30,
        fontWeight: 'bold',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '5%',
        marginBottom: '15%',
        color: 'white',
    },
    form: {
        flex: 1,
        justifyContent: 'space-between',
        paddingBottom: '5%',
    },
    inputs: {
        width: '100%',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
    },
    input: {
        width: '80%',
        borderBottomWidth: 1,
        borderBottomColor: 'white',
        paddingTop: 10,
        fontSize: 16,
        minHeight: 40,
        color: 'white',
    },
    button: {
        width: '80%',
        backgroundColor: 'white',
        height: 40,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 5,
    },
    buttonText: {
        color: 'black',
        fontSize: 16,
        fontWeight: '400'
    },
    buttonAlt: {
        marginTop: '5%',
        width: '80%',
        borderWidth: 1,
        height: 40,
        borderRadius: 50,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 5,
    },
    buttonAltText: {
        color: 'white',
        fontSize: 16,
        fontWeight: '400',
    },
    message: {
        fontSize: 16,
        marginVertical: '2%',
        color: 'white',
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default AuthScreen;