import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import Header from '../../components/Header';
import CustomMap from '../../components/CustomMap';
import setupFetchInterceptors from '../../hooks/setupFetchInterceptors';

const MapScreen = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [currRole, setCurrRole] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    
    setupFetchInterceptors()

    // Load selected item from AsyncStorage on component mount
    useEffect(() => {
        const loadRole = async () => {
            setCurrRole(await AsyncStorage.getItem('role'))
        };

        const loadSelectedItem = async () => {
            try {
                const storedItem = await AsyncStorage.getItem('boxName');
                if (storedItem) {
                    console.log(JSON.parse(storedItem))
                    setSelectedItem(JSON.parse(storedItem));
                }
            } catch (error) {
                console.error('Error loading selected item:', error);
            }
        };
        
        loadRole();
        loadSelectedItem();
    }, []);

    const onSelect = async (item) => {
        try {
            await AsyncStorage.setItem('boxName', JSON.stringify(item));
            console.log((item))
            setSelectedItem(item);
        } catch (error) {
            console.error('Error saving selected item:', error);
        }
    };

    const handleDrawerStateChange = (newState) => {
        setIsDrawerOpen(newState);
    };

    return (
        <View style={styles.back}>
            <Header
                value={selectedItem}
                onSelect={onSelect}
                onDrawerStateChange={handleDrawerStateChange}
                role={currRole}
                currentPage={'Map'}
            />
            <View style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 65px)', width: isDrawerOpen ? 'calc(100vw - 250px)' : 'calc(100vw - 60px)', marginLeft: isDrawerOpen ? '250px' : '60px', overflow: 'auto' }}>
                 <CustomMap location={selectedItem ? selectedItem.id : null} />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    dropdownStyle: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderRadius: 2.5,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 3,
        borderColor: 'black',
    },
    dropdownTextStyle: {
        height: '300pt',
        backgroundColor: 'lightgray',
        borderRadius: 2,
        overflow: 'auto',
    },
    back: {
        width: '100%',
        height: '100%',
        backgroundColor: '#FBFCFD',
    },
    heading: {
        fontSize: 30,
        fontWeight: 'bold',
        marginTop: '2%',
        marginBottom: '5%',
        textAlign: 'center',
        color: 'black',
    },
    body: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: '5%',
        textAlign: 'center',
        color: 'white',
    },
});

export default MapScreen;