import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import moment from 'moment';
import { Feather } from '@expo/vector-icons';

const Calendar = ({
  width, height, onDateChange, openToDate,
  onSelect = () => { },
}) => {
  const [currentDate, setCurrentDate] = useState(openToDate ? moment(openToDate) : moment());
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  const getDaysArray = (start, end) => {
    let arr = [];
    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
      arr.push(m.format('YYYY-MM-DD'));
    }
    return arr;
  };

  const getMonthArray = (date) => {

    const firstDay = moment(date).startOf('month').format('YYYY-MM-DD');
    const startOfWeek = moment(firstDay).startOf('week');

    const finalDay = moment(date).endOf('month').format('YYYY-MM-DD');
    const endOfWeek = moment(finalDay).endOf('week');

    let daysArr = getDaysArray(startOfWeek, endOfWeek);
    let test = daysArr.pop();
    return daysArr;
  };

  const handleDatePress = (date) => {
    const selectedDate = moment(date, 'YYYY-MM-DD').toDate();
    onSelect(selectedDate);
  };

  const getNextMonth = () => {
    setCurrentDate(moment(currentDate).add(1, 'months'));
  };

  const getPrevMonth = () => {
    setCurrentDate(moment(currentDate).subtract(1, 'months'));
  };

  const monthArray = getMonthArray(currentDate);



  return (
    <View style={[styles.container, { width, height }]}>
      <View style={[styles.header, { height: '20%', width: '100%' }]}>
        <TouchableOpacity onPress={getPrevMonth}>
          <Feather name="arrow-left" size={18} color="black" />
        </TouchableOpacity>
        <Text style={styles.month}>{currentDate.format('MMMM YYYY')}</Text>
        <TouchableOpacity onPress={getNextMonth}>
          <Feather name="arrow-right" size={18} color="black" />
        </TouchableOpacity>
      </View>
      <View style={[styles.days, { height: '10%', width: '100%' }]}>
        <Text style={styles.day}>Sun</Text>
        <Text style={styles.day}>Mon</Text>
        <Text style={styles.day}>Tue</Text>
        <Text style={styles.day}>Wed</Text>
        <Text style={styles.day}>Thu</Text>
        <Text style={styles.day}>Fri</Text>
        <Text style={styles.day}>Sat</Text>
      </View>
      <View style={[styles.dates, { height: '70%', width: '100%' }]}>
        {monthArray.map((date, index) => {
          const isToday = moment().isSame(date, 'day');
          const isSelected = selectedDateRange.includes(date);
          const isCurrentMonth = moment(date).isSame(currentDate, 'month');
          const textStyle = [
            styles.dateText,
            isToday && styles.todayText,
            isSelected && styles.selectedText,
            !isCurrentMonth && styles.disabledText,
          ];
          return (
            <TouchableOpacity
              style={{ height: '14.2857%', width: '14.2857%' }}
              key={index}
              onPress={() => handleDatePress(date)}
              disabled={!isCurrentMonth}>
              <View style={styles.date}>
                <Text style={textStyle}>{moment(date).format('D')}</Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  getPrevMonth: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  arrow: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  month: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  days: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  day: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
  },
  dates: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  date: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateText: {
    fontSize: 16,
    color: '#000',
  },
  todayText: {
    color: 'blue',
  },
  selectedText: {
    opacity: '0.5',
    color: 'black',
  },
  disabledText: {
    opacity: '1',
    color: '#ccc',
  },
});

export default Calendar;